import * as React from 'react';

import { Affix, Button, Layout, Menu, notification } from 'antd';
import { Icon } from '@ant-design/compatible';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import DAL from './data/DAL';

import AppHeader from './components/AppHeader';
import Login from './pages/login/Login';
import Statistics from './pages/statistics/Statistics';
import Liucheng from './pages/liucheng/Liucheng';
import ControlPlatform from './pages/controlPlatform/ControlPlatform';
import Company from './pages/company/Company';

import NotificationMiniApp from './pages/notification/NotificationMiniApp';
import UserList from './pages/user/UserList';
import FangtaiEmbed from './pages/FangtaiEmbed';
import MeetingMiniApp from './pages/meeting/MeetingMiniApp';
import ContractMiniApp from './pages/contract/ContractMiniApp';
import ZhiNengMenJinMiniApp from './pages/ZhiNengMenJin/ZhiNengMenJinMiniApp';
import CallerMiniApp from './pages/caller/CallerMiniApp';
import ZhiNengXunGengMiniApp from './pages/patrol/ZhiNengXunGengMiniApp';
import WenzhangMiniApp from './pages/wenzhang/WenzhangMiniApp';
import HuoDongMiniApp from './pages/huodong/huoDongMiniApp';
import QuestionnaireMiniApp from './pages/questionnaire/QuestionnaireMiniApp';
import TiebaMiniApp from './pages/postBar/TiebaMiniApp';
import OrderMiniApp from './pages/order/OrderMiniApp';
import PayCostManageMiniApp from './pages/payCostManagement/PayCostManageMiniApp';
import GroupMiniApp from './pages/group/GroupMiniApp';
import LuckDrawMiniApp from './pages/luckdraw/LuckDrawMiniApp';
import FoundationSteupMiniApp from './pages/foundationSetup/FoundationSteupMiniApp';
import AssetsMiniApp from './pages/assets/AssetsMiniApp';
import ShenheMiniApp from './pages/zhuhushenhe/ShenheMiniApp';
import CompanyOrgMiniApp from './pages/qiyeguanli/CompanyOrgMiniApp';
import Home from './pages/home/Home';
import { Scrollbars } from 'react-custom-scrollbars';
import loginModel from '../src/models/login';
import CommonModal from '../src/models/Common';
import FaceAuditMiniApp from './pages/zhuhushenhe/FaceAuditMiniApp';
import BaoBiaoMiniApp from './pages/zhuhushenhe/BaoBiaoMiniApp';
import CompanyInfo from './pages/qiyeguanli/pages/CompanyInfo';
import API from './data/API';
import { getDepartment } from './services/PermItemService';
import EpidemicPreventionMiniApp from './pages/epidemicPrevention/EpidemicPreventionMiniApp';
import OperationManagementMiniApp from './pages/operationManagement/OperationManagementMiniApp';
import CurrentPlatform from './pages/controlPlatform/CurrentPlatform';
import InvestmentManagementMiniApp from './pages/banGong/InvestmentManagementMiniApp';
import Preview from './pages/phone/Preview';
import { WaterMark } from '@ant-design/pro-layout';
import Api from './data/API';
import SystemMiniApp from './pages/system/SystemMiniApp';
import CompanyProMiniApp from './pages/projectManagement/CompanyProMiniApp';
import CarMiniApp from './pages/car/CarMiniApp';
import TongXingJianKong from './pages/tongXinJianKong/Jiankong';
import DateTransmissionMiniApp from './pages/dataTransmission/DataTransmissionMiniApp';
import { getDeviceDList } from './services/ZhuhushenheService';
import ProductMiniApp from './pages/product/ProductMiniApp';
import TimedTaskMiniApp from './pages/timedTask/TimedTaskMiniApp';
import AttendanceManageMiniApp from './pages/attendance/AttendanceManageMiniApp';
import EarlyWarningMiniApp from './pages/earlyWarning/EarlyWarningMiniApp';
import UnlockRecordData from './pages/qiyeguanli/pages/UnlockRecordData';
import OptionReport from './pages/statistics/OptionReport';
import ScenarioMiniApp from './pages/scenario/ScenarioMiniApp';
import SocketContext from './context/SocketContext';
import {
	getTreeSchemeTechList,
	getTreeSchemeTypeList
} from './services/scheme';
import { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import PropertyReport from './pages/statistics/PropertyReport';
import EnergyConsumptionReport from './pages/statistics/EnergyConsumptionReport';
import IndustrialOperation from './pages/statistics/IndustrialOperation';
import EpidemicPreventionReport from './pages/statistics/EpidemicPreventionReport';
import AlarmLog from './pages/system/pages/AlarmLog';
import appConfig from './appConfig';
import './styles/DarkTheme.css';
import './styles/App.css';
import SafetyMiniApp from './pages/safety/SafetyMiniApp';
import HomeInspectionMiniApp from './pages/homeInspection/HomeInspectionMiniApp';
import LeaseMiniApp from './pages/lease/LeaseMiniApp';
import ExamAnswerMiniApp from './pages/examAnswer/ExamAnswerMiniApp';
import BeijingOfficeMiniApp from './pages/beijingOffice/BeijingOfficeMiniApp';
import DataScreen from './pages/statistics/DataScreen';
import ExitSlipMiniApp from './pages/exitSlip/ExitSlipMiniApp';
import TrafficEventMiniApp from "./pages/trafficEvent/TrafficEventMiniApp";
import InvestmentProjectsMiniApp from "./pages/investmentProjects/InvestmentProjectsMiniApp";
import WorkTaskMiniApp from "./pages/workTask/WorkTaskMiniApp";

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const App: React.FC = (props: any) => {

	const dal = DAL.dal;
	const allRoutes = [
		{
			sidebar: '首页',
			path: '/home',
			key: 'home',
			main: () => <Home/>
		},
		{
			key: 'admission:company:select',
			path: '/company-information',
			sidebar: '企业信息',
			main: () => <CompanyInfo/>
		},
		{
			key: 'business',
			path: '/company-organization',
			sidebar: '组织架构',
			main: () => <CompanyOrgMiniApp/>
		},
		{
			key: 'project_manager',
			path: '/company-project-manager',
			sidebar: '项目管理',
			main: () => <CompanyProMiniApp/>
		},
		{
			key: 'control_platform',
			path: '/control-platform',
			sidebar: '数据概览',
			main: () => <ControlPlatform/>
		},
		{
			key: 'current_platform',
			path: '/current-platform',
			sidebar: '通行概览',
			main: () => <CurrentPlatform/>
		},
		{
			key: 'assets_management',
			path: '/assets',
			sidebar: '房产管理',
			main: () => <AssetsMiniApp/>
		},
		{
			key: 'lease_management',
			path: '/lease',
			sidebar: '租赁管理',
			main: () => <LeaseMiniApp/>
		},
		{
			key: 'yanfang_management',
			path: '/inspection',
			sidebar: '验房管理',
			main: () => <HomeInspectionMiniApp/>
		},
		{
			key: 'car_management',
			path: '/car',
			sidebar: '车辆管理',
			main: () => <CarMiniApp/>
		},
		{
			key: 'notification_management',
			path: '/notification',
			sidebar: '通知',
			main: () => <NotificationMiniApp/>
		},
		{
			key: 'flow_sheet',
			path: '/liucheng',
			sidebar: '流程单',
			icon: 'file-text',
			main: () => <Liucheng/>
		},
		{
			key: 'admission',
			path: '/company',
			sidebar: '入驻企业',
			main: () => <Company/>
		},
		{
			key: 'management_meetings',
			path: '/meeting',
			sidebar: '会议室',
			// main: () => <MeetingNav/>
			main: () => <MeetingMiniApp/>
		},
		{
			key: 'operation_management',
			path: '/operationmanagement',
			sidebar: '产业赋能',
			icon: 'setting',
			main: () => <OperationManagementMiniApp/>
		},
		{
			key: 'bangong_management',
			path: '/bangong_management',
			sidebar: '招商管理',
			main: () => <InvestmentManagementMiniApp/>
		},
		{
			key: 'household',
			path: '/zhuhu',
			sidebar: '住户管理',
			main: () => <ShenheMiniApp/>
		},{
			key: 'exitSlip:manager',
			path: '/exit-slip',
			sidebar: '住户管理',
			main: () => <ExitSlipMiniApp/>
		},
		{
			key: 'face_audit',
			path: '/face_audit',
			sidebar: '公租房人脸审核',
			main: () => <FaceAuditMiniApp/>
		},
		{
			key: 'household:console',
			path: '/baobiao',
			sidebar: '运营报表',
			main: () => <BaoBiaoMiniApp/>
		},
		{
			key: 'entrance_guard',
			path: '/zhineng-menjin',
			sidebar: '门禁通行',
			main: () => <ZhiNengMenJinMiniApp/>
		},
		{
			key: 'answer',
			path: '/answer',
			sidebar: '考试答题',
			main: () => <ExamAnswerMiniApp/>
		},
		{
			key: 'epidemic_prevention_management',
			path: '/epidemic-prevention',
			sidebar: '防疫管理',
			main: () => <EpidemicPreventionMiniApp/>
		},
		{
			key: 'visitor_management',
			path: '/wy/fangke-guanli',
			sidebar: '访客管理',
			//main: () => <Caller/>
			main: () => <CallerMiniApp/>
		},
		{
			key: 'intelligent_patrol',
			path: '/patrolinspection',
			sidebar: '巡更巡检',
			// main: () => <ZhiNengXunGeng/>
			main: () => <ZhiNengXunGengMiniApp/>
		},
		{
			key: 'community_service',
			path: '/wenzhang',
			sidebar: '资讯',
			main: () => <WenzhangMiniApp/>
		},
		{
			key: 'activity_management',
			path: '/huodong',
			sidebar: '活动',
			// main: () => <HuoDong/>
			main: () => <HuoDongMiniApp/>
		},
		{
			key: 'post_bar_management',
			path: '/postbar',
			sidebar: '贴吧',
			main: () => <TiebaMiniApp/>
			// main: () => <TiebaNav/>
		},
		{
			key: 'questionnaire_investigation',
			path: '/questionnaire',
			sidebar: '问卷',
			// main: () => <QuestionnaireNav/>
			main: () => <QuestionnaireMiniApp/>
		},
		{
			key: 'group_management',
			path: '/group',
			sidebar: '群组',
			main: () => <GroupMiniApp/>
			// main: () => <GroupNav/>
		},
		{
			key: 'lottery_management',
			path: '/luckdraw',
			sidebar: '抽奖',
			// main: () => <LuckDrawNav/>
			main: () => <LuckDrawMiniApp/>
		},
		{
			key: 'charge_management',
			path: '/order',
			sidebar: '订单处理',
			// main: () => <Order/>
			main: () => <OrderMiniApp/>
		},
		{
			key: 'payment_management',
			path: '/pay_cost_management/',
			sidebar: '缴费管理',
			main: () => <PayCostManageMiniApp/>
			// main: () => <PayCostManagement/>
		},
		{
			key: 'contract_management',
			path: '/contract',
			sidebar: '合同管理',
			// main: () => <ContractNav/>
			main: () => <ContractMiniApp/>
		},
		{
			key: 'foundation_setup',
			path: '/foundation',
			sidebar: '前端应用配置',
			main: () => <FoundationSteupMiniApp/>
		},
		{
			key: 'product',
			path: '/product',
			sidebar: '产品管理',
			main: () => <ProductMiniApp/>
		},
		{
			key: 'foundation-data-transmission:parameter',
			path: '/transmission',
			sidebar: '数据传输配置',
			main: () => <DateTransmissionMiniApp/>
		},
		{
			key: 'user_management',
			path: '/user',
			sidebar: '用户查询',
			main: () => <UserList/>
		},
		{
			key: 'system_log',
			path: '/system',
			sidebar: '系统',
			main: () => <SystemMiniApp/>
		},
		{
			key: 'foundation_timed_task',
			path: '/timedtask',
			sidebar: '任务管理',
			main: () => <TimedTaskMiniApp/>
		},
		{
			key: 'attendance_manage',
			path: '/attendance',
			sidebar: '考勤管理',
			main: () => <AttendanceManageMiniApp/>
		},
		{
			key: 'early-warning-manager',
			path: '/early_warning_manage',
			sidebar: '预警管理',
			main: () => <EarlyWarningMiniApp/>
		},
		// {
		// 	key: 'personnel_statistics',
		// 	path: '/empData',
		// 	sidebar: '人员统计',
		// 	main: () => <EmpData />,
		// },
		{
			key: 'traffic_report',
			path: '/unlockRecordData',
			sidebar: '通行报表',
			main: () => <UnlockRecordData/>
		},
		{
			key: 'statistics:option_report',
			path: '/option_report',
			sidebar: '综合运营看板',
			main: () => <OptionReport/>
		},
		{
			key: 'statistics:property_report',
			path: '/property_report',
			sidebar: '物业运营看板',
			main: () => <PropertyReport/>
		},{
			key: 'shujudapan',
			path: '/dataScreen',
			sidebar: '数据大屏',
			main: () => <DataScreen/>
		},
		{
			key: 'statistics:energy_consumption_report',
			path: '/energy_consumption_report',
			sidebar: '综合能耗看板',
			main: () => <EnergyConsumptionReport/>
		},
		{
			key: 'statistics:industrial_operation_report',
			path: '/industrial_operation_report',
			sidebar: '产业运营看板',
			main: () => <IndustrialOperation/>
		},
		{
			key: 'sz_jia',
			path: '/scenario',
			sidebar: '数智',
			main: () => <ScenarioMiniApp/>
		}, {
			key: 'system_alarm_log',
			path: '/alarm_log',
			sidebar: '告警页面',
			main: () => <AlarmLog/>
		},
		{
			key: 'safety_management:monitor',
			path: '/safety_management/monitor',
			sidebar: '物业安防',
			main: () => <SafetyMiniApp/>
		},
		{
			key: 'beijingOfficeManager',
			path: '/beijingOffice',
			sidebar: '驻京办',
			main: () => <BeijingOfficeMiniApp/>
		},
		{
			key: 'trafficEvent',
			path: '/traffic_event',
			sidebar: '交通事件',
			main: () => <TrafficEventMiniApp/>
		},
		{
			key: 'work-task:manage',
			path: '/work-task',
			sidebar: '工作任务',
			main: () => <WorkTaskMiniApp/>
		},
		{
			key: 'investment_projects:manager',
			path: '/investment_projects',
			sidebar: '投资项目',
			main: () => <InvestmentProjectsMiniApp/>
		}

	];
	const [width, setWidth] = useState('');
	const [watermark, setWatermark] = useState({});
	const [userName, setUserName] = useState('');
	const [wuYeName, setWuYeName] = useState('');
	const [collapsed, setCollapsed] = useState(false);
	const [dataReady, setDataReady] = useState(false);
	const [ds, setDs] = useState([]);
	const [loginState, setLoginState] = useState(false);
	const [menuName, setMenuName] = useState('');
	const [userMenus, setUserMenus] = useState([]);
	const [userRoutes, setUserRoutes] = useState([]);
	const [show403Message, setShow403Message] = useState(false);
	const socketRef = useRef<any>(null);
	// const audio = new Audio('http://img95.699pic.com/music_sound_effect/679/790/5d6763968ad1a.mp3');

	useEffect(() => {
		// audio.load()
		let state = dal.getLoginState();
		setLoginState(state);
		if (
			!state &&
			!window.location.pathname.includes('/fangtai-embed') &&
			!window.location.pathname.includes('/preview.html') &&
			!window.location.pathname.includes('/tongxing-jiankong')
		) {
			if (!window.location.pathname.includes('/wy/login') ) {
				window.location.href = '/wy/login';
			}
			return;
		}
		getWatermark().then();
		getPersonAcl();
		setWidth('100%');
		dal.getAllPerm();
		getEmpUserList();
		getDeviceList();
		getScenarioTypeList1();
		getTechList();
	}, []);

	useEffect(() => {
		let href = window.location.pathname.split('/')[1];
		console.log('href', href);
		let userMenupermission: any = [];
		let name = '';
		let findResult = false;
		((loginModel.state.currentUser as any).menu || []).map((item: any) => {
			if (item.children) {
				item.children.map((children: any) => {
					if (children.children) {
						children.children.map((s: any) => {
							if (s.path.includes('/')) {
								if (s.path.split('/')[1] === href) {
									findResult = true;
									userMenupermission = children.children || [];
									name = children.name;
								}
							} else if (s.path === href) {
								findResult = true;
								userMenupermission = children.children || [];
								name = children.name;
							}

						});
					}
					if (children.path.includes('/')) {
						if (children.path.split('/')[1] === href) {
							findResult = true;
							userMenupermission = children.children || [];
							name = children.name;
						}
					} else if (children.path === href) {
						findResult = true;
						userMenupermission = children.children || [];
						name = children.name;
					}
				});
			}
		});
		console.log('href:::', href, '总菜单：：', ((loginModel.state.currentUser as any).menu || []), '新菜单：：：', userMenupermission);
		if (findResult) {
			setUserMenus(userMenupermission);
			setMenuName(name);
		}
	}, [window.location.pathname]);
	useEffect(() => {
		let socket: any = null;
		let userInfo = JSON.parse(localStorage.getItem('userInfo11') || '{}');
		if (dal.getLoginState() && userInfo.uuid && userInfo.token && socketRef.current == null) {
			console.log('初始化socket.io', socket)
			let socketPath = '';
			if (process.env.NODE_ENV === 'production') {
				// 生产环境，优先使用window对象下的全局配置
				socketPath = appConfig.apiRootPath;
			} else {
				// 开发环境，直接使用开发配置文件
				socketPath = 'http://localhost:9092'
			}
			socket = io(socketPath, {
				query: {
					'uuid': userInfo.uuid,
					'token': userInfo.token
				},
			});
			socket.on('connect', () => {
				console.log('连接成功');
				if (userRoutes.length > 0) {
					setDataReady(true);
				}
			});
			socket.on('disconnect', function() {
				console.log('断开连接');
			});
			socket.on('message', (data: any, a1: any) => {
				console.log('APP::message::', data, a1, socket.id, a1 === socket.id);
			});
			socket.on('liuChengNotice', (data1: any) => {
				console.log("liuChengNotice::",data1);
				// audio.play()
				const data = JSON.parse(data1 || '{}')
				notification.open({
					message: '通知',
					description:data.message,
					btn:(
						<Button type="primary" size="small" onClick={() => window.open("/page/liucheng/Liuchengdan_data_info?shiliuuid="+data.shiliUuid)}>
							去审核
				 		 </Button>
					),
					onClick: () => {
					},
				  });
			})
			socket.on('connect_error', (error: any) => {
				console.log('connect_error', error);
				if (userRoutes.length > 0) {
					setDataReady(true);
				}
			});
			socket.on('reconnect', (attemptNumber: number) => {
				console.log('app::socket', '重连成功');
			});
			socketRef.current = socket;
		} else {
			setDataReady(true);
			if (socket != null) {
				socket.disconnect();
			}
		}
		return () => {
			if (socket != null) {
				socket.off('connect');
				socket.off('disconnect');
				socket.off('connect_error');
				console.log('APP:::OFF::');
			}
		};
	}, [socketRef.current]);


	const getWatermark = async () => {
		Api.postWithAuth('/api/wuye/get_user_info', {
			user_id: Api.userId,
			password: Api.pd
		}).then((res) => {
			if (res.err === 0) {
				// this.setState({
				// 	watermark: res.data.watermark,
				// 	userName: res.data.userName,
				// 	wyYeName: res.data.wyYeName
				// });
				setWatermark(res.data.watermark);
				setUserName(res.data.userName);
				setWuYeName(res.data.wyYeName);
			} else {
			}
		});
	};

	// componentWillMount() {
	// 	console.log('this.this.props', this.props);
	// 	let state = this.dal.getLoginState();
	// 	this.setState({ loginState: state });
	// 	if (
	// 		!state &&
	// 		!window.location.pathname.includes('/fangtai-embed') &&
	// 		!window.location.pathname.includes('/preview.html') &&
	// 		!window.location.pathname.includes('/tongxing-jiankong')
	// 	) {
	// 		if (window.location.pathname !== '/wy/login') {
	// 			window.location.href = '/wy/login';
	// 		}
	// 		return;
	// 	}
	// 	this.getWatermark();
	// 	this.getPersonAcl();
	// 	this.setState({
	// 		width: '100%'
	// 	});
	// 	this.dal.getAllPerm();
	// 	this.getEmpUserList();
	// 	this.getDeviceList();
	// 	this.getScenarioTypeList1();
	// 	this.getTechList();
	//
	// 	let href = window.location.pathname;
	// 	let userMenupermission: any = [];
	// 	let name = '';
	// 	((loginModel.state.currentUser as any).menu || []).map((item: any) => {
	// 		if (item.children) {
	// 			item.children.map((children: any) => {
	// 				if (children.children) {
	// 					children.children.map((s: any) => {
	// 						if (s.path == href) {
	// 							userMenupermission = children.children || [];
	// 							name = children.name;
	// 						}
	// 					});
	// 				}
	// 				if (children.path == href) {
	// 					userMenupermission = children.children || [];
	// 					name = children.name;
	// 				}
	//
	// 			});
	// 		}
	// 	});
	// 	console.log('href:::', href, '总菜单：：', ((loginModel.state.currentUser as any).menu || []), '新菜单：：：', userMenupermission);
	// 	this.setState({
	// 		userMenus: userMenupermission,
	// 		menuName: name
	// 	});
	// }

	const getEmpUserList = async () => {
		if (
			CommonModal.state.empUserList !== undefined &&
			CommonModal.state.empUserList !== [] &&
			CommonModal.state.empUserList.length > 0
		) {
		} else {
			const data = {
				companyUuid: API.getCompanyUuid()
			};
			const res = await getDepartment(data);
			if (res.err == 0) {
				CommonModal.state.empUserList = res.data;
				console.log('res:::::', res);
			}
		}
	};
	const getScenarioTypeList1 = async () => {
		if (
			CommonModal.state.scenarioTypeList !== undefined &&
			CommonModal.state.scenarioTypeList !== [] &&
			CommonModal.state.scenarioTypeList.length > 0
		) {
		} else {
			const data = {
				proj_id: localStorage.getItem('wyUuid')
			};
			const res = await getTreeSchemeTypeList(data);
			if (res.err == 0) {
				CommonModal.state.scenarioTypeList = res.data;
				console.log('res:::::', res);
			}
		}
	};
	const getTechList = async () => {
		if (
			CommonModal.state.schemeTechList !== undefined &&
			CommonModal.state.schemeTechList !== [] &&
			CommonModal.state.schemeTechList.length > 0
		) {
		} else {
			const data = {
				proj_id: localStorage.getItem('wyUuid')
			};
			const res = await getTreeSchemeTechList(data);
			if (res.err == 0) {
				CommonModal.state.schemeTechList = res.data;
				console.log('res:::::', res);
			}
		}
	};
	const getDeviceList = async () => {
		if (
			CommonModal.state.deviceList !== undefined &&
			CommonModal.state.deviceList !== [] &&
			CommonModal.state.deviceList.length > 0
		) {
		} else {
			const data = {
				wuyeid: localStorage.getItem('wyUuid')
			};
			const res = await getDeviceDList(data);
			if (res.err == 0) {
				CommonModal.state.deviceList = res.data;
				console.log('res:::::', res);
			}
		}
	};
	/**
	 * 获取当前用户的功能授权，过滤更新应当展示的菜单
	 */
	const getPersonAcl = () => {
		// let userMenupermission = (loginModel.state.currentUser as any).menu || [];
		let userPerm = ((loginModel.state.currentUser as any).button || '').split(',');
		// console.log('userPerm::::::', userPerm, userMenupermission);
		let userRoutes: any = allRoutes.filter((item) => {
			let flag = false;
			userPerm.map((item1: string) => {
				if (item.key === item1) {
					flag = true;
					return;
				}
			});
			return flag;
		});
		// this.setState({
		// 	// userMenus: userMenupermission,
		// 	userRoutes: userRoutes,
		// 	dataReady: true
		// });
		setUserRoutes(userRoutes);
		if (socketRef.current != null) {
			setDataReady(true);
		}
		// setDataReady(true);

	};

	const toggleSidebar = () => {
		console.log('App.toggleSidebar', collapsed, collapsed);
		// this.setState({
		// 	collapsed: !this.state.collapsed
		// });
		setCollapsed(!collapsed);
	};

	const renderLoginPage = () => {
		return <Route path="/wy/login" component={Login}/>;
	};

	const renderFangTaiPage = () => {
		return <Route path="/fangtai-embed/:wuyeUuid" component={FangtaiEmbed}/>;
	};

	const renderPreviewPage = () => {
		return <Route path="/preview.html" component={Preview}/>;
	};

	const renderTongXingJianKong = () => {
		return <Route path="/tongxing-jiankong" component={TongXingJianKong}/>;
	};
	// const renderToPrintBill = () => {
	// 	return <Route path="//print_bill" component={ReceiptOfCharges}/>;
	// };


	const setMenu = (key: any) => {
		let userMenupermission: any = [];
		let name = '';

		((loginModel.state.currentUser as any).menu || []).map((item: any) => {
			if (item.children) {
				item.children.map((children: any) => {
					if (children.key === key) {
						userMenupermission = children.children || [];
						name = children.name;
					}
				});
			}
		});
		console.log('总菜单：：', ((loginModel.state.currentUser as any).menu || []), '新菜单：：：', userMenupermission);
		// this.setState({
		// 	userMenus: userMenupermission,
		// 	menuName: name
		// });
		setUserMenus(userMenupermission);
		setMenuName(name);
	};

	const render2 = () => {
		if (window.location.pathname.includes('/tongxing-jiankong')) {
			return renderTongXingJianKong();
		}

		if (window.location.pathname.includes('/fangtai-embed')) {
			return renderFangTaiPage();
		}
		if (window.location.pathname.includes('/preview.html')) {
			return renderPreviewPage();
		}
		if (window.location.pathname.includes('/epidemic-prevention-report')) {
			return <Route path="/epidemic-prevention-report" component={EpidemicPreventionReport}/>;
		}
		if (!loginState) {
			return renderLoginPage();
		}
		if (!dataReady) {
			return <div></div>;
		}

		return (
			<SocketContext.Provider value={socketRef.current}>
				<WaterMark zIndex={0} style={{ width: '100%', height: '100%' }}
						   content={watermark ? wuYeName + '·' + userName : ''}>
					<Layout className="basic-layout">
						<AppHeader onChange={setMenu} isSidebarCollapsed={collapsed}
								   toggleSidebar={toggleSidebar}/>
						<Layout>
							{
								window.location.pathname !== '/home' &&
								<Sider width={140} onCollapse={(collapsed: any) => {
									// this.setState({
									// 	collapsed: collapsed
									// });
									setCollapsed(collapsed);
								}} className="admin-side">
									<Scrollbars>
										<div style={{
											width: 140,
											paddingLeft: 20,
											lineHeight: '40px',
											height: 40,
											fontSize: 16,
											fontWeight: 'bold',
											color: '#fff'
										}}>
											{collapsed || menuName}
										</div>
										<Menu
											theme="dark"
											mode="inline"
											selectedKeys={[window.location.pathname]}
										>
											{userMenus.map((route: any, index: number) => {

												return route.children ? (
													<SubMenu
														key={route.path}
														title={
															<span>
																<Icon
																	type={route.icon}
																	style={{
																		fontSize: '16px'
																	}}
																/>
																<span
																	style={{
																		fontSize: '16px'
																	}}
																>
																	{route.name}
																</span>
															</span>
														}
													>
														{route.children.map((item: any, inde: number) => (
															<Menu.Item key={item.path}>
																{
																	(route.path.includes('https://') || route.path.includes('http://')) ?
																		<a
																			href={route.path || ''}
																			target={'_black'}
																			style={{
																				textDecoration: 'none'
																			}}
																		>
																			<span>{route.name}</span>
																		</a> : <NavLink
																			to={item.path || ''}
																			style={{
																				textDecoration: 'none'
																			}}
																		>
																			<span>{item.name}</span>
																		</NavLink>
																}

															</Menu.Item>
														))}
													</SubMenu>
												) : (
													<Menu.Item key={route.path}>
														{
															(route.path.includes('https://') || route.path.includes('http://')) ?
																<a
																	href={route.path || ''}
																	target={'_black'}
																	style={{
																		textDecoration: 'none'
																	}}
																>
																	<Icon type={route.icon || ''}
																		  style={{ fontSize: '16px' }}/>
																	<span>{route.name}</span>
																</a> : <NavLink
																	to={route.path || ''}
																	style={{
																		textDecoration: 'none'
																	}}
																>
																	<Icon type={route.icon || ''}
																		  style={{ fontSize: '16px' }}/>
																	<span>{route.name}</span>
																</NavLink>
														}

													</Menu.Item>
												);
											})}
										</Menu>
									</Scrollbars>
								</Sider>
							}
							<Layout>
								{show403Message ? (
									<h2 style={{ padding: '20px' }}>您尚未获得任何功能授权，请联系您的企业管理员</h2>
								) : (
									<Content className="screen-desktop-wide">
										<Switch>
											{userRoutes.map((route: any, index: number) =>
												route.groupList ? (
													route.groupList.map((item: any, inde: number) => (
														<Route key={inde} path={item.path} component={item.main}/>
													))
												) : (
													<Route key={index} path={route.path} component={route.main}/>
												)
											)}
											<Route path="/home" key="/home" component={Home}/>
											<Route path="/statistics/shuju-tongji" component={Statistics}/>
											<Route path="/statistics/number-added" component={Statistics}/>
											<Route path="/face/face-shenhei" component={ZhiNengMenJinMiniApp}/>
											{/*<Route path={"/home"} component={<Home/>}/>*/}
											{/*<Route path="/liucheng/iframe" component={Liucheng}/>*/}
											{/*<Route render={() => <h3>{(this.state as any).msg}</h3>}/>*/}
											<Redirect to={'/home'}/>
										</Switch>
									</Content>
								)}
							</Layout>
						</Layout>
					</Layout>
				</WaterMark>
			</SocketContext.Provider>
		);
	};

	return render2();
};
export default App;
