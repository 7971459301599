import React, {useContext, useEffect, useRef, useState} from 'react';
import {FormInstance} from 'antd/es/form';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Statistic,
    Switch,
    Tooltip,
    Image,
    Tag,
    Spin,
    Progress, Dropdown, Menu,
} from 'antd';
import {ActionType} from '@ant-design/pro-table';
import GSTable from '../../../components/GSTable';
import API from '../../../data/API';
import {ExclamationCircleOutlined} from '@ant-design/icons/lib';
import {
    deletKey,
    getDevices,
    keyDelay,
    sendKeySms,
    updateMapping,
    deleteMappingList,
    updeteKeyAvailable,
    getMappingList,
    batchUpdateEKey,
    getMappingData,
    batchDeleteEkey,
    getMappingInfo,
    batchUpdeteKeyAvailable,
    batchKeyDelay,
} from '../../../services/ZhiNengMenJinService';
import {getSmsContent} from '../../../services/notificationService';
import KeyEventNote from './KeyEventNote';
import * as XLSX from 'xlsx';
import SendKeyModal from '../components/SendKeyModal';
import GsTreeSelect from '../../../components/GsTreeSelect';
import AuthorizedView from '../../../util/AuthorizedView';
import appConfig from '../../../appConfig';
import SocketContext from '../../../context/SocketContext';
import {DownOutlined} from "@ant-design/icons";

const {RangePicker} = DatePicker;
const MappingList: React.FC = (props: any) => {
    const socket: any = useContext(SocketContext);
    useEffect(() => {
        console.log('props::::', props);
        if ('cb743f05-e07e-4159-8a34-2d55ba713cea' == localStorage.getItem('wyUuid')) {
            setMappingType('anytime');
        }
        getSelectDeviceList().then();
        querySmsContent();
        loadMappingData().then();
    }, []);

    useEffect(() => {
        if (socket != null) {
            console.log('初始化socket.io  not null', socket.id)
            socket.on('refresh', (data: any) => {
                console.log('mappingList:::', data, deleteLoading);
                message.success(JSON.parse(data).msg);
                setDeleteLoading(false);
                if (actionRef.current) {
                    actionRef.current.reload();
                }

            });
            socket.on('reconnect', (attemptNumber: number) => {
                console.log('mappingList::socket', '重连成功');
            });
            socket.on('connect', () => {
                console.log('mappingList::连接成功');
            });
            socket.on('disconnect', function () {
                console.log('断开连接');
            });
            socket.on('handle', (data: any) => {
                setDeletePercent(JSON.parse(data).percent);
                console.log('mappingList:::handle', data);

            });
            socket.on('reconnect', (attemptNumber: number) => {
                console.log('app::socket', '重连成功');
            });
        }
        return () => {
            if (socket != null) {
                socket.off('refresh');
                socket.off('handle');
                socket.off('reconnect');
                socket.off('disconnect');
                socket.off('connect')
                console.log('mapping::::socket:::取消监听')
            }
        };
    }, [socket]);

    let msg = require('../../../util/SuspensionMsg');
    let moment = require('moment');
    const Search = Input.Search;
    const Option = Select.Option;
    const RadioGroup = Radio.Group;
    const confirm = Modal.confirm;
    const [updateForm] = Form.useForm<FormInstance>();
    const [extensionForm] = Form.useForm<FormInstance>();
    const [updateKeyForm] = Form.useForm<FormInstance>();
    const [deleteKeyForm] = Form.useForm<FormInstance>();
    const FormItem = Form.Item;
    const actionRef = useRef<ActionType>();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [quanxuan, setQunXuan] = useState(false);
    const [selectPhone, setSelectPhone] = useState(new Set());
    const [datas, setDatas] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const [isShow, setIsShow] = useState(true);
    const [item, setItem] = useState<any>({});
    const [keyRemarkVisible, setRemarkVisible] = useState(false);
    const [batchUpdateKeyVisible, setBatchUpdateKeyVisible] = useState(false);
    const [batchDeleteKeyVisible, setBatchDeleteKeyVisible] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deletePercent, setDeletePercent] = useState(0);
    const [mappingTopData, setMappingTopData] = useState({
        deviceNum: 0,
        noTongbuUserNum: 0,
        noUploadImgUserNum: 0,
        lanYaKeyNum: 0,
        faceKeyNum: 0,
    });
    const [search, setSearch] = useState('');
    const [email, setEmail] = useState('');
    const [deviceId, setDeviceId] = useState((props.location.state || {deviceId: ''}).deviceId || '');
    const [type, setType] = useState('');
    const [mappingType, setMappingType] = useState('');
    const [isAvailable, setIsAvailable] = useState('');
    const [devices, setDevices] = useState([]);
    const [updateDevices, setUpdateDevices] = useState([]);
    const [smsContent, setSmsContent] = useState('');
    const [deviceOnekeyVisible, setDeviceOnekeyVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [showRow, setShowRow] = useState(true);
    const [delayDate, setDelayDate] = useState(moment().add(1, 'year').format('YYYY-MM-DD HH:mm:59'));
    const [updateVisible, setUpdateVisible] = useState(false);
    const [reason, setReason] = useState('');
    const [keyEventNoteListVisible, setKeyEventNoteListVisible] = useState(false);
    const [sendDeviceVisible, setSendDeviceVisible] = useState(false);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [batchUpdateDJOrJDVisible, setbatchUpdateDJOrJDVisible] = useState(false);
    const [batchDataList, setbatchDataList] = useState([]);
    const [batchIsAvailable, setBatchIsAvailable] = useState(false);
    const [batchYq, setbatchYq] = useState(false)
    const columns = [
        {
            title: '照片',
            width: 100,
            dataIndex: 'user_img',
            render: (text: string, record: any) => {
                if (record.user_img) {
                    return <Image src={record.user_img} width={100} height={100}/>;
                }
                if (!record.facephoto) {
                    return <span>未上传</span>;
                }
                return (
                    <Image
                        src={
                            API.apiRootPath + '/api/file/get_file_qiniu/' + record.facephoto + '/' + appConfig.wuYeUuid
                        }
                        width={100}
                        height={100}
                    />
                );
            },
        },
        {
            title: '账号名称',
            width: 100,
            dataIndex: 'real_name',
        },

        {
            title: '账号',
            width: 150,
            dataIndex: 'login_id',
        },
        {
            title: '用户身份证号',
            width: 150,
            dataIndex: 'card_id_num',
        },
        {
            title: '钥匙名称',
            width: 100,
            dataIndex: 'device_name',
        },
        {
            title: '设备ID',
            width: 100,
            dataIndex: 'device_id',
        },
        // {
        // 	title: '发送者',
        // 	dataIndex: 'sendname'
        // },
        // {
        // 	title: '发送者账号',
        // 	dataIndex: 'send_by'
        // },
        {
            title: '用户权限',
            width: 100,
            dataIndex: 'type',
            render: (text: string) => {
                return getType(text);
            },
        },
        {
            title: '照片同步',
            key: 'tongbu',
            width: 100,
            render: (text: string, item: any) => {
                return (
                    <>
                        <Tag color={isFaceTongBu(item) == '已同步' ? 'green' : 'red'}>{isFaceTongBu(item)}</Tag>
                    </>
                );
            },
        },
        {
            title: '钥匙期限',
            width: 200,
            dataIndex: 'expire_time',
            render: (text: any, item: any) => {
                if (item.start_time === '1111-01-01 00:00:00' && item.expire_time === '9999-01-01 00:00:00') {
                    return '永久';
                }
                return (
                    <>
                        <div>自：{item.start_time}</div>
                        <div>至：{item.expire_time}</div>
                    </>
                );
            },
        },
        // {
        // 	title: '绑定次数',
        // 	dataIndex: 'bindnum'
        // },
        // {
        // 	title: '临时钥匙次数',
        // 	dataIndex: 'fasongnum',
        // 	render: (text: any, item: any) => {
        // 		return 5 - item.fasongnum < 0 ? 0 : 5 - item.fasongnum;
        // 	}
        // },
        // {
        // 	title: '发送时间',
        // 	dataIndex: 'createtime'
        // },
        {
            title: '操作',
            width: 150,
            fixed: 'right',
            dataIndex: 'type',
            render: (text: any, item: any) => (
                <Space>
                    <a
                        onClick={() => {
                            deleteItem(item);
                        }}
                    >
                        删除
                    </a>
                    <a
                        onClick={() => {
                            getRecord(item);
                        }}
                    >
                        设置
                    </a>
                    <a
                        onClick={() => {
                            DJORJD(item);
                        }}
                    >
                        {item.is_available ? '冻结' : '解冻'}
                    </a>
                </Space>
            ),
        },
    ];

    const DJORJD = (item: any) => {
        setItem(item);
        setReason('');
        setRemarkVisible(true);
    };
    const getRecord = async (record: any) => {
        const res = await getMappingInfo({
            deviceId: record.device_id,
            userId: record.user_id,
            loginId: record.login_id,
        });
        if (res.err === 0) {
            console.log('UpdateRecord::;', record);
            const formData: any = {
                ...res.data,
                sySendNum: res.data.sendNum - res.data.sendUserCount,
            };
            updateForm.setFieldsValue(formData);
            setUpdateVisible(true);
        } else {
            message.error(res.msg);
        }
    };

    const deleteItem = (item: any) => {
        confirm({
            title: '您确定要删除该钥匙吗?',
            okType: 'danger',
            onOk: async () => {
                let data = {
                    device_id: item.device_id,
                    contact: item.login_id,
                };
                const res = await deletKey(data);
                if (res.status === 1) {
                    msg.suspensionMsg({content: '删除成功', type: 'success'});
                    if (actionRef.current) {
                        actionRef.current.reload();
                    }
                } else {
                    msg.suspensionMsg({content: '删除失败', type: 'success'});
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const extension = (item: any) => {
        const formData: any = {
            tempDeviceId: item.device_id,
            tempLoginId: item.login_id,
        };
        extensionForm.setFieldsValue(formData);
        setDeviceOnekeyVisible(true);
    };
    const querySmsContent = async () => {
        const res = await getSmsContent({
            applicationName: API.keyApplicationName,
            source: API.source,
        });
        if (res.err === 0) {
            if (res.data !== undefined && res.data !== null) {
                if (res.data.content !== null && res.data.content !== undefined) {
                    setSmsContent(res.data.content);
                }
            }
        }
    };

    const getSelectDeviceList = async () => {
        const res = await getDevices({});
        if (res.err !== 0) {
            msg.suspensionMsg({content: res.msg});
            return;
        }
        let data: any = [];
        data.push({key: '', title: '全部设备', value: ''});
        data = data.concat(res.data);
        setDevices(data);
        setUpdateDevices(res.data);
        for (let i = 0; i < data.length; i++) {
            if (data[i].children) {
                for (let j = 0; j < data[i].children.length; j++) {
                    if (deviceId !== '' && data[i].children[j].value.includes(deviceId)) {
                        setDeviceId(data[i].children[j].value);
                        console.log('找到了：：：', data[i].children[j].value);
                        return;
                    }
                }
            }
        }
    };

    const isFaceTongBu = (record: any) => {
        if (record.facedevice === null) {
            return '非人脸设备';
        } else if (record.is_face_tongbu) {
            return '已同步';
        } else if (record.facephoto === null) {
            return '未上传照片';
        } else {
            return '未同步';
        }
    };
    const getType = (value: any) => {
        let type = '';
        switch (value) {
            case 'admin':
                type = '超级管理员';
                break;
            case 'anytime':
                type = '普通用户';
                break;
            case 'timeslot':
                type = '会议室钥匙';
                break;
            case 'manage':
                type = '管理';
                break;
            case 'look':
                type = '查看';
                break;
            case 'use_look':
                type = '使用和查看';
                break;
            case 'manage_look':
                type = '管理和查看';
                break;
            case 'use_manage':
                type = '使用和管理';
                break;
            default:
                break;
        }
        return type;
    };

    const loadMappingData = async () => {
        setIsShow(false);
        let rsp = await getMappingData({});
        setIsShow(false);
        if (rsp.err === 0) {
            setMappingTopData(rsp.data);
        }
    };
    const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
        let select = new Set();
        setQunXuan(false);

        if (selectedRows.length > 0) {
            selectedRows.map((item: any) => {
                select.add(item.login_id);
            });
        } else {
            select = new Set();
        }
        setSelectPhone(select);
        console.log('选中的手机号：：', select);
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
    };
    const rowSelection = {
        selectedRowKeys,
        preserveSelectedRowKeys: true,
        onChange: onSelectChange,
        selections: [
            {
                key: 'currentPage',
                text: '全选当前页面',
                onSelect: (changableRowKeys: any) => {
                    setQunXuan(false);
                    let select = new Set();
                    if (datas.length > 0) {
                        datas.map((item: any) => {
                            select.add(item.login_id);
                        });
                    } else {
                        select = new Set();
                    }
                    setSelectedRowKeys(changableRowKeys);
                    setSelectedRows(datas);
                },
            },
            {
                key: '"SELECT_ALL"',
                text: '全选全部页面',
                onSelect: (changableRowKeys: any) => {
                    setQunXuan(true);
                    setSelectedRows([]);
                    setSelectedRowKeys(changableRowKeys);
                },
            },
        ],
    };
    const renderStatisticTitle = (element: any, msg: string, iconStyle?: any) => {
        return (
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>{element}</div>
                {msg && (
                    <div>
                        <Tooltip title={msg}>
                            <ExclamationCircleOutlined style={iconStyle}/>
                        </Tooltip>
                    </div>
                )}
            </div>
        );
    };
    const tableExtraRender = () => {
        return (
            <Row gutter={16}>
                <Col span={4}>
                    <Card style={{backgroundColor: '#fff'}} bodyStyle={{padding: '10px 15px'}}>
                        <Statistic
                            loading={isShow}
                            title={renderStatisticTitle(
                                <span className={'single-line-text'} style={{color: '#000', fontSize: 16}}>
									钥匙总数
								</span>,
                                ''
                            )}
                            value={mappingTopData.deviceNum || 0}
                            valueStyle={{fontSize: 30}}
                            suffix={<span style={{fontSize: 16}}>个</span>}
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card style={{backgroundColor: '#fff'}} bodyStyle={{padding: '10px 15px'}}>
                        <Statistic
                            loading={isShow}
                            title={renderStatisticTitle(
                                <span className={'single-line-text'} style={{color: '#000', fontSize: 16}}>
									蓝牙设备钥匙数
								</span>,
                                ''
                            )}
                            valueStyle={{fontSize: 30}}
                            value={mappingTopData.lanYaKeyNum || 0}
                            suffix={<div style={{fontSize: 16}}>个</div>}
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card style={{backgroundColor: '#fff'}} bodyStyle={{padding: '10px 15px'}}>
                        <Statistic
                            loading={isShow}
                            title={renderStatisticTitle(
                                <span className={'single-line-text'} style={{color: '#000', fontSize: 16}}>
									人脸设备钥匙数
								</span>,
                                ''
                            )}
                            valueStyle={{fontSize: 30}}
                            value={mappingTopData.faceKeyNum || 0}
                            suffix={<div style={{fontSize: 16}}>个</div>}
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card style={{backgroundColor: '#fff'}} bodyStyle={{padding: '10px 15px'}}>
                        <Statistic
                            loading={isShow}
                            title={renderStatisticTitle(
                                <span className={'single-line-text'} style={{color: '#000', fontSize: 16}}>
									未上传照片人数
								</span>,
                                ''
                            )}
                            value={mappingTopData.noUploadImgUserNum || 0}
                            valueStyle={{fontSize: 30}}
                            suffix={<span style={{fontSize: 16}}>人</span>}
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card style={{backgroundColor: '#E82D2D'}} bodyStyle={{padding: '10px 15px'}}>
                        <Statistic
                            loading={isShow}
                            title={renderStatisticTitle(
                                <span className={'single-line-text'} style={{color: '#fff', fontSize: 16}}>
									未同步照片数量
								</span>,
                                '',
                                {color: '#fff'}
                            )}
                            valueStyle={{color: '#fff', fontSize: 30}}
                            value={mappingTopData.noTongbuUserNum || 0}
                            suffix={<div style={{color: '#fff', fontSize: 16}}>个</div>}
                        />
                    </Card>
                </Col>
            </Row>
        );
    };

    {
        /*<Button*/
    }
    {
        /*	type={'primary'}*/
    }
    {
        /*	onClick={() => {*/
    }
    {
        /*		extensionForm.resetFields();*/
    }
    {
        /*		setDeviceOnekeyVisible(true);*/
    }
    {
        /*	}}*/
    }
    {
        /*>*/
    }
    {
        /*	钥匙一键延期*/
    }
    {
        /*</Button>,*/
    }
    const tableOperationsBarRender = () => [
        <Button
            type={'primary'}
            onClick={() => {
                setSendDeviceVisible(true);
            }}
        >
            发送钥匙
        </Button>,
        <Dropdown overlay={<Menu>
            <AuthorizedView needAuthority={'entrance_guard:mapping_list:batch:update'}>
                <Menu.Item>
                    <Button
                        type={'link'}
                        onClick={() => {
                            setBatchUpdateKeyVisible(true);
                        }}
                    >
                        批量设置钥匙
                    </Button>
                </Menu.Item>
            </AuthorizedView>
            <AuthorizedView needAuthority={'entrance_guard:mapping_list:batch:update'}>
                <Menu.Item>
                    <Button
                        type={'link'}
                        onClick={() => {
                            setBatchDeleteKeyVisible(true);
                        }}
                    >
                        批量删除钥匙
                    </Button>
                </Menu.Item>
            </AuthorizedView>
        </Menu>}>
            <Button type={"link"}>批量设置 <DownOutlined/></Button>
        </Dropdown>


    ];

    const tableSearchBarRender = () => [
        <GsTreeSelect
            maxTagCount={1}
            style={{width: 150}}
            treeData={devices}
            value={deviceId.split(',')}
            onChange={(ev: any) => {
                console.log('deviceChange', ev);
                const arr = ev.filter((i) => i && i.trim());
                setDeviceId(arr.toString());
            }}
        />,
        <Select
            style={{width: 120}}
            onChange={(value: any) => {
                setType(value);
            }}
            defaultValue={''}
        >
            <Option value={''}>照片同步状态</Option>
            <Option value="success">同步成功</Option>
            <Option value="fail">同步失败</Option>
            <Option value="wait">未上传照片</Option>
        </Select>,
        <Select
            style={{width: 100}}
            value={mappingType}
            onChange={(value: any) => {
                setMappingType(value);
            }}
        >
            <Option value={''}>全部钥匙</Option>
            <Option value="anytime">普通用户</Option>
            <Option value="timeslot">会议室钥匙</Option>
            <Option value="manage">管理</Option>
            <Option value="look">查看</Option>
            <Option value="use_look">使用和查看</Option>
            <Option value="manage_look">管理和查看</Option>
            <Option value="use_manage">使用和管理</Option>
            <Option value="admin">超级管理员</Option>
        </Select>,
        <Select
            style={{width: 100}}
            defaultValue={isAvailable}
            onChange={(value: any) => {
                setIsAvailable(value);
                // if (actionRef.current) {
                // 	actionRef.current.reloadAndRest();
                // }
            }}
        >
            <Option value={''}>全部状态</Option>
            <Option value="1">正常</Option>
            <Option value="0">冻结</Option>
        </Select>,
        <RangePicker
            style={{width: 240}}
            showTime={true}
            allowClear={true}
            format="YYYY-MM-DD HH:mm"
            placeholder={['请选择开始时间', '请选择结束时间']}
            onChange={(dates: any) => {
                if (dates) {
                    console.log('dates:::::', dates);
                    setStartTime(dates[0].format('YYYY-MM-DD HH:mm:00'));
                    setEndTime(dates[1].format('YYYY-MM-DD HH:mm:59'));
                } else {
                    setStartTime('');
                    setEndTime('');
                }
            }}
        />,
        <Input
            placeholder="设备名称、用户账号、名称"
            onChange={(value: any) => {
                setSearch(value.target.value);
            }}
        />,
        <Button
            type={'primary'}
            onClick={() => {
                if (actionRef.current) {
                    actionRef.current.reloadAndRest();
                }
            }}
        >
            查询
        </Button>,
    ];
    const sendSMS = () => {
        confirm({
            title: '发送短信通知',
            content: smsContent,
            onOk() {
                sendMessage();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const sendMessage = async () => {
        const selectPhone1 = [...selectPhone];
        console.log('selectPhone.toString()', selectPhone1.toString());
        console.log('selectedRowKeys::', selectedRowKeys);
        console.log('data.quanxuan', quanxuan);
        let data = {
            wyUuid: localStorage.getItem('wyUuid'),
            inputInfo: localStorage.getItem('wyYeName') || '',
            inputPhone: selectPhone1.toString(),
            noticeType: 'key',
            search: search,
            loginId: email,
            deviceId: deviceId,
            type: type,
            mappingType: mappingType,
            quanxuan: quanxuan,
        };
        const res = await sendKeySms(data);
        if ((res as any).err === 0) {
            setQunXuan(false);
            setSelectedRowKeys([]);
            setSelectPhone(new Set());
            msg.suspensionMsg({content: (res as any).msg, type: 'success'});
        } else {
            msg.suspensionMsg({content: '发送结果' + (res as any).msg});
        }
    };
    const keyListDelay = (selectedRow: any) => {
        console.log('selectedRow:::::', selectedRow);
        let deviceIds: any = [];
        let userEmails: any = [];
        let batchKeyData = []
        selectedRow.map((item: any) => {
            batchKeyData.push({deviceId: item.device_id, loginId: item.login_id})
        });
        extensionForm.setFieldsValue({
            batchKeyData: JSON.stringify(batchKeyData)
        });
        setbatchYq(true)
        setDeviceOnekeyVisible(true);
    };

    const batchDjOrJd = (selectedRow: any, isAvailable) => {
        console.log('selectEdRow', selectedRow);
        setbatchDataList(selectedRow);
        setBatchIsAvailable(isAvailable);
        setbatchUpdateDJOrJDVisible(true);
    };

    const keyDelayItem = () => {
        extensionForm.validateFields().then(async (data: any) => {
            setConfirmLoading(true);
            const formData: any = {
                search: search,
                mappingType: mappingType,
                expireTime: delayDate,
                batchKeyData: data.batchKeyData
            };
            const res = await batchKeyDelay(formData);
            if (res.err === 0) {
                msg.suspensionMsg({content: res.msg, type: 'success'});
                setConfirmLoading(false);
                setDeviceOnekeyVisible(false);
                if (actionRef.current) {
                    actionRef.current.reload();
                }
            } else {
                setConfirmLoading(false);
                msg.suspensionMsg({content: '钥匙延期失败'});
            }
            console.log('extensionData:::', formData);
        });
    };
    const formLayout = {
        labelCol: {span: 10},
        wrapperCol: {span: 10},
    };
    const updateItem = () => {
        updateForm.validateFields().then(async (data: any) => {
            let formData = {
                needBindImsi: data.needBindImsi,
                needBindPhone: data.needBindPhone,
                bindNum: data.bindNum,
                device_id: data.deviceId,
                isAvailable: !data.isAvailable,
                userId: data.userId,
                isAutoFill: data.isAutoFill,
                sendNum: data.sendNum,
                needUserCertified: data.needUserCertified,
            };
            console.log('updateItem::', formData);
            const res = await updateMapping(formData);
            if (res.err === 0) {
                if (actionRef.current) {
                    actionRef.current.reload();
                }
                msg.suspensionMsg({content: '操作成功', type: 'success'});
                setUpdateVisible(false);
            } else {
                msg.suspensionMsg({content: '操作失败' + res.msg});
            }
        });
    };
    const updateKeyAvailable = async () => {
        if (reason == '' || reason == undefined || reason == null) {
            message.warning('请输入原因');
            return;
        }
        const formData: any = {
            userId: item.user_id,
            deviceId: item.device_id,
            isAvailable: !item.is_available,
            deviceName: item.deivice_name,
            event: reason,
        };
        const res = await updeteKeyAvailable(formData);
        if (res.err === 0) {
            message.success('修改成功');
            setRemarkVisible(false);
            setItem({});
            setReason('');
            if (actionRef.current) {
                actionRef.current.reload();
            }
        } else {
            message.error('修改失败');
        }
    };
    const batchJdOrDj = async () => {
        if (reason == '' || reason == undefined || reason == null) {
            message.warning('请输入原因');
            return;
        }
        let data = []
        batchDataList.forEach(item => {
            data.push({
                deviceId: item.device_id,
                userId: item.user_id
            })
        })
        const formData: any = {
            data: JSON.stringify(data),
            isAvailable: batchIsAvailable,
            event: reason,
        };
        setConfirmLoading(true)
        const res = await batchUpdeteKeyAvailable(formData);
        setConfirmLoading(false)
        if (res.err === 0) {
            message.success('修改成功');
            setbatchUpdateDJOrJDVisible(false);
            setReason('');
            if (actionRef.current) {
                actionRef.current.reload();
            }
        } else {
            message.error('修改失败');
        }
    }

    const bathDeleteMapping = () => {
        Modal.confirm({
            title: '提示',
            content: '您确定要删除该钥匙？',
            okType: 'danger',
            okText: '删除',
            onOk: async () => {
                let params = {
                    search: search,
                    loginId: email,
                    deviceId: deviceId,
                    type: type,
                    mappingType: mappingType,
                    quanxuan: quanxuan,
                    ekeyStartTime: startTime,
                    ekeyEndTime: endTime,
                    ids: selectedRowKeys.toString(),
                };
                let rsp = await deleteMappingList(params);
                if (rsp.err === 0) {
                    setSelectedRowKeys([]);
                    setSelectedRows([]);
                    setDeleteLoading(true);
                    setDeletePercent(0);
                } else {
                    message.error(rsp.msg);
                }
            },
        });
    };

    const expireData = async () => {
        setIsShow(true);
        if (quanxuan) {
            let data = {
                search: search,
                isAvailable: isAvailable,
                loginId: email,
                deviceId: deviceId,
                type: type,
                mappingType: mappingType,
                ekeyStartTime: startTime,
                ekeyEndTime: endTime,
            };
            let rsp = await getMappingList(data);
            if (rsp.err === 0) {
                setExprotData(rsp.data);
            } else {
                setIsShow(false);
                message.error(rsp.msg);
            }
        } else {
            setExprotData(selectedRows);
        }
    };
    const setExprotData = (selectedRows: any[]) => {
        let sheetData = new Array(selectedRows.length + 1);
        let titles: [string?] = [];
        columns.map((item: any) => {
            titles.push(item.title);
        });
        sheetData[0] = titles;
        for (let i = 0; i < selectedRows.length; i++) {
            let data: [string?] = [];
            columns.map((item: any) => {
                if (item.valueEnum) {
                    if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
                        data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
                    } else {
                        data.push('');
                    }
                } else if (item.valueType === 'dateTime') {
                    data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
                } else {
                    data.push(selectedRows[i][item.dataIndex]);
                }
            });
            sheetData[i + 1] = data;
        }

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, '钥匙列表');

        /* save to file */
        XLSX.writeFile(wb, '钥匙列表.xlsx');
        setIsShow(false);
    };

    const loadList = async (data: any) => {
        let res = await getMappingList(data);
        if (res.err !== 0) {
            return {
                data: [],
                total: 0,
            };
        }
        let selectedRowKeys: any = [];
        if (quanxuan) {
            if (res.data.length > 0) {
                res.data.map((item: any) => {
                    selectedRowKeys.push(item.id);
                });
            }
        }
        setIsShow(false);
        setDatas(res.data);
        setDataCount(res.total);
        setSelectedRowKeys(selectedRowKeys);
        // setMappingTopData(res.mappingTopData);
        return {
            data: res.data,
            total: res.total,
            success: true,
        };
    };
    const batchUpdateKeyOk = async () => {
        let data: any = await updateKeyForm.validateFields();
        console.log('data', data);
        let params = {
            ...data,
            account: data.account.toString(),
            deviceIds: data.deviceIds.toString(),
        };
        setConfirmLoading(true);
        let rsp = await batchUpdateEKey(params);
        setConfirmLoading(false);
        if (rsp.err === 0) {
            message.success(rsp.msg);
            setBatchUpdateKeyVisible(false);
            if (actionRef.current) {
                actionRef.current.reload();
            }
        } else {
            message.error(rsp.msg);
        }
    };

    const batchDeleteKeyOk = async () => {
        let data: any = await deleteKeyForm.validateFields();
        console.log('data', data);
        let params = {
            ...data,
            account: data.account.toString(),
            deviceIds: data.deviceIds.toString(),
        };
        setConfirmLoading(true);
        let rsp = await batchDeleteEkey(params);
        setConfirmLoading(false);
        if (rsp.err === 0) {
            message.success(rsp.msg);
            setBatchDeleteKeyVisible(false);
            if (actionRef.current) {
                actionRef.current.reload();
            }
        } else {
            message.error(rsp.msg);
        }
    };

    return (
        <div>
            <Spin spinning={deleteLoading} indicator={<Progress type="circle" percent={deletePercent}/>}>
                <GSTable
                    columns={columns}
                    actionRef={actionRef}
                    searchBarRender={tableSearchBarRender()}
                    rowKey="id"
                    rowSelection={rowSelection}
                    tableAlertRender={({selectedRowKeys, selectedRows, onCleanSelected}: any) => {
                        return (
                            <Space size={24}>
								<span>
									已选 {quanxuan ? dataCount : selectedRowKeys.length} 项
									<a style={{marginLeft: 8}} onClick={onCleanSelected}>
										取消选择
									</a>
								</span>
                            </Space>
                        );
                    }}
                    tableAlertOptionRender={() => {
                        return (
                            <Space size={16}>
                                <a onClick={bathDeleteMapping}>删除</a>
                                {!quanxuan && <a onClick={() => keyListDelay(selectedRows)}>钥匙延期</a>}
                                {!quanxuan && <a onClick={() => batchDjOrJd(selectedRows, false)}>冻结</a>}
                                {!quanxuan && <a onClick={() => batchDjOrJd(selectedRows, true)}>解冻</a>}
                                {/*<a onClick={sendSMS}>发送短信通知</a>*/}
                                <Button type={'link'} loading={isShow} onClick={expireData}>
                                    导出
                                </Button>
                            </Space>
                        );
                    }}
                    tableExtraRender={tableExtraRender}
                    operationsBarRender={tableOperationsBarRender()}
                    request={async (params: any = {}, sort: any, filter: any) => {
                        let data = {
                            page: params.current,
                            size: params.pageSize,
                            search: search,
                            isAvailable: isAvailable,
                            loginId: email,
                            deviceId: deviceId,
                            type: type,
                            ekeyStartTime: startTime,
                            ekeyEndTime: endTime,
                            mappingType: mappingType,
                        };
                        return loadList(data);
                    }}
                />
            </Spin>
            <Modal
                width={600}
                visible={deviceOnekeyVisible}
                title="钥匙延期"
                onOk={keyDelayItem}
                onCancel={() => {
                    setDeviceOnekeyVisible(false);
                }}
                confirmLoading={confirmLoading}
            >
                <Form {...formLayout} form={extensionForm}>
                    <FormItem label={'tempLoginId'} name={'tempLoginId'} hidden={true}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'tempDeviceId'} name={'tempDeviceId'} hidden={true}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'batchKeyData'} name={'batchKeyData'} hidden={true}>
                        <Input/>
                    </FormItem>

                    <FormItem label={'延期'} name={'yanqi'}>
                        <RadioGroup
                            onChange={(value: any) => {
                                setShowRow(value.target.value);
                                if (value.target.value) {
                                    setDelayDate(moment().add(1, 'year').format('YYYY-MM-DD HH:mm:59'));
                                } else {
                                    setDelayDate('');
                                }
                            }}
                            defaultValue={true}
                        >
                            <Radio value={true}>
                                <Tooltip overlay={'钥匙有效期在原有基础上延长一年'}>一年</Tooltip>
                            </Radio>
                            <Radio value={false}>自定义</Radio>
                        </RadioGroup>
                    </FormItem>
                    <FormItem hidden={showRow} label={'有效期延长至：'} name={'delayDate'}>
                        <DatePicker
                            showTime={true}
                            format="YYYY-MM-DD HH:mm:ss"
                            placeholder="选择或者输入日期+时间"
                            onChange={(date: any, dataToString: any) => {
                                setDelayDate(dataToString);
                            }}
                        />
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                title={'操作'}
                width={600}
                visible={updateVisible}
                onCancel={() => {
                    setUpdateVisible(false);
                }}
                onOk={updateItem}
            >
                <Form {...formLayout} form={updateForm}>
                    <FormItem hidden={true} name={'deviceId'}>
                        <Input/>
                    </FormItem>
                    <FormItem hidden={true} name={'userId'}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'设备名称'} name={'deviceName'}>
                        <Input disabled={true}/>
                    </FormItem>
                    <FormItem label={'钥匙名称'} name={'keyName'}>
                        <Input disabled={true}/>
                    </FormItem>
                    {/*<FormItem label={'是否冻结'} name={'isAvailable'} valuePropName={'checked'}>*/}
                    {/*	<Switch />*/}
                    {/*</FormItem>*/}
                    <FormItem label={'是否需要绑定IMSI卡'} valuePropName={'checked'} name={'needBindImsi'}>
                        <Switch/>
                    </FormItem>
                    <FormItem label={'绑定时是否自动填写验证码'} valuePropName={'checked'} name={'isAutoFill'}>
                        <Switch/>
                    </FormItem>
                    <FormItem label={'是否需要绑定手机'} valuePropName={'checked'} name={'needBindPhone'}>
                        <Switch/>
                    </FormItem>
                    <FormItem label={'是否需要实名认证'} valuePropName={'checked'} name={'needUserCertified'}>
                        <Switch/>
                    </FormItem>
                    <FormItem label={'绑定次数'} name={'bindNum'}>
                        <InputNumber min={0} max={10}/>
                    </FormItem>
                    <FormItem label={'每月可发送的临时钥匙次数'} name={'sendNum'}>
                        <InputNumber min={0} max={10}/>
                    </FormItem>
                    <FormItem label={'剩余的临时钥匙次数'} name={'sySendNum'}>
                        <InputNumber disabled={true} min={0} max={10}/>
                    </FormItem>
                    <FormItem label={'发送者'} name={'sendName'}>
                        <Input disabled={true}/>
                    </FormItem>
                    <FormItem label={'发送者账号'} name={'sendBy'}>
                        <Input disabled={true}/>
                    </FormItem>
                    <FormItem label={'发送时间'} name={'createtime'}>
                        <Input disabled={true}/>
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                title={(item.is_available ? '冻结' : '解冻') + '钥匙：' + item.device_name}
                width={600}
                visible={keyRemarkVisible}
                onCancel={() => {
                    setRemarkVisible(false);
                }}
                onOk={updateKeyAvailable}
            >
                <Row>
                    <Col span={24}>
                        <Input.TextArea
                            value={reason}
                            allowClear={true}
                            autoSize={{maxRows: 3, minRows: 3}}
                            onChange={(e) => {
                                setReason(e.target.value);
                            }}
                            placeholder={'钥匙：' + item.device_name + (item.is_available ? ' 冻结' : '解冻') + '原因'}
                        />
                    </Col>
                </Row>
                <Row justify={'end'}>
                    <Col>
                        <a
                            onClick={() => {
                                setKeyEventNoteListVisible(true);
                            }}
                        >
                            查看处理记录
                        </a>
                    </Col>
                </Row>
            </Modal>

            <Modal
                width={960}
                visible={keyEventNoteListVisible}
                title={item.device_name + ' 处理记录'}
                footer={null}
                onCancel={() => {
                    setKeyEventNoteListVisible(false);
                }}
            >
                <KeyEventNote key={item.device_id} deviceId={item.device_id}/>
            </Modal>
            {/*发钥匙*/}
            <SendKeyModal
                visible={sendDeviceVisible}
                onCancel={() => {
                    setSendDeviceVisible(false);
                }}
                deviceId={deviceId || undefined}
                callback={() => {
                    if (actionRef.current) {
                        actionRef.current.reload();
                    }
                }}
            />
            <Modal
                visible={batchUpdateKeyVisible}
                title={'处理'}
                confirmLoading={confirmLoading}
                onOk={batchUpdateKeyOk}
                onCancel={() => {
                    setBatchUpdateKeyVisible(false);
                }}
            >
                <Form form={updateKeyForm}>
                    <Form.Item label={'账号'} name={'account'} rules={[{required: true, message: '请填写'}]}>
                        <Select
                            mode="tags"
                            style={{width: '100%'}}
                            tokenSeparators={[',', '\n', '，', '\\', '\t', '\r\n', ' ']}
                        />
                    </Form.Item>
                    <Form.Item label={'设备'} name={'deviceIds'} rules={[{required: true, message: '请选择'}]}>
                        <GsTreeSelect style={{width: '100%'}} treeData={updateDevices}/>
                    </Form.Item>
                    <Form.Item label={'操作'} name={'available'} rules={[{required: true, message: '请选择'}]}>
                        <Select>
                            <Select.Option value={false}>冻结</Select.Option>
                            <Select.Option value={true}>激活</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                visible={batchDeleteKeyVisible}
                title={'批量删除钥匙'}
                confirmLoading={confirmLoading}
                onOk={batchDeleteKeyOk}
                onCancel={() => {
                    setBatchDeleteKeyVisible(false);
                }}
            >
                <Form form={deleteKeyForm}>
                    <Form.Item label={'账号'} name={'account'} rules={[{required: true, message: '请填写'}]}>
                        <Select
                            mode="tags"
                            style={{width: '100%'}}
                            tokenSeparators={[',', '\n', '，', '\\', '\t', '\r\n', ' ']}
                        />
                    </Form.Item>
                    <Form.Item label={'设备'} name={'deviceIds'} rules={[{required: true, message: '请选择'}]}>
                        <GsTreeSelect style={{width: '100%'}} treeData={updateDevices}/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={(batchIsAvailable ? '解冻' : '冻结')}
                width={600}
                confirmLoading={confirmLoading}
                visible={batchUpdateDJOrJDVisible}
                onCancel={() => {
                    setbatchUpdateDJOrJDVisible(false);
                }}
                onOk={batchJdOrDj}
            >
                <Row>
                    <Col span={24}>
                        <Input.TextArea
                            value={reason}
                            allowClear={true}
                            autoSize={{maxRows: 3, minRows: 3}}
                            onChange={(e) => {
                                setReason(e.target.value);
                            }}
                            placeholder={'钥匙：' + (batchIsAvailable ? ' 解冻' : '冻结') + '原因'}
                        />
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};
export default MappingList;
