import React, { useEffect, useState } from 'react';
import { message, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import appConfig from '../appConfig';
import { RcFile } from 'antd/lib/upload';

let lrz = require('lrz');

interface UploadFileProps {
	imageUrl: string;
	onChange: (url: string) => void;
	multiple: boolean;
}

const UploadImage: React.FC<UploadFileProps> = (props: UploadFileProps) => {
	const [imageUrl, setImageUrl] = useState('');
	const maxFileSizeLimit = 10; // 单位M
	const [fileList, setFileList] = useState([]);

	useEffect(() => {
		if (props.imageUrl !== '') {
			let fileUrl: string = props.imageUrl;
			let fileList1: any = [];
			if (fileUrl !== '') {
				fileUrl.split(',').map((item) => {
					let file: any = {
						uid: new Date().getTime(),
						name: '照片',
						status: 'done',
						url: item,
						response: {
							url: item,
						},
					};
					fileList1.push(file);
				});
			}
			setImageUrl(props.imageUrl);
			setFileList(fileList1);
		}
	}, []);

	const onChangeFile = (info: any) => {
		const { status } = info.file;
		if (status === undefined) {
			return;
		}

		// let fileList = [...info.fileList];
		console.log('UploadFile.onChange: ', status, info.fileList.length);

		// fileList = fileList.slice(-1);

		setFileList(info.fileList);

		if (status !== 'uploading') {
			console.log(info.file, info.fileList);
		}
		if (status === 'removed') {
			let file = info.file;
			let url = file.response.url;
			let imageUrls = imageUrl.split(',').filter((item: string) => {
				return !item.includes(url);
			});
			console.log(imageUrls, imageUrl, url);
			setImageUrl(imageUrls.toString());
			props.onChange(imageUrls.toString());
		}
		if (status === 'done') {
			let name: any = [];
			console.log('上传文件列表：', info.fileList);
			info.fileList.map((file: any) => {
				if (file.response.url.includes('http://') || file.response.url.includes('https://')) {
					name.push(file.response.url);
				} else {
					name.push(
						appConfig.apiRootPath +
							'/api/file/get_file_qiniu/' +
							file.response.url +
							'!!!' +
							info.file.name +
							'/' +
							appConfig.wuYeUuid
					);
				}
			});
			setImageUrl(name.toString());
			props.onChange(name.toString());
			message.success(`${info.file.name} 上传成功`);
		} else if (status === 'error') {
			message.error(`${info.file.name} 上传失败`);
		}
	};





	/**
	 * 检查文件尺寸
	 */
	const beforeUploadFile = (file: RcFile, fileList1: RcFile[]): boolean => {
		const fileSize = file.size / 1024 / 1024;
		console.log('UploadFile.beforeUpload: ', maxFileSizeLimit, fileSize);

		const isInLimit = fileSize < maxFileSizeLimit;
		if (!isInLimit) {
			message.error('上传图片不要超过' + maxFileSizeLimit + 'M');
		}
		return isInLimit;
	};

	const getUploadApiExtraData = (file: RcFile) => {
		return {
			wuye_uuid: localStorage.getItem('wyUuid'),
		};
	};

	const transformFile = (file: RcFile) => {
		return lrz(file, { quality: 0.85, width: 1280 })
			.then((rt: any) => {
				let soureFile = rt.file;
				console.log('文件大小：：：', file.size, soureFile.size);
				return new Promise(function (resolve: any, reject: any) {
					resolve(soureFile);
				});
			})
			.catch((e: any) => {
				console.log('发生异常', e);
				return new Promise(function (resolve: any, reject: any) {
					resolve(file);
				});
			});
	};

	const uploaderProps = {
		name: 'file',
		action: appConfig.apiRootPath + '/api/picture/upload_qiniu', // 文件上传提交地址
		beforeUpload: beforeUploadFile,
		onChange: onChangeFile,
		data: getUploadApiExtraData,
		transformFile: transformFile,
		showUploadList: {
			showRemoveIcon: true,
			showDownloadIcon: false,
			showPreviewIcon: false,
		},
	};

	return (
		<Upload {...uploaderProps} listType={'picture-card'} accept={'image/*'} fileList={fileList}>
			{!props.multiple && imageUrl !== '' ? null : (
				<div>
					<PlusOutlined />
					<p className="ant-upload-hint">点击或拖拽图片到此处上传</p>
				</div>
			)}
		</Upload>
	);
};
export default UploadImage;
