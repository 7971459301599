import React from 'react';
import MiniApp, {MiniAppMenu, MiniAppRoute} from "../../util/MiniApp";
import InvestmentProjectsList from "./pages/InvestmentProjectsList";
import ApplicationList from "./pages/ApplicationList";
const index: React.FC = () => {
  const rootUrl = '/investment_projects';
  const menus: MiniAppMenu[] = [
    {
      name: '项目列表',
      path: 'list',
      authority: 'investment_projects:list',
      component: InvestmentProjectsList,
    }, {
      name: '投资申请',
      path: 'application-list',
      authority: 'investment_projects:application:list',
      component: ApplicationList,
    },
  ];
  const routes: MiniAppRoute[] = [
  ];
  return <MiniApp miniAppName={'投资项目管理'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
export default index;
