import React, { Component } from 'react';
import GSTable from '../../../components/GSTable';
import API from '../../../data/API';
import { Button, Table, Upload, Space, Row, Col, Modal, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Api from '../../../data/API';
import * as XLSX from 'xlsx';
import { batchImportHousehold } from '../../../services/ZhuhushenheService';

const msg = require('../../../util/SuspensionMsg');
var myXlsx = require('../../../util/myXlsx');

class BatchHousehold extends Component {
	actionRef: any;

	/*定义属性*/
	batchColumns = [
		{
			title: '楼栋名称',
			key: '楼栋名称',
			dataIndex: '楼栋名称',
		},
		{
			title: '房间编号',
			key: '房间编号',
			dataIndex: '房间编号',
		},
		{
			title: '单元号',
			key: '单元号',
			dataIndex: '单元号',
		},
		{
			title: '楼层号',
			key: '楼层号',
			dataIndex: '楼层号',
		},
		{
			title: '建筑面积',
			key: '建筑面积',
			dataIndex: '建筑面积',
		},
		{
			title: '套内面积',
			key: '套内面积',
			dataIndex: '套内面积',
		},
		{
			title: '花园面积',
			key: '花园面积',
			dataIndex: '花园面积',
		},
		{
			title: '阁楼面积',
			key: '阁楼面积',
			dataIndex: '阁楼面积',
		},
		{
			title: '计费面积',
			key: '计费面积',
			dataIndex: '计费面积',
		},
		{
			title: '房间备注',
			key: '房间备注',
			dataIndex: '房间备注',
		},
		{
			title: '业户姓名',
			key: '业户姓名',
			dataIndex: '业户姓名',
		},
		{
			title: '业户类型',
			key: '业户类型',
			dataIndex: '业户类型',
		},
		{
			title: '性别',
			key: '性别',
			dataIndex: '性别',
		},
		{
			title: '手机号',
			key: '手机号',
			dataIndex: '手机号',
		},
		{
			title: '邮箱',
			key: '邮箱',
			dataIndex: '邮箱',
		},
		{
			title: '备用联系方式',
			key: '备用联系方式',
			dataIndex: '备用联系方式',
		},
		{
			title: '证件类型',
			key: '证件类型',
			dataIndex: '证件类型',
		},
		{
			title: '证件号码',
			key: '证件号码',
			dataIndex: '证件号码',
		},
		{
			title: '入住时间',
			key: '入住时间',
			dataIndex: '入住时间',
		},
		{
			title: '租住到期时间',
			key: '租住到期时间',
			dataIndex: '租住到期时间',
		},
	];

	constructor(props: any) {
		super(props);
		this.state = {
			url: window.location.pathname,
			batchData: [],
			errCount: 0,
			successCount: 0,
			errData: [],
			successStatus: 'block',
			errStatus: 'none',
			confirmLoading: false,
		};
	}
	batchErrorColumns = [...this.batchColumns];

	/** 模板下载 */
	exportBmry = () => {
		// let sheetData = new Array(1);
		// sheetData[0] = ['楼号', '房间号', '面积', '物业费', '是否入住',	'是否是业主', '承租人姓名',	'承租人性别',	'承租人手机号',	 '入住时间',	'证件类型', '证件号码', '合同开始时间', '合同结束时间'];
		// sheetData[1] = ['1号楼', '1-1-0101', '120.12', '400.00', '0: 没有入住，1：入住', '承租人，家庭成员，同住人', '某某某', '0:男，1：女', '132XXXXXXXX', '2019-01-01 23:23:23', '身份证', '350****************', '2019-01-01 23:23:23', '2019-01-01 23:23:23'];
		// const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		// const wb: XLSX.WorkBook = XLSX.utils.book_new();
		// XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		// XLSX.writeFile(wb, '住户.xlsx');
		window.open(
			'https://pcdn.guosim.com/template/%E6%88%BF%E9%97%B4%E4%BD%8F%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
			'_blank'
		);
	};

	/*导入信息显示*/
	analyze = (evv: any) => {
		console.log('上传返回', evv);
		let that = this;
		myXlsx.readData(evv, function (res: any) {
			that.setState({
				batchData: res,
			});
		});
	};

	/*导入数据*/
	batchImport = async () => {
		let that = this;
		if ((this.state as any).batchData.length == 0) { 
			message.warning("请上传文件")
			return
		}
		this.setState({
			confirmLoading: true,
		});
		const res = await batchImportHousehold({
			data: JSON.stringify((this.state as any).batchData),
		});
		// Api.postWithAuth('/api/zhuhu/batch_import_household', {data: JSON.stringify((this.state as any).batchData)}).then(res => {
		that.setState({
			batchData: [],
			// batchVisible: false,
			confirmLoading: false,
			// batchResultVisible: true
		});

		if (res.err === 0) {
			msg.suspensionMsg({ content: '导入成功', type: 'success' });
			that.setState({
				errCount: res.errCount,
				successCount: res.successCount,
				errData: res.errData,
				errStatus: 'block',
				successStatus: 'none',
			});
		} else {
			msg.suspensionMsg({ content: res.msg });
		}
		// });
	};

	/*下载导入*/
	downloadErrData = () => {
		let sheetData = new Array(1);
		sheetData[0] = [];
		this.batchErrorColumns.map((item) => {
			sheetData[0].push(item.title);
		});
		(this.state as any).errData.map((record: any, index: number) => {
			let newIndex = index + 1;
			sheetData[newIndex] = [];
			sheetData[0].map((data: string) => {
				sheetData[newIndex].push(record[data]);
			});
		});
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.writeFile(wb, '导入失败住户.xlsx');
		// this.setState({
		//     errStatus:'none',
		//     successStatus:'block',
		// })
		// this.setState({batchResultVisible: false});
	};

	/*按钮*/
	// tableOperationsBarRender  = () =>([
	//     <Upload showUploadList={false}  onChange={this.analyze} accept={'.xlsx'}>
	//         <Button>
	//             <UploadOutlined/> 选择文件
	//         </Button>
	//     </Upload>,
	//
	//     <Button type="primary"  onClick={this.exportBmry}>模版下载</Button>
	// ])

	/*返回继续导入*/
	changeStatus = () => {
		this.setState({
			errStatus: 'none',
			successStatus: 'block',
		});
	};

	render() {
		return (
			<div>
				{/*导入信息*/}
				<div style={{ display: (this.state as any).successStatus }}>
					<Space style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-end' }}>
						<Upload showUploadList={false} onChange={this.analyze} accept={'.xlsx'}>
							<Button>
								<UploadOutlined /> 选择文件
							</Button>
						</Upload>
						<Button type="primary" onClick={this.exportBmry}>
							模版下载
						</Button>
						<Button type="primary" onClick={this.batchImport}>
							开始导入
						</Button>
					</Space>
					<Table
						columns={this.batchColumns}
						loading={(this.state as any).confirmLoading}
						size="middle"
						dataSource={(this.state as any).batchData}
					/>
				</div>
				{/*导入完得反馈*/}
				<div style={{ display: (this.state as any).errStatus }}>
					<div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
						<Space>
							成功导入：{(this.state as any).successCount} 导入失败：{(this.state as any).errCount}
						</Space>
						<Space>
							<Button type="primary" onClick={this.downloadErrData}>
								下载
							</Button>
							<Button type="primary" onClick={this.changeStatus}>
								返回批量导入
							</Button>
						</Space>
					</div>
					<Table columns={this.batchErrorColumns} size="middle" dataSource={(this.state as any).errData} />
				</div>
			</div>
		);
	}
}

export default BatchHousehold;
