import Api from '../data/API';
export async function getList(params: any) {
  return Api.postWithAuth('/admin/api/work-task/list', params);
}
export async function auditorWorkTask(params: any) {
  return Api.postWithAuth('/admin/api/work-task/auditor', params);
}
export async function getIntentionList(params: any) {
  return Api.postWithAuth('/admin/api/work-task/intention/list', params);
}
export async function auditorIntention(params: any) {
  return Api.postWithAuth('/admin/api/work-task/intention/auditor', params);
}
