import * as React from 'react';
import { Avatar, Button, Col, Dropdown, Form, Input, Layout, Menu, message, Modal, Row, Space, Tooltip } from 'antd';
import { Icon } from '@ant-design/compatible';
import DAL from '../data/DAL';
import API from '../data/API';
import './AppHeader.css';
import { useEffect, useState } from 'react';
import ProxyForm from './ProxyForm';
import { change_AdminProject } from '../services/login';
import { Reducer, Dispatch } from 'redux';
import { connect } from 'dva';
import ConnectState from '../models/connect';
import { getAllReleaseNote } from '../services/ReleaseNoteService';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import Api from '../data/API';
import FullScrenn from '../util/fullScreen';
import {
	DeleteOutlined,
	EllipsisOutlined,
	FullscreenExitOutlined,
	FullscreenOutlined, HomeFilled,
	MailOutlined
} from '@ant-design/icons/lib';
import AppMenu from './AppMenu';
import { NavLink } from 'react-router-dom';

const { Header } = Layout;

let msg = require('../util/SuspensionMsg');
let packageJson = require('../../package.json');

interface AppHeaderProps {
	isSidebarCollapsed: boolean;
	toggleSidebar: () => void;
	dispatch: Dispatch;
	onChange?: (id: any) => void;
	login?: any
}

const AppHeader: React.FC<AppHeaderProps> = (props) => {
	const { dispatch } = props;
	const dal = DAL.dal;
	const [password, setPassword] = useState('');
	const [pwd, setPwd] = useState('');
	const [pwds, setPwds] = useState('');
	const { login } = props;
	const [visible, setVisible] = useState(false);

	const [userName, setUserName] = useState('');
	const [wuyeName, setWuyeName] = useState('');
	const [account, setAccount] = useState('');
	const [userHead, setUserHead] = useState('');
	const [project, setProject] = useState([]);
	const [skey, setKey] = useState('');
	const [noteVisible, setNoteVisible] = useState(false);
	const [releaseNoteData, setReleaseNoteData] = useState([]);
	const [defaultKeys, setDefaultKeys] = useState([]);
	const [note, setNote] = useState('');
	const [hiddenPwd, setHiddenPwd] = useState(true);
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	// const [openMenu, setMenu] = useState(true);
	const [isScreenFull, setIsScreenFull] = useState(false); //是否全屏
	const [collection, setCollection] = useState<any>([]);
	const [menu, setMenu] = useState(login.currentUser.menu);

	useEffect(() => {
		getUserInfo();
		getNote();
		FullScrenn.init(screenChange);
		let collection = (DAL.dal.getCookie(Api.userId + 'collectionMenu') || '').split(',');
		setCollection(collection);
	}, []);
	//屏幕变化
	const screenChange = (isFull: any) => {
		console.log('是否全屏', isFull);
		setIsScreenFull(isFull);
	};
	const gotoIcon = (value: any) => {
		switch (value) {
			case '全屏':
				if (isScreenFull) {
					//退出全屏
					FullScrenn.exitFullScreen();
				} else {
					//进入全屏
					FullScrenn.enterFullScreen();
				}
				break;
			default:
				break;
		}
	};
	const getUserInfo = () => {
		dal.getUserInfo().then((res) => {
			console.log('resUserInfo:::', res);
			setUserName(res.userName);
			setWuyeName(res.wyYeName);
			setAccount(res.account);
			setUserHead(res.userHead);
			setProject(res.project);

			localStorage.setItem('wyYeName', res.wyYeName);
			localStorage.setItem('userName', res.userName);
			localStorage.setItem('watermark', res.watermark);
			localStorage.setItem('projects', res.project);
		});
	};


	const logout = () => {
		localStorage.clear();
		window.location.href = '/wy/login';
	};

	const updatePwd = () => {
		form.validateFields().then(async (data: any) => {
			if (data.pwds === '') {
				msg.suspensionMsg({ content: '请输入重复密码', type: 'danger' });
				return;
			}
			if (data.pwds !== data.pwd) {
				msg.suspensionMsg({ content: '两次密码输入不一致', type: 'danger' });
				return;
			}
			API.postWithAuth('/api/user/updatePwd', {
				password: data.password,
				pwd: data.pwd,
				pwds: data.pwds
			}).then((res) => {
				if (res.err === 0) {
					msg.suspensionMsg({ content: res.msg, type: 'success' });
					Api.pd = data.password;
					cancelChangePassword();
					logout();
				} else {
					msg.suspensionMsg({ content: res.msg });
				}
			});
		});
	};

	const getNote = async () => {
		const res = await getAllReleaseNote({});
		if (res.err === 0) {
			console.log('res:::note', res);
			if (res.data[0] != undefined) {
				setKey(res.data[0].version);
				setDefaultKeys(res.data[0].version);
				setNote(res.data[0].note);
			}
			setReleaseNoteData(res.data);
			if (res.password !== null && res.password !== undefined && res.password !== '') {
				setHiddenPwd(false);
			}
		}
	};
	const showChangePasswordModal = () => {
		setVisible(true);
	};

	const cancelChangePassword = () => {
		setVisible(false);
	};

	const openRelease = () => {
		setNoteVisible(true);
	};
	const changeCurrentWuyeProject = async (e: any) => {
		// const res = await change_AdminProject({
		//     projectUuid: e.key
		// })

		console.log('this.props', props as any);
		dispatch({
			type: 'login/changeAdminProject',
			payload: {
				projectUuid: e.key
			}
		});

		getUserInfo();
	};

	const handleClick1 = (e: any) => {
		const res = releaseNoteData.filter((item: any) => {
			return item.version === e.key;
		});
		setNote(res[0].note);
	};
	const mySettingsMenu = (
		<Menu>
			<Menu.Item>
				<a rel="noopener noreferrer" href="#">
					账号：{account}
				</a>
			</Menu.Item>
			<Menu.Item>
				<a rel="noopener noreferrer" onClick={showChangePasswordModal} href="#">
					{hiddenPwd ? '设置密码' : '修改密码'}
				</a>
			</Menu.Item>
			<Menu.Item>
				<a rel="noopener noreferrer" onClick={logout} href="#">
					退出
				</a>
			</Menu.Item>

			<Menu.Item>
				<a rel="noopener noreferrer" href="#">
					版本：{packageJson.version}
				</a>
			</Menu.Item>
			<Menu.Item>
				<a rel="noopener noreferrer" onClick={openRelease} href="#">
					版本发布
				</a>
			</Menu.Item>
		</Menu>
	);

	const wuyeProjectMenu = (
		<Menu onClick={changeCurrentWuyeProject}>
			{project.map((item: any, index: number) => (
				<Menu.Item key={item.projectUuid}>
					<a rel="noopener noreferrer">{item.projectName}</a>
				</Menu.Item>
			))}
		</Menu>
	);

	// const everyOne =()=>{
	//
	//     const res = releaseNoteData.filter((item:any)=>{
	//         return item.version === skey;
	//     })
	//     console.log("filterRes",res)
	//     return(
	//         <div>
	//             {res.note}
	//         </div>
	//     )
	//
	// }

	const onChangeItem = () => {
		let collection = (DAL.dal.getCookie(Api.userId + 'collectionMenu') || '').split(',');
		console.log("collectionMenu:::",collection);

		setCollection(collection);
	};
	return (
		<Header style={{ height: 48 }} className="basic-layout-header">
			<a href="/" style={{ color: '#fff', fontSize: 16, maxWidth: 200 }}>
				<Space>
					<HomeFilled/>
					{
						<span>{localStorage.getItem('wuyeSlogan')}</span>
					}
				</Space>
			</a>
			<div className="app-header-title-wrapper"
				 style={{ display: 'flex', flex: 1, alignItems: 'center', height: 47, color: '#fff' }}>
				<NavLink to={'/home'} style={{ marginLeft: 20, color: '#fff', fontSize: 14,width: 30, }}>首页</NavLink>
				<div style={{ marginLeft: 40, marginRight: 40 , width: 80}}>
					<AppMenu toggleSidebar={props.isSidebarCollapsed} onChangeItem={onChangeItem} onChange={(id: any) => {
						if (props.onChange) {
							props.onChange(id);
						}
					}}/>
				</div>
				<div style={{ width: 1, backgroundColor: '#FFF', height: 26 }}/>
				<Menu theme={'dark'} overflowedIndicator={<EllipsisOutlined style={{ color: '#fff' }}/>}
					  className="custom_header_menu" mode="horizontal"
					style={{ lineHeight: '46px', backgroundColor: '#1B2553', color: '#fff' }}>


					{collection.map((collectionMenu: any) => {
						if (DAL.dal.isNotNull(collectionMenu)) {
							return menu.map((item: any) => {
								if (item.children) {
									return item.children.map((children: any) => {
										if (collectionMenu == children.path) {
											return <Menu.Item key={children.path}>
													{
														(children.path.includes("https://") || children.path.includes("http://")) ? <a onClick={() => {
															if (props.onChange) {
																props.onChange(children.key);
															}
														}} href={children.path} target={'_black'} style={{
															fontSize: 14,
															color: '#fff'
														}}>
															{children.name}
														</a> : <NavLink onClick={() => {
															if (props.onChange) {
																props.onChange(children.key);
															}
														}} to={children.path} style={{
															fontSize: 14,
															color: '#fff'
														}}>
															{children.name}
														</NavLink>
													}

											</Menu.Item>;
										}
										return;
									});
								}
							})
						}
					})}

					{/* {
						menu.map((item: any) => {
							if (item.children) {
								return item.children.map((children: any) => {
									if (collection.indexOf(children.path) !== -1) {
										return <Menu.Item key={children.path}>
											<NavLink onClick={() => {
												if (props.onChange) {
													props.onChange(children.key);
												}
											}} to={children.path} style={{
												fontSize: 14,
												color: '#fff'
											}}>
												{children.name}
											</NavLink>
										</Menu.Item>;
									}
									return;
								});
							}
						})
					} */}
				</Menu>
			</div>
			<div className="app-header-slider-title">
				<Dropdown overlay={wuyeProjectMenu}>
					<Button style={{ color: '#fff' }} type="link">
						{wuyeName}
						<b className="caret"/>
					</Button>
				</Dropdown>
				<Dropdown overlay={mySettingsMenu}>
					<Button style={{ color: '#fff' }} type="link">
						<Avatar src={userHead}/>
						&nbsp;&nbsp;{userName}
						<b className="caret"/>
					</Button>
				</Dropdown>
				{/*<a>*/}
				{/*    <Icon*/}
				{/*        type={props.isSidebarCollapsed ? "menu-unfold" : "menu-fold"}*/}
				{/*        onClick={props.toggleSidebar}*/}
				{/*        style={{ color: "#fff", fontSize: 20 }}*/}
				{/*    />*/}
				{/*</a>*/}
				<a style={{ marginLeft: 10 }}>
					{isScreenFull ?
						<FullscreenExitOutlined style={{ color: '#fff', fontSize: 20 }}
												onClick={() => gotoIcon('全屏')}/> :
						<FullscreenOutlined style={{ color: '#fff', fontSize: 20 }} onClick={() => gotoIcon('全屏')}/>
					}
				</a>
			</div>
			<Modal
				title={hiddenPwd ? '设置密码' : '修改密码'}
				visible={visible}
				onOk={updatePwd}
				onCancel={cancelChangePassword}
			>
				<ProxyForm form={form}>
					{
						hiddenPwd && <Row justify={'center'}><Col>尚未设置密码，请输入新密码</Col></Row>
					}
					<FormItem label={'原密码'} hidden={hiddenPwd} name={'password'} rules={[{ required: !hiddenPwd }]}>
						<Input type={'password'} placeholder={'请输入原密码'}/>
					</FormItem>
					<FormItem label={'新密码'} name={'pwd'} rules={[{ required: true }, {
						required: true,
						message: "密码应由8-16位数字、大小写字母、符号组成",
						pattern:
							/^(?=.*?[a-z])(?=.*?\d)(?=.*?[!#@*&.])[a-zA-Z\d!#@*&.]{8,16}$/,
					},]}>
						<Input type={'password'} placeholder={'请输入新密码'}/>
					</FormItem>
					<FormItem label={'请再次输入新密码'} name={'pwds'} rules={[{ required: true }, {
						required: true,
						message: "密码应由8-16位数字、大小写字母、符号组成",
						pattern:
							/^(?=.*?[a-z])(?=.*?\d)(?=.*?[!#@*&.])[a-zA-Z\d!#@*&.]{8,16}$/,
					}]}>
						<Input type={'password'} placeholder={'请再次输入新密码'}/>
					</FormItem>
				</ProxyForm>

				{/*<div className="container">*/}
				{/*    <div className="row updatePwd">*/}
				{/*        <div className="col-xs-4 qiyeguanli-name-num">*/}
				{/*            <label htmlFor="exampleInputAccount1">原密码</label>*/}
				{/*        </div>*/}
				{/*        <div className="col-xs-6 qiyeguanli-name-num-input">*/}
				{/*            <Input type="password" onInput={passwordInput} placeholder=""/>*/}
				{/*        </div>*/}
				{/*    </div>*/}
				{/*    <div className="row updatePwd">*/}
				{/*        <div className="col-xs-4 qiyeguanli-name-num">*/}
				{/*            <label htmlFor="exampleInputAccount1">密码</label>*/}
				{/*        </div>*/}
				{/*        <div className="col-xs-6 qiyeguanli-name-num-input">*/}
				{/*            <Input type="password" onInput={pwdInput} placeholder=""/>*/}
				{/*        </div>*/}
				{/*    </div>*/}

				{/*    <div className="row updatePwd">*/}
				{/*        <div className="col-xs-4 qiyeguanli-name-num">*/}
				{/*            <label htmlFor="exampleInputAccount1">请重复密码</label>*/}
				{/*        </div>*/}
				{/*        <div className="col-xs-6 qiyeguanli-name-num-input">*/}
				{/*            <Input type="password" onInput={pwdsInput} placeholder=""/>*/}
				{/*        </div>*/}
				{/*    </div>*/}
				{/*</div>*/}
			</Modal>

			<Modal
				visible={noteVisible}
				title="版本发布"
				footer={null}
				width={1100}
				onCancel={() => setNoteVisible(false)}
			>
				<Row>
					<Col span={4} style={{ borderRight: '10px solid #f0f2f5' }}>
						{/*<div style={{padding: '20px 10px 20px 10px', fontSize: 15, color: 'rgba(0, 0, 0, 0.65)', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{this.state.projectName}</div>*/}
						<Menu onClick={handleClick1} style={{ width: '100%' }} defaultSelectedKeys={defaultKeys}>
							{releaseNoteData.map((item: any) => {
								return <Menu.Item key={item.version}>{item.version}</Menu.Item>;
							})}
						</Menu>
					</Col>
					<Col span={20} style={{ padding: 10, whiteSpace: 'pre-line' }}>
						{note}
					</Col>
				</Row>
			</Modal>
		</Header>
	);
};

export default connect(({ common, login }: ConnectState) => ({
	common: common,
	login: login
}))(AppHeader);
