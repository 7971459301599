import React, {useEffect, useRef, useState} from 'react';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { Button, Form, Input, message, Modal, Select, TreeSelect, Switch, InputNumber } from 'antd';
import { FormInstance } from 'antd/es/form';
import { ActionType } from '@ant-design/pro-table';
import {
	addAppModel1,
	deleteAppModel1,
	getRongQiList, getVersionController,
	updateAppModel1,
} from '../../../services/FoundationSetupService';
import {getWuyeProj} from "../../../services/WuyeService";
import CustomUploadImage from "../../../components/CustomUploadImage";
import { PlusOutlined } from "@ant-design/icons";
import API from "../../../data/API";
import appConfig from "../../../appConfig";

const RongQiSet: React.FC = () => {
	useEffect(()=>{
		getAllProject()
	},[])
	const [projects,setProjects] = useState([])
	const actionRef = useRef<ActionType>();
	const [visible, setVisble] = useState(false);
	const [add, setAdd] = useState(false);
	const [form] = Form.useForm<FormInstance>();
	const [appName, setAppName] = useState(undefined);
	const [appXcxProjects,setAppXcxProjects]  = useState([])
	const [flag,setFlag] = useState(false)
	let [fileList, setFileList] = useState<any[]>([]);

	const Option = Select.Option;
	const FormItem = Form.Item;


	const uploadButton = (
		<div>
			<PlusOutlined/>
			<div style={{ marginTop: 8 }}>上传</div>
		</div>
	);

	const render = () => (fileList.length >= 1 ? null : uploadButton);
	const getAllProject = async ()=>{
		const res = await getVersionController({})
		if(res.err === 0){
			setProjects(res.data)
			setFlag(true)
			actionRef.current.reload()
			getProjInfo(res.data)
		}
	}
	const getProjInfo = async (projects:any) => {
		const res = await getWuyeProj({});
		if (res.err === 0) {
			let datas: any = new Set();
			const appXcxs = res.data.appXcx;
			if (appXcxs !== undefined && appXcxs !== null && appXcxs !== '') {
				const arr1 = appXcxs.split(',');
				arr1.map((item: any) => {
					projects.map((item1: any) => {
						if (item === item1.typeValue) {
							datas.add(item1);
						}
					});
				});
				setAppXcxProjects([...datas]);

			}
		}
	};
	const columns = [
		{
			title: '编号',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: '容器名称',
			dataIndex: 'modulName',
			key: 'modulName',
			render: (text: string, record: any) => (
				<>
					<a
						onClick={() => {
							openUpdateModal(record);
						}}
					>
						{text}
					</a>
				</>
			),
		},
		{
			title: '项目名称',
			dataIndex: 'appName',
			key: 'apName',
			render:(text:string,item:any)=>{
				return <span>{filterAppName(item.appName)}</span>
			}
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<>
					<a
						onClick={() => {
							deleteItem(record);
						}}
					>
						删除
					</a>
				</>
			),
		},
	];
	const filterAppName = (value:any)=>{
		if(value!==null&&value!==''&&value!==undefined){
			const items = projects.filter((item:any)=>{
				return item.typeValue == value
			})

			const item = items[0]
			return item.name || '-'
		}else{
			return "-"
		}
	}
	const deleteItem = (item: any) => {
		Modal.confirm({
			title: '你确定要删除' + item.modulName + '吗？',
			okType: 'danger',
			onOk: async () => {
				const data: any = {
					id: item.id,
					wuyeUuid: localStorage.getItem('wyUuid'),
				};
				const res = await deleteAppModel1(data);
				if (res.err === 0) {
					message.success('删除成功');
					actionRef.current.reload();
				} else {
					message.error('删除失败');
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const getList = async () => {
		if(flag){
			let params = {
				appName: appName,
			};
			let rsp = await getRongQiList(params);
			if (rsp.err === 0) {
				return {
					data: rsp.data,
				};
			} else {
				return {
					data: [],
					total: 0,
				};
			}
		}{
			return {
				data: [],
				total: 0,
			};
		}

	};
	const saveItem = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData = {
				modulName: data.modulName,
				parentId: -100,
				appName: data.appName,
				widget: 'RongQi',
				pageSize: data.pageSize
			};
			const res = await addAppModel1(formData);
			if (res.err === 0) {
				message.success('添加成功');
				setVisble(false);
				actionRef.current.reload();
			} else {
				message.error('添加失败');
			}
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData = {
				...data,
			};
			const res = await updateAppModel1(formData);
			if (res.err === 0) {
				message.success('修改成功');
				setVisble(false);
				actionRef.current.reload();
			} else {
				message.error('修改失败');
			}
		});
	};
	const tableSearchBarRender = () => [
		<Select
			placeholder="项目"
			style={{ width: 200 }}
			defaultValue={appName}
			onChange={(value: any) => {
				setAppName(value);
				actionRef.current.reload();
			}}
		>
			{appXcxProjects.length > 0
				? appXcxProjects.map((item: any) => {
						return <Option value={item.typeValue}>{item.name}</Option>;
				  })
				: ''}
		</Select>,
	];
	const tableOperationsBarRender = () => [
		<Button type={'primary'} onClick={openAddModal}>
			{' '}
			添加
		</Button>,
	];
	const openAddModal = () => {
		form.resetFields();
		setAdd(true);
		setVisble(true);
	};
	const openUpdateModal = (item: any) => {
		console.log("item:::",item)
		form.setFieldsValue(item);
		setAdd(false);
		setVisble(true);
		if (item.icon) {
			let list = [
				{
					uid: "1",
					url: item.icon || '',
					name: "logo.png",
					status: "done"
				}
			];
			setFileList(list);
		} else {
			setFileList([])
		}

	};

	const getFileName = (fileList: any) => {
		console.log("fileList", fileList);
		if (fileList.file.status === "done") {
			setFileList(fileList.fileList);
			let data: any = {
				icon: fileList.file.response.data[0]
			}
			form.setFieldsValue(data)
		} else if (fileList.file.status === "error") {
			message.error(`${fileList.file.name} 上传文件失败`);
		} else if (fileList.file.status === "removed") {
			setFileList([]);
			let data: any = {
				icon: ""
			}
			form.setFieldsValue(data)
		}
	};
	return (
		<>
			<GSTable
				pagination={false}
				columns={columns}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				actionRef={actionRef}
				request={(params: any) => {
					return getList();
				}}
			/>
			<Modal
				title={add ? '添加' : '修改'}
				visible={visible}
				onOk={saveItem}
				onCancel={() => {
					setVisble(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem hidden={true} name={'id'}>
						<Input />
					</FormItem>
					<FormItem hidden={true} name={'parentId'}>
						<Input />
					</FormItem>
					<FormItem hidden={true} name={'widget'}>
						<Input />
					</FormItem>
					<FormItem name={'modulName'} label={'名称'} rules={[{ required: true }]}>
						<Input placeholder="请输入名称" />
					</FormItem>
					<FormItem name={'appName'} label={'项目'} rules={[{ required: true }]}>
						<Select placeholder="请选择项目">
							{appXcxProjects.length > 0
								? appXcxProjects.map((item: any) => {
										return <Option value={item.typeValue}>{item.name}</Option>;
								  })
								: ''}
						</Select>
					</FormItem>
					<FormItem name={'pageSize'} label={'超过多少显示更多'} rules={[{ required: true }]}>
						<InputNumber />
					</FormItem>
					<FormItem name={'icon'} label={'更多图标'}   rules={[{ required: false }]}>
						<CustomUploadImage cdn={'public'}  maxCount={1}
										   key={new Date().getTime()}
										   multiple={false}
										   listType="picture-card"
										   defaultFileList={fileList.length > 0 ? fileList : null}
										   onChange={getFileName}
										   render={render()}
						/>
					</FormItem>
				</ProxyForm>
			</Modal>
		</>
	);
};
export default RongQiSet;
