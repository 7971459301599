import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import {
	Avatar,
	Button,
	Card,
	Col,
	Form,
	Image,
	Input,
	message,
	Modal,
	Radio,
	Row,
	Space,
	Statistic,
	Tooltip
} from "antd";
import appConfig from '../../../appConfig';
import API from '../../../data/API';
import { NavLink } from 'react-router-dom';
import { getUserData, staffList, updateDeviceStaff } from '../../../services/ZhiNengMenJinService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';
import SendKeyModal from '../components/SendKeyModal';
import { FormInstance } from 'antd/es/form';
let msg = require('../../../util/SuspensionMsg');
const StaffList: React.FC = () => {
	const tooltipText = (
		<div>
			<div>普通用户：只能开锁；</div>
			<div>管理和查看：可以发送，删除钥匙，可以查看使用记录；</div>
			<div>管理：可以发送，删除钥匙；</div>
			<div>使用和查看：可以使用、查看使用记录；</div>
			<div>超级管理员：可以开锁，发送删除钥匙，查看使用记录</div>
		</div>
	);
	const Search = Input.Search;
	const actionRef = useRef<ActionType>();
	const [sendDeviceVisible, setSendDeviceVisible] = useState(false);
	const [search, setSearch] = useState('');
	const [email, setmail] = useState('');
	const [visible, setVisible] = useState(false);
	const [deviceUseData, setDeviceUseData] = useState({
		userNum: 0,
		faceImgNum: 0,
	});
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const columns = [
		{
			title: '头像',
			width: 100,
			dataIndex: 'profileImg',
			render: (text: any, item: any) => <Avatar size={64} src={appConfig.cdnRootPath + item.profileImg} />,
		},
		{
			title: '用户昵称',
			dataIndex: 'nickname',
			width: 100,
		},
		{
			title: '账号',
			dataIndex: 'email',
			width: 100,
			render: (text: any, item: any) => (
				<a
					onClick={() => {
						update(item);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: '身份证号',
			width: 150,
			dataIndex: 'cardIdNum',
		},
		{
			title: '性别',
			width: 100,
			dataIndex: 'sex',
		},
		{
			title: '人脸门禁照片',
			width: 100,
			dataIndex: 'facePhoto',
			render: (text: any, item: any) =>
				item.userImg === null || item.userImg === '' ? (
					item.facePhoto === null || item.facePhoto === '' ? (
						'无'
					) : (
						<Image
							width={100}
							height={100}
							src={
								API.apiRootPath +
								'/api/file/get_file_qiniu/' +
								item.facePhoto +
								'/' +
								appConfig.wuYeUuid
							}
						/>
					)
				) : (
					<Image width={100} height={100} src={item.userImg.split(',')[0]} />
				),
		},
		{
			title: '身份证照片',
			width: 100,
			dataIndex: 'cardIdImg',
			render: (text: any, item: any) =>
				item.cardIdImg === null || item.cardIdImg === undefined || item.cardIdImg === '' ? (
					'无'
				) : (
					<Image width={100} height={100} src={item.cardIdImg.toLowerCase()} />
				),
		},
		{
			title: '手机型号',
			width: 200,
			dataIndex: 'phone_info',
		},
		{
			title: '最近登陆时间',
			width: 200,
			dataIndex: 'lastLoginTime',
		},
		{
			title: '操作',
			width: 150,
			fixed: 'right',
			dataIndex: 'type',
			render: (text: any, item: any) => (
				<Space>
					<NavLink to={'/zhineng-menjin/single-lock-user-list?id=' + item.email}>钥匙管理</NavLink>
					<a onClick={() => sendKeyModal(item)}>发送钥匙</a>
				</Space>
			),
		},
	];
	useEffect(() => {
		stafflistData().then()
	}, [])
	const stafflistData = async () => {
		let rsp = await getUserData({})
		if (rsp.err === 0) {
			setDeviceUseData(rsp.data);

		}
	}
	const sendKeyModal = (record: any) => {
		console.log('record', record);
		setmail(record.email);
		setSendDeviceVisible(true);
	};

	const update = (item: any) => {
		const FormData: any = {
			uuid: item.uuid,
			cardIdNum: item.cardIdNum,
			sex: item.sex,
			nickname: item.nickname,
		};
		form.setFieldsValue(FormData);
		setVisible(true);
	};

	const tableSearchBarRender = () => [
		<Input
			placeholder={'账号、姓名'}
			onChange={(value) => {
				setSearch(value.target.value);
			}}
		/>,
		<Button type={"primary"} onClick={() => {
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		}}>查询</Button>
	];

	const renderStatisticTitle = (element: any, msg: string, iconStyle?: any) => {
		return (
			<div style={{ display: 'flex' }}>
				<div style={{ flex: 1 }}>{element}</div>
				{
					msg && <div>
						<Tooltip title={msg}>
							<ExclamationCircleOutlined style={iconStyle} />
						</Tooltip>
					</div>
				}

			</div>
		);
	};
	const tableExtraRender = () => {
		return (
			<Row gutter={16}>
				<Col span={4}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>人员总数</span>,
								''
							)}
							value={deviceUseData.userNum}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>人</span>}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>人脸门禁照片总数</span>,
								''
							)}
							value={deviceUseData.faceImgNum}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>张</span>}
						/>
					</Card>
				</Col>
			</Row>
		);
	};

	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			if (data.cardIdNum !== null && data.cardIdNum !== undefined && data.cardIdNum !== '') {
				if (!(data.cardIdNum.length == 18)) {
					message.warning('身份证号格式不正确');
					return;
				}
			}
			const res = await updateDeviceStaff(data);
			if (res.err === 0) {
				message.success('修改成功');
				setVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error('修改失败');
			}
			console.log('data::', data);
		});
	};
	return (
		<div>
			<GSTable
				columns={columns}
				rowKey={'uuid'}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				tableExtraRender={tableExtraRender}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await staffList({
						realEmail: search,
						page: params.current,
						size: params.pageSize,
					});
					if (res.err !== 0) {
						return {
							data: [],
							total: 0,
						};
					}
					console.log('res::;,', res);
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<SendKeyModal
				visible={sendDeviceVisible}
				onCancel={() => {
					setSendDeviceVisible(false);
				}}
				userId={email}
			/>

			<Modal
				visible={visible}
				onOk={updateItem}
				onCancel={() => {
					setVisible(false);
				}}
				title={'修改'}
			>
				<ProxyForm form={form}>
					<FormItem label={'uuid'} name={'uuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'用户昵称'} name={'nickname'}>
						<Input disabled={true} placeholder={'请输入用户昵称'} />
					</FormItem>
					<FormItem label={'身份证号'} name={'cardIdNum'}>
						<Input placeholder={'请输入身份证号'} />
					</FormItem>
					<FormItem label={'性别'} name={'sex'}>
						<Radio.Group>
							<Radio value={'男'}>男</Radio>
							<Radio value={'女'}>女</Radio>
						</Radio.Group>
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default StaffList;
