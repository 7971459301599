import React, { useEffect, useState } from 'react';
import { addMeetingImg, deleteMeetingImg, meetingFindByUuid, setFirstImg } from '../../../services/meetingService';
import { Button, Card, Col, Image, message, Modal, Row, Upload } from 'antd';
import Api from '../../../data/API';
import FengMianImg from "../../../img/333.png"
import { PlusOutlined, UploadOutlined } from '@ant-design/icons/lib';
const MeetingImgs: React.FC = () => {
	useEffect(() => {
		getdata();
	}, []);
	const [imgs, setImgs] = useState(['loading']);
	const [visible, setVisible] = useState(false);
	const [photo, setPhoto] = useState('');

	const getdata = async () => {
		const res = await meetingFindByUuid({
			uuid: getUuid(),
		});
		console.log('meeting', res);
		if (res.err === 0) {
			setImgs(res.imgs);
		} else {
			message.error(res.msg);
		}
	};
	const getUuid = () => {
		const page = window.location.search;
		const c = page.split('=');
		const uuid = c[1];
		return uuid;
	};
	const setFirst = async (id: any) => {
		const res = await setFirstImg({
			uuid: getUuid(),
			fileName: id,
		});
		console.log('meeting', res);
		if (res.err === 0) {
			getdata();
			message.success('切换成功');
		} else {
			message.error(res.ms);
		}
	};

	const deleteImg = (id: any) => {
		Modal.confirm({
			title: '您确认要删除该图片吗?',
			onOk: async () => {
				const res = await deleteMeetingImg({
					uuid: getUuid(),
					img: id,
				});
				if (res.err === 0) {
					getdata();
					message.success(res.msg);
				} else {
					message.error(res.msg);
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const imgInfos = () => {
		setPhoto('');
		setVisible(true);
	};

	const addInfoImage = async () => {
		if (photo === '' || photo === undefined) {
			message.warning('请上传图片');
			return;
		}
		const res = await addMeetingImg({
			uuid: getUuid(),
			img: photo,
		});
		if (res.err === 0) {
			getdata();
			message.success(res.msg);
			setVisible(false);
			setPhoto('');
		} else {
			message.error(res.msg);
		}
	};

	const getMeetFileName = (fileList: any) => {
		console.log('fileList', fileList);
		if (fileList.file.status === 'done') {
			setPhoto(fileList.file.response.url);
			console.log(`${fileList.file.name}`);
			console.log('会议', fileList.file.response.url);
		} else if (fileList.file.status === 'error') {
			message.error('上传图片失败');
		}
	};
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>上传</div>
		</div>
	);
	return (
		<div>
			<div className="pull-right" style={{ marginRight: 10, float: 'right' }}>
				<Button type="primary" style={{ marginRight: 10 }} onClick={imgInfos}>
					添加详情图片
				</Button>
			</div>
			<Row>
				{imgs.map((i: any, index: any) => (
					<Col>
						<Card
							hoverable
							style={{ marginRight: 20, width: 200, height: 300, marginTop: 30 }}
							cover={
								i.first === 1 ? (
									<div>
										<Image
											className="meeting-img-one"
											width="200px"
											height="260px"
											alt="会议图片"
											src={Api.cdnRootPath + i.img}
											data-toggle="modal"
											data-target="#big_img"
										/>
										<div style={{ position: 'absolute', top: 0, left: 0 }}>
											<Image
												className="cover-img"
												width="40px"
												height="40px"
												style={{ position: 'absolute', top: 0, left: 0 }}
												alt="会议图片"
												src={FengMianImg}
											/>
										</div>
									</div>
								) : (
									<Image
										className="meeting-img-nocover"
										width="200px"
										height="260px"
										alt="会议图片"
										src={Api.cdnRootPath + i.img}
										data-toggle="modal"
										data-target="#big_img"
									/>
								)
							}
							bodyStyle={{ padding: 0, margin: 0 }}
							actions={[
								<a
									onClick={() => {
										setFirst(i.img);
									}}
								>
									设为封面
								</a>,
								<a
									onClick={() => {
										deleteImg(i.img);
									}}
								>
									删除图片
								</a>,
							]}
						></Card>
					</Col>
				))}
			</Row>
			<Modal
				visible={visible}
				title={'添加详情图片'}
				onCancel={() => {
					setVisible(false);
				}}
				onOk={addInfoImage}
			>
				<Row align={'middle'}>
					<Col span={6} style={{ textAlign: 'right' }}>
						添加详情图片：
					</Col>
					<Col>
						<Upload
							name="image"
							listType="picture-card"
							className="avatar-uploader"
							showUploadList={false}
							data={{ wuye_uuid: localStorage.getItem('wyUuid') }}
							action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}
							onChange={getMeetFileName}
							accept={'.jpg,.png'}
						>
							{photo !== null && photo !== undefined && photo !== '' ? (
								<img alt="avatar" style={{ width: '100%' }} src={Api.cdnRootPath + photo} />
							) : (
								uploadButton
							)}
						</Upload>
					</Col>
				</Row>
			</Modal>
		</div>
	);
};
export default MeetingImgs;
