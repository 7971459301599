import * as React from 'react';
import appConfig from '../appConfig';
import API from '../data/API';
let E = require('wangeditor');
/**
 * wangEditor
 */
interface WangEditorProps {
	id: string;
	value1: any;
	changeCallback: any;
}

class WangEditor extends React.Component<WangEditorProps> {
	constructor(props: any) {
		super(props);
		this.state = {
			editorContent: '',
		};
	}

	componentDidMount() {
		var editor = new E('#' + (this.props.id || 'editorElem'));
		//editor.customConfig.uploadImgShowBase64 = true;
		editor.config.uploadImgServer = appConfig.apiRootPath + '/api/picture/upload_qiniu_two';
		editor.config.uploadImgParams = {
			wuye_uuid: API.getWuyeUuid(),
		};
		editor.config.onchange = (html: any) => {
			this.setState({
				editorContent: html,
			});
			this.props.changeCallback(html);
		};
		editor.create();
		console.log('value', this.props.value1);
		if (this.props.value1 !== null && this.props.value1 !== undefined && this.props.value1 !== '') {
			editor.txt.html(this.props.value1);
		}
	}

	render() {
		return (
			<div>
				<div className={'w-e-menu'} id={this.props.id || 'editorElem'} />
			</div>
		);
	}
}

export default WangEditor;
