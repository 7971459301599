
import React from 'react';
import MiniApp, {MiniAppMenu, MiniAppRoute} from "../../util/MiniApp";
import InvestmentProjectsList from "./pages/InvestmentProjectsList";
import WorkTaskList from "./pages/WorkTaskList";
import ApplicationList from "./pages/ApplicationList";
const index: React.FC = () => {
  const rootUrl = '/work-task';
  const menus: MiniAppMenu[] = [
    {
      name: '工作任务列表',
      path: 'list',
      authority: 'work-task:list',
      component: WorkTaskList,
    }, {
      name: '工作申请列表',
      path: 'application-list',
      authority: 'work-task:application:list',
      component: ApplicationList,
    },
  ];
  const routes: MiniAppRoute[] = [
  ];
  return <MiniApp miniAppName={'工作任务管理'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
export default index;
