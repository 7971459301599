import React, { useCallback, useEffect, useState } from 'react';
import { Col, Rate, Row, Space, Input } from 'antd';
import { CaretDownOutlined, DeleteOutlined, SearchOutlined, StarFilled, StarOutlined } from '@ant-design/icons/lib';
import { connect } from 'dva';
import ConnectState from '../models/connect';
import { NavLink } from 'react-router-dom';
import { Icon } from '@ant-design/compatible';
import Star from './Star';
import { Scrollbars } from 'react-custom-scrollbars';
import DAL from '../data/DAL';
import Api from '../data/API';
import InputDragable from './InputDragable';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import HTMLBackend from 'react-dnd-html5-backend';

const AppMenu: React.FC = (props: any) => {
	const { login } = props;
	const [menu, setMenu] = useState(login.currentUser.menu);
	const [findMenu, setFindMenu] = useState(login.currentUser.menu);
	const [showMenu, setShowMenu] = useState(false);
	const [collection, setCollection] = useState<any>([]);
	let time: any;
	useEffect(() => {
		let collection = (DAL.dal.getCookie(Api.userId + 'collectionMenu') || '').split(',');
		setCollection(collection);
	}, []);
	const moveCard = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			let dragCard: any;
			dragCard = collection[dragIndex];
			let arr = update(collection, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragCard]
				]
			});
			setCollection([...arr]);
			DAL.dal.setCookie(
				Api.userId + 'collectionMenu',
				arr.toString()
			);
			if (props.onChangeItem) {
				props.onChangeItem();
			}
		},

		[collection]
	);
	return (
		<div
			onMouseEnter={(event: any) => {
				clearTimeout(time);
				setShowMenu(true);
				console.log('进入');
			}}
			onMouseLeave={(event: any) => {
				time = setTimeout(() => {
					setShowMenu(false);
				}, 500);
				console.log('离开');

			}}
		>
			<Space>
				<a style={{ color: '#fff', fontSize: 14 }}>所有应用</a>
				<CaretDownOutlined className={'clickItem'}/>
			</Space>
			<div
				style={{
					maxWidth: 964,
					width: '70%',
					height: '73%',
					maxHeight: 691,
					backgroundColor: '#E7F4FF',
					opacity: 1,
					zIndex: 1000,
					position: 'fixed',
					left: window.location.pathname !== '/home' ? (props.toggleSidebar ? 80 : 140) : 0,
					display: showMenu ? 'block' : 'none',
					lineHeight: 'normal'
				}}
			>
				<Row gutter={0} style={{ marginTop: 0, height: '100%' }}>
					<Col
						style={{
							height: '100%',
							maxHeight: 691,
							backgroundColor: 'rgba(24, 144, 255, 0.1)',
							paddingTop: 30,
							paddingBottom: 30
						}}
						span={6}
					>
						<div style={{ marginLeft: 30, fontSize: 16, color: '#000', height: 20, lineHeight: '20px' }}>
							快捷访问
						</div>
						<Scrollbars
							style={{
								maxHeight: 671,
								height: '100%'
							}}
						>
							<DndProvider backend={HTMLBackend}>
								{collection.map((collectionMenu: any, index) => {
									if (DAL.dal.isNotNull(collectionMenu)) {
										return menu.map((menuItem: any) => {
											if (menuItem.children) {
												return menuItem.children.map((children: any) => {
													if (children.path == collectionMenu) {
														return (
															<div
																style={{
																	marginTop: 10,
																	marginLeft: 'auto',
																	marginRight: 'auto',
																	padding: 4,
																	backgroundColor: 'rgba(24, 144, 255, 0.2)',
																	lineHeight: '28px',
																	// width: '70%',
																	width: 140,
																	height: 28,
																	display: 'flex',
																	justifyContent: 'space-between',
																	alignItems: 'center',
																	fontSize: 13,
																	color: 'rgba(0, 0, 0, 1)'
																}}
															>

																<InputDragable
																	accept={'selectItem'}
																	key={index}
																	index={index}
																	type={'menu'}
																	moveCard={moveCard}
																>
																	{
																		(children.path.includes('https://') || children.path.includes("http://")) ? <a
																			className={'menu-a'}
																			onClick={() => {
																				if (props.onChange) {
																					props.onChange(children.key);
																					setShowMenu(false)
																				}
																			}}
																			href={children.path}
																			target={'_black'}
																			style={{
																				color: 'rgba(24, 38, 60, 1)',
																				flex: 1,
																				display: 'flex',
																				justifyContent: 'center'
																			}}
																		>
																			{children.name}
																		</a> :<NavLink
																			className={'menu-a'}
																			onClick={() => {
																				if (props.onChange) {
																					props.onChange(children.key);
																					setShowMenu(false)
																				}
																			}}
																			to={children.path}
																			style={{
																				color: 'rgba(24, 38, 60, 1)',
																				flex: 1,
																				display: 'flex',
																				justifyContent: 'center'
																			}}
																		>
																			{children.name}
																		</NavLink>
																	}

																	<DeleteOutlined
																		onClick={() => {
																			let index = -1;
																			let collectionItem = (
																				DAL.dal.getCookie(
																					Api.userId + 'collectionMenu'
																				) || ''
																			).split(',');
																			collectionItem.map(
																				(
																					collectionItem: string,
																					collectionIndex: number
																				) => {
																					if (
																						collectionItem === children.path
																					) {
																						index = collectionIndex;
																					}
																				}
																			);
																			if (index == -1) {
																				return;
																			}
																			collectionItem.splice(index, 1);
																			DAL.dal.setCookie(
																				Api.userId + 'collectionMenu',
																				collectionItem.toString()
																			);
																			setCollection(collectionItem);
																			if (props.onChangeItem) {
																				props.onChangeItem();
																			}
																		}}
																		style={{ justifySelf: 'end' }}
																	/>
																</InputDragable>
															</div>
														);
													}
													return;
												});
											}
										});
									}
								})}
							</DndProvider>
							{/* {menu.map((item: any) => {
								if (item.children) {
									return item.children.map((children: any) => {
										if (collection.indexOf(children.path) !== -1) {
											return (
												<div
													style={{
														marginTop: 10,
														marginLeft: 'auto',
														marginRight: 'auto',
														padding: 4,
														backgroundColor: 'rgba(24, 144, 255, 0.2)',
														lineHeight: '13px',
														width: '70%',
														maxWidth: 140,
														height: 28,
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														fontSize: 13,
														color: 'rgba(0, 0, 0, 1)',
													}}
												>
													<NavLink
														className={'menu-a'}
														onClick={() => {
															if (props.onChange) {
																props.onChange(children.key);
															}
														}}
														to={children.path}
														style={{
															color: 'rgba(24, 38, 60, 1)',
															flex: 1,
															display: 'flex',
															justifyContent: 'center',
														}}
													>
														{children.name}
													</NavLink>
													<DeleteOutlined
														onClick={() => {
															let index = -1;
															let collectionItem = (
																DAL.dal.getCookie(Api.userId + 'collectionMenu') || ''
															).split(',');
															collectionItem.map(
																(collectionItem: string, collectionIndex: number) => {
																	if (collectionItem === children.path) {
																		index = collectionIndex;
																	}
																}
															);
															if (index == -1) {
																return;
															}
															collectionItem.splice(index, 1);
															DAL.dal.setCookie(
																Api.userId + 'collectionMenu',
																collectionItem.toString()
															);
															setCollection(collectionItem);
															if (props.onChangeItem) {
																props.onChangeItem();
															}
														}}
														style={{ justifySelf: 'end' }}
													/>
												</div>
											);
										}
										return;
									});
								}
							})} */}
						</Scrollbars>
					</Col>
					<Col style={{ paddingRight: 10, paddingLeft: 30 }} span={18}>
						<Scrollbars style={{ height: '100%', maxHeight: 691 }}>
							<Row justify={'end'}>
								<Col xs={16} sm={16} md={12} lg={8}>
									<Input
										addonAfter={<SearchOutlined/>}
										onChange={(event: any) => {
											clearTimeout(time)
											let value = event.target.value;
											setShowMenu(true);
											if (!value) {
												setFindMenu(menu);
												return;
											}
											let arr = menu.filter((item: any) => {
												if (item.name.includes(value)) {
													return true;
												}
												if (item.children) {
													let flag = false;
													item.children.map((children: any) => {
														if (children.name.includes(value)) {
															flag = true;
														}
													});
													return flag;
												}
												return false;
											});
											setFindMenu(arr);
										}}
									/>
								</Col>
							</Row>
							<Row>
								{findMenu.map((item: any) => {
									if (item.name === '主页') {
										return;
									}
									return (
										<Col xs={24} sm={12} md={8} lg={6}>
											<div
												style={{
													color: 'rgba(0, 0, 0, 0.85)',
													fontSize: 16,
													fontWeight: 'bold',
													lineHeight: '16px',
													marginTop: 30
												}}
											>
												<Space>
													{item.icon ? (
														<Icon
															type={item.icon}
															style={{
																color: '#000'
															}}
														/>
													) : (
														<div style={{ width: 18, height: 18 }}/>
													)}
													<div>{item.name}</div>
												</Space>
											</div>
											{item.children && (
												<>
													{item.children.map((children: any) => {
														return (
															<Row style={{ marginLeft: 2 }}>
																<Col span={24}>
																	<div
																		style={{
																			color: 'rgba(24, 38, 60, 1)',
																			fontSize: 13,
																			lineHeight: '13px'
																		}}
																	>
																		<Space>
																			<Star
																				key={collection}
																				check={
																					collection.indexOf(
																						children.path
																					) !== -1
																				}
																				onChange={(check) => {
																					if (check) {
																						let collection = (
																							DAL.dal.getCookie(
																								Api.userId +
																								'collectionMenu'
																							) || ''
																						).split(',');
																						collection.push(children.path);
																						DAL.dal.setCookie(
																							Api.userId +
																							'collectionMenu',
																							collection.toString()
																						);
																						setCollection(collection);
																						if (props.onChangeItem) {
																							props.onChangeItem();
																						}
																					} else {
																						let index = -1;
																						let collection = (
																							DAL.dal.getCookie(
																								Api.userId +
																								'collectionMenu'
																							) || ''
																						).split(',');
																						collection.map(
																							(
																								collectionItem: string,
																								collectionIndex: number
																							) => {
																								if (
																									collectionItem ===
																									children.path
																								) {
																									index = collectionIndex;
																								}
																							}
																						);
																						if (index == -1) {
																							return;
																						}
																						collection.splice(index, 1);
																						DAL.dal.setCookie(
																							Api.userId +
																							'collectionMenu',
																							collection.toString()
																						);
																						setCollection(collection);
																						if (props.onChangeItem) {
																							props.onChangeItem();
																						}
																					}
																				}}
																			/>
																			{
																				(children.path.includes('http://') || children.path.includes('https://')) ?
																					<a onClick={() => {
																						setShowMenu(false)
																					}} className={'menu-a'}
																					   style={{
																						   color: 'rgba(24, 38, 60, 1)',
																						   fontSize: 13,
																						   lineHeight: '13px'
																					   }}
																					   target={"_black"}
																					   href={children.path}>
																						{children.name}
																					</a> : <NavLink
																						style={{
																							color: 'rgba(24, 38, 60, 1)',
																							fontSize: 13,
																							lineHeight: '13px'
																						}}
																						to={children.path}
																						onClick={() => {
																							if (props.onChange) {
																								props.onChange(children.key);
																							}
																							setShowMenu(false)
																						}}
																						className={'menu-a'}
																					>
																						{children.name}
																					</NavLink>
																			}

																		</Space>
																	</div>
																</Col>
															</Row>
														);
													})}
												</>
											)}
										</Col>
									);
								})}
							</Row>
						</Scrollbars>
					</Col>
				</Row>
			</div>
		</div>
	);
};
export default connect(({ login }: ConnectState) => ({
	login: login
}))(AppMenu);
