import * as React from 'react';
import Api from '../../data/API';
import DAL from '../../data/DAL';
import md5 from '../../util/md5';
import { Redirect } from 'react-router-dom';
import {Button, Col, Input, message, Checkbox, Row, Spin, Tabs} from 'antd';
import {
	getAdminToken,
	getLoginCode,
	judgeAccount,
	loginWithSms,
	saasRegister,
	saasRetrieve,
	saasUserRegister,
	tokenSignIn,
} from '../../services/login';
import { connect } from 'dva';
import LoginImg from './2.png';
import logoCk from '../../img/logo-ck.png'
import ConnectState from '../../models/connect';
import appConfig from "../../appConfig";

const { TabPane } = Tabs;
var msg = require('../../util/SuspensionMsg');
require('./login.css');

let userId: any;
let password: any;
let captche: any;
let phoneCode: any;
let maxTime: number = 60;
const key = 'login';

class Login extends React.Component {
	dal = DAL.dal;
	timer: any = null;
	constructor(props: any) {
		super(props);
		this.state = {
			obtainCaptche: '获取验证码',
			value: '',
			isShow: false,
			height: 33,
			showLoadingIcon: false,
			isLoggedOn: Api.getLoginState(),
			redirectTo: '/qiye-guanli/employee-guanli',
			captcha: Api.cdnRootPath + '/captcha',
			showLogin: false,
			showRegister: true,
			forget: true,
			expression: '',
			validate: '',
			validateInput: '',
			currentTime: 61,
			codeTag: false,
			code: '',
			text: '',
			logoUrl: '',
			bannerTitle: '',
			showAgreement: false,
			agreement: false,
			btnText: '发送验证码',
			btnBool: false,
			loading: false,
			token: '',

			errMsg: {
				username: '',
				password: '',
				captcha: '',
				agreement: '',
			},
		};
		this.login = this.login.bind(this);
		this.captche = this.captche.bind(this);
		this.renderCode = this.renderCode.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.Blue = this.Blue.bind(this);
		this.Register = this.Register.bind(this);
		this.userBlur = this.userBlur.bind(this);
		this.startCountDown = this.startCountDown.bind(this);
		this.SendOut = this.SendOut.bind(this);
		this.forget = this.forget.bind(this);
		this.GoLogin = this.GoLogin.bind(this);
		this.CodeBlur = this.CodeBlur.bind(this);
		this.GoRegister = this.GoRegister.bind(this);
		this.Retrieve = this.Retrieve.bind(this);
		this.SendPhoneCode = this.SendPhoneCode.bind(this);
		this.loginWithSms = this.loginWithSms.bind(this);
		// this.ReadAgreement = this.ReadAgreement.bind(this);
		//this.callback = this.callback.bind(this);
	}

	// let isFree = (document.getElementsByName('free') as any)[0].checked;
	// if (isFree) {
	//     console.log('免登30天');
	//     localStorage.setItem('free', 'true');
	//     localStorage.setItem('logintime', new Date().getTime().toString());
	// }

	// ReadAgreement() {
	//     let agreement = (document.getElementsByName('agreement') as any)[0];
	//     let ReadAgreement = false;
	//     console.log(agreement)
	//     if (agreement !== undefined) {
	//         ReadAgreement =  agreement.checked;
	//     }
	//     if (ReadAgreement || (this.state as any).agreement) {
	//         this.setCookie('agreement', 'true');
	//     }
	//     console.log(ReadAgreement)
	//     this.setState({
	//         agreement: ReadAgreement
	//     });
	// }

	Register() {
		this.setState({
			showLogin: true,
			showRegister: false,
			forget: true,
		});
	}

	GoLogin() {
		this.setState({
			showLogin: false,
			showRegister: true,
			forget: true,
			validateInput: '',
			errMsg: {},
		});
		userId = '';
		password = '';
	}

	componentDidMount() {
		this.renderCode();
		console.log('window.location.search', window.location);
		//lgymove.tuodong('drag', this.callback);
	}

	// callback(text: any) {
	//     this.setState({
	//         text: text
	//     });
	// }

	captche() {
		let data = new Date();
		this.setState({
			captcha: Api.cdnRootPath + '/captcha?' + data.getTime(),
		});
	}

	tokenSignIn = async (token: string) => {
		const dispatch = (this.props as any).dispatch;
		dispatch({
			type: 'login/tokenLogin',
			payload: {
				access_token: token,
			},
		});
	};

	componentWillMount() {
		// console.log("域名", window.location.hostname);

		console.log('window.location.search', window.location);
		let height = window.innerHeight;
		this.setState({
			height: height,
		});

		let hostUrl = window.location.hostname;
		if (hostUrl !== 'wy.guosim.com') {
			this.setState({
				logoUrl: logoCk,
				companyName: '北京创客小镇科技有限公司',
				bannerTitle: '让创业更简单',
			});
		} else {
			this.setState({
				logoUrl: 'https://pcdn.guosim.com/static/logo-guosim.png',
				companyName: '北京果心科技有限公司',
				bannerTitle: '智慧社区',
			});
		}
		let search = window.location.search;
		let token = search.split('token=')[1];
		if (token !== undefined) {
			this.tokenSignIn(token);
		}
		let readAgreement = this.getCookie('agreement');
		if (readAgreement === 'true') {
			console.log('已经阅读隐私协议');
			this.setState(
				{
					showAgreement: true,
					agreement: true
				},
				() => {
					console.log((this.state as any).agreement);
				}
			);
		} else {
			console.log('没有阅读隐私协议');
		}
	}

	loadingIcon() {
		if ((this.state as any).showLoadingIcon) {
			return <i className="icon icon-spin icon-spinner" />;
		} else {
			return <span />;
		}
	}

	login = async () => {
		this.setState({
			loading: true,
		});

		if (userId === undefined || userId === '') {
			//msg.suspensionMsg('账号不能为空');
			let errMsg = {
				username: '账号不能为空',
				password: '',
				captcha: '',
				agreement: '',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			this.setState({
				loading: false,
			});
			return;
		}
		if (password === undefined || password === '') {
			// msg.suspensionMsg('密码不能为空');
			let errMsg = {
				username: '',
				password: '密码不能为空',
				captcha: '',
				agreement: '',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			this.setState({
				loading: false,
			});
			return;
		}

		let thisInput = (this.state as any).validateInput;
		let validateCode = (this.state as any).expression;
		if (thisInput !== validateCode) {
			let errMsg = {
				username: '',
				password: '',
				captcha: '验证码不正确',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			this.setState({
				loading: false,
			});
			return;
		}

		if (!(this.state as any).agreement) {
			let errMsg = {
				username: '',
				password: '',
				captcha: '',
				agreement: '请勾选隐私协议',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			this.setState({
				loading: false,
			});
			return;
		}

		this.setState({
			isShow: true,
			showLoadingIcon: true,
		});

		const dispatch = (this.props as any).dispatch;
		dispatch({
			type: 'login/login',
			payload: {
				user_id: userId,
				password: password,
			},
		});
		this.setState({
			loading: false,
		});
	};

	xzLogin = async () => {
		console.log("登录",userId)
		this.setState({
			loading: true,
		});
		if (userId === undefined || userId === '') {
			message.warning("账号不能为空")
			this.setState({
				loading: false,
			});
			return;
		}
		if (password === undefined || password === '') {
			// msg.suspensionMsg('密码不能为空');
			message.warning("密码不能为空")
			this.setState({
				loading: false,
			});
			return;
		}
		let thisInput = (this.state as any).validateInput;
		let validateCode = (this.state as any).expression;
		if (thisInput !== validateCode) {
			message.warning("验证码不正确")
			this.setState({
				loading: false,
			});
			return;
		}
		if (phoneCode === undefined || phoneCode === '') {
			// msg.suspensionMsg('密码不能为空');
			message.warning("手机验证码不能为空")
			this.setState({
				loading: false,
			});
			return;
		}
		this.setState({
			isShow: true,
			showLoadingIcon: true,
		});

		const dispatch = (this.props as any).dispatch;
		dispatch({
			type: 'login/login',
			payload: {
				user_id: userId,
				password: password,
				phoneCode:phoneCode,
				projType:'小镇'
			},
		});
		this.setState({
			loading: false,
		});
	};

	render() {
		const { loading } = this.state;
		return (
			<>
				{window.location.href.includes('app.bjckxz.com') || window.location.href.includes('apptest.zgcckxz.com') ? (
					<div>
						{(this.state as any).isLoggedOn ? (
							<Redirect
								to={{
									pathname: '/qiye-guanli/employee-guanli',
								}}
							/>
						) : (
							<div
								className="container-fluid login-bg"
								style={{ height: (this.state as any).height }}
							>

								<Row style={{paddingTop: "12%", width: "100%"}}>
									<Col span={5}></Col>
									<Col span={14}>
										<div  style={{ display:'flex',justifyContent:'space-between' }}>
											<div className="col-md-5 col-md-offset-2">
												<div className="slogan">
													<h1>{(this.state as any).bannerTitle}</h1>
													<h1 style={{ padding: "10px 0 0 90px" }}>让生活更美好</h1>
												</div>
											</div>
											<div className="col-md-3" style={{width:455}}>
												<div className="login-block">
													<div>
														<img
															className="login-div-img"
															src={(this.state as any).logoUrl}
														/>
													</div>
													{/*登录*/}
													<div
														hidden={(this.state as any).showLogin}
													>
														<div className="login-div-count-div">
															<input
																className=" form-control-home "
																name="userId"
																onInput={this.userNumber}
																placeholder="账号"
															/>
														</div>
														<div className="login-div-count-div">
															<input
																className=" form-control-home "
																type="password"
																name="password"
																onInput={this.passwordNumber}
																placeholder="密码"
															/>
														</div>
														<div className="login-div-count-div login-div-count-div-captcha">
															<div className="captcha-input">
																<input
																	className="  form-control-home-captcha-ssm  "
																	value={(this.state as any).validateInput}
																	placeholder="请输入验证码"
																	onBlur={this.Blue}
																	onInput={this.handleChange}
																/>
																<div className="form-control-captcha">
																	<button
																		type="button"
																		className="btn form-control-captcha"
																		onClick={this.renderCode}
																	>
																		{(this.state as any).expression}
																	</button>
																</div>
															</div>
														</div>
														<div className="login-div-count-div login-div-count-div-captcha">
															<div className="captcha-input">
																<input
																	className="  form-control-home-captcha-ssm  "
																	name="phoneCode"
																	onInput={this.phoneCode}
																	placeholder="请输入收到的验证码"
																/>
																<div className="form-control-captcha">
																	<button
																		type="button"
																		disabled={(this.state as any).codeTag}
																		className="btn form-control-captcha"
																		onClick={this.SendOut}
																	>
																		{/*获取验证码*/}
																		{(this.state as any).obtainCaptche}
																	</button>
																</div>
															</div>
														</div>
														<div className="login-div-forget">
															<div className="checkbox login-div-checkbox">
																<Checkbox defaultChecked={localStorage.getItem("free") === 'true'} onChange={(e: any) => {
																	console.log("check:::", e.target.checked)
																	localStorage.setItem("free", e.target.checked)
																}}>30天免登录</Checkbox>
															</div>
															<a onClick={this.forget}>忘记密码？</a>
														</div>

														<div className="login-div-count-div">
															<button className="btn-home" onClick={this.xzLogin}>
																登录
															</button>
														</div>
														<div className="login-div-count-div">
															<button className="btn-home" onClick={this.Register}>
																注册
															</button>
														</div>
														{/* (this.state as any).isShow ? <div className="cover"> <div><i  className="icon icon-spin icon-spinner-indicator"/></div></div> : null */}
													</div>
													{/*注册*/}
													<div
														className="login-div-count"
														hidden={(this.state as any).showRegister}
													>
														<div className="login-div-count-div">
															<input
																className=" form-control-home "
																onBlur={this.userBlur}
																name="userId"
																onInput={this.userNumber}
																placeholder="登录账号/手机号"
															/>
														</div>
														<div className="login-div-count-div login-div-count-div-captcha">
															<div className="captcha-input">
																<input
																	className=" form-control-home-captcha-ssm "
																	value={(this.state as any).validateInput}
																	placeholder="请输入验证码"
																	onBlur={this.Blue}
																	onInput={this.handleChange}
																/>
																<div className="form-control-captcha">
																	<button
																		type="button"
																		className="btn form-control-captcha"
																		onClick={this.renderCode}
																	>
																		{(this.state as any).expression}
																	</button>
																</div>
																<div className="form-control-captcha">
																	<button
																		type="button"
																		disabled={(this.state as any).codeTag}
																		className="btn form-control-captcha"
																		onClick={this.SendOut}
																	>
																		{/*获取验证码*/}
																		{(this.state as any).obtainCaptche}
																	</button>
																</div>
															</div>
														</div>
														<div className="login-div-count-div">
															<input
																className=" form-control-home "
																name="phoneCode"
																onBlur={this.CodeBlur}
																onInput={this.phoneCode}
																placeholder="请输入收到的验证码"
															/>
														</div>
														<div className="login-div-count-div">
															<input
																className=" form-control-home "
																type="password"
																name="password"
																onInput={this.passwordNumber}
																placeholder="设置密码"
															/>
														</div>
														<div className="login-div-count-div">
															<button className="btn-home" onClick={this.GoRegister}>
																注册
															</button>
														</div>
														<div className="login-div-forget" style={{ marginLeft: "58%" }}>
															<a onClick={this.GoLogin}>去登录</a>
														</div>
													</div>
													{/*找回密码*/}
													<div
														className="login-div-count"
														hidden={(this.state as any).forget}
													>
														<div className="login-div-count-div">
															<input
																className=" form-control-home "
																name="userId"
																onInput={this.userNumber}
																placeholder="登录账号/手机号"
															/>
														</div>
														<div className="login-div-count-div login-div-count-div-captcha">
															<div className="captcha-input">
																<input
																	className=" form-control-home-captcha-ssm "
																	value={(this.state as any).validateInput}
																	placeholder="请输入验证码"
																	onBlur={this.Blue}
																	onInput={this.handleChange}
																/>
																<div className="form-control-captcha">
																	<button
																		type="button"
																		className="btn form-control-captcha"
																		onClick={this.renderCode}
																	>
																		{(this.state as any).expression}
																	</button>
																</div>
																<div className="form-control-captcha">
																	<button
																		type="button"
																		disabled={(this.state as any).codeTag}
																		className="btn form-control-captcha"
																		onClick={this.SendOut}
																	>
																		{/*获取验证码*/}
																		{(this.state as any).obtainCaptche}
																	</button>
																</div>
															</div>
														</div>
														<div className="login-div-count-div">
															<input
																className=" form-control-home "
																name="phoneCode"
																onInput={this.phoneCode}
																placeholder="请输入收到的验证码"
															/>
														</div>
														<div className="login-div-count-div">
															<input
																className=" form-control-home "
																type="password"
																name="password"
																onInput={this.passwordNumber}
																placeholder="设置密码"
															/>
														</div>
														<div className="login-div-count-div">
															<button className="btn-home" onClick={this.Retrieve}>
																找回密码
															</button>
														</div>
														<div className="login-div-forget" style={{ marginLeft: "58%" }}>
															<a onClick={this.GoLogin}>去登录</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Col>
									<Col span={5}></Col>
								</Row>

								<div style={{display:'flex',justifyContent:'center'}} >
									<div className="custom_instructions"><h3 style={{color:'#fff'}}>中关村创客小镇（北京）科技有限公司</h3></div>
								</div>
							</div>
						)}
					</div>
				) : (
					<div
						style={{
							height: '100vh',
							width: '100%',
							display: 'flex',
							backgroundColor: 'white',
							position: "relative",
							overflow: 'hidden',
						}}
					>
						<div
							style={{
								flex: 1,
								height: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundColor: '#447BD5',
							}}
						>
							<img width={600} src={LoginImg} />
						</div>
						<div style={{ width: '445px', height: '100%' }} className="custom_container_right">
							<div className="custom_title">
								<div>欢迎回来</div>
								<div>Welcome back !</div>
							</div>
							{!(this.state as any).forget === true ? (
								<div>
									<div className="custom_form" style={{ width: '290px' }}>
										<div>
											<input placeholder="手机号/邮箱" name="userId" onInput={this.userNumber} />
										</div>
										<div className="errMsg">{(this.state as any).errMsg.username}</div>
										<div
											style={{
												display: 'flex',
												alignContent: 'center',
												alignItems: 'center',
											}}
										>
											<input
												value={(this.state as any).validateInput}
												placeholder="请输入验证码"
												onBlur={this.Blue}
												onInput={this.handleChange}
											/>
											<div style={{ position: 'relative' }}>
												<button onClick={this.renderCode} className="custom_captcha">
													{(this.state as any).expression}
												</button>
											</div>
										</div>
										<div className="errMsg">{(this.state as any).errMsg.captcha}</div>
										<div
											style={{
												display: 'flex',
												alignContent: 'center',
												alignItems: 'center',
											}}
										>
											<input
												onBlur={this.CodeBlur}
												onInput={this.phoneCode}
												placeholder="请输入收到的验证码"
											/>
										</div>
										<div className="errMsg">{(this.state as any).errMsg.phoneCode}</div>
										<div style={{ position: 'relative' }}>
											<button
												className="custom_sendBtn"
												onClick={() => {
													this.SendPhoneCode(true);
												}}
											>
												{(this.state as any).btnText}
											</button>
										</div>
										<div
											style={{
												display: 'flex',
												alignContent: 'center',
												alignItems: 'center',
											}}
										>
											<input
												type="password"
												name="password"
												onInput={this.passwordNumber}
												placeholder="设置新的密码"
												readOnly={true}
												onFocus={(event) => {
													event.currentTarget.removeAttribute('readonly');
												}}
											/>
										</div>
										<div className="errMsg">{(this.state as any).errMsg.password}</div>
										<div style={{ marginTop: '15px' }}>
											<Button type={'primary'} style={{ width: '100%' }} onClick={this.Retrieve}>
												找回密码
											</Button>
											<a
												onClick={this.GoLogin}
												style={{
													color: 'black',
													float: 'right',
													margin: '10px 5px 0px 0px',
													fontSize: '13px',
												}}
											>
												去登录
											</a>
										</div>
									</div>
								</div>
							) : (
								<div className="custom_tabs">
									<Tabs defaultActiveKey="1" centered={true}>
										<TabPane tab="账号登录" key="1">
											<div className="custom_form">
												<div>
													<input
														placeholder="请输入账号/手机号/邮箱"
														name="userId"
														onInput={this.userNumber}
													/>
												</div>
												<div className="errMsg">{(this.state as any).errMsg.username}</div>
												<div>
													<input
														placeholder="请输入密码"
														type="password"
														name="password"
														onInput={this.passwordNumber}
													/>
												</div>
												<div className="errMsg">{(this.state as any).errMsg.password}</div>
												<div
													style={{
														display: 'flex',
														alignContent: 'center',
														alignItems: 'center',
													}}
												>
													<input
														value={(this.state as any).validateInput}
														placeholder="请输入验证码"
														onBlur={this.Blue}
														onInput={this.handleChange}
													/>
													<div style={{ position: 'relative' }}>
														<button onClick={this.renderCode} className="custom_captcha">
															{(this.state as any).expression}
														</button>
													</div>
												</div>
												<div className="errMsg">{(this.state as any).errMsg.captcha}</div>
												{!(this.state as any).showAgreement && (
													<div
														style={{
															fontSize: '12px',
															display: 'flex',
															alignItems: 'center',
														}}
													>
														<div>
															<Checkbox checked={(this.state as any).agreement} onChange={(e: any) => {
																console.log('e:', e)
																this.setState({
																	agreement: e.target.checked
																})
																this.setCookie('agreement', e.target.checked);
															}}/>
														</div>
														<div>&ensp;我同意《果心用户协议》和《果心隐私协议》</div>
													</div>
												)}
												<div className="errMsg" >
													{(this.state as any).errMsg.agreement}
												</div>
												<div style={{ display: 'flex', justifyContent: 'space-between', color: 'black', fontSize: '13px', }}>
													<div>
														<Checkbox defaultChecked={localStorage.getItem("free") === 'true'} onChange={(e: any) => {
															console.log("check:::", e.target.checked)
															localStorage.setItem("free", e.target.checked)
														}}>30天免登录</Checkbox>
													</div>
													<a
														onClick={this.forget}
														style={{
															color: 'black',
															zIndex: 1000,
														}}
													>
														忘记密码
													</a>
												</div>
												<div style={{ marginTop: '15px' }}>

													<Button
														type="primary"
														style={{ width: '100%' }}
														// className="custom_login_btn"
														onClick={this.login}
														loading={loading}
													>
														立即登录
													</Button>
												</div>
											</div>
										</TabPane>
										<TabPane tab="短信登录" key="2">
											<div className="custom_form">
												<div>
													<input
														placeholder="手机号"
														name="userId"
														onInput={this.userNumber}
													/>
												</div>
												<div className="errMsg">{(this.state as any).errMsg.username}</div>
												<div
													style={{
														display: 'flex',
														alignContent: 'center',
														alignItems: 'center',
													}}
												>
													<input onInput={this.phoneCode} placeholder="请输入收到的验证码" />
												</div>
												<div style={{ position: 'relative' }}>
													<button
														className="custom_sendBtn"
														disabled={(this.state as any).btnBool}
														onClick={() => {
															this.SendPhoneCode(false);
														}}
													>
														{(this.state as any).btnText}
													</button>
												</div>
												<div className="errMsg">{(this.state as any).errMsg.captcha}</div>
												{!(this.state as any).showAgreement && (
													<div
														style={{
															fontSize: '12px',
															display: 'flex',
															alignItems: 'center',
														}}
													>
														<div>
															<Checkbox checked={(this.state as any).agreement} onChange={(e: any) => {
																this.setState({
																	agreement: e.target.checked
																})
																this.setCookie('agreement', e.target.checked);
															}}/>
														</div>
														<div>&ensp;我同意《果心用户协议》和《果心隐私协议》</div>
													</div>
												)}
												<div className="errMsg" >
													{(this.state as any).errMsg.agreement}
												</div>
												<div style={{display: 'flex', justifyContent: 'space-between',color: 'black',
													fontSize: '13px',}}>
													<div>
														<Checkbox defaultChecked={localStorage.getItem("free") === 'true'} onChange={(e: any) => {
															console.log('checked::::', e.target.checked)
															localStorage.setItem("free", e.target.checked)
														}}>30天免登录</Checkbox>
													</div>
													<a
														onClick={this.forget}
														style={{
															zIndex: 1000,
															color: 'black',
															fontSize: '13px',
														}}
													>
														忘记密码
													</a>
												</div>

												<div style={{ marginTop: '15px' }}>
													{/*<Spin />*/}
													<Button
														type="primary"
														style={{ width: '100%' }}
														// className="custom_login_btn"
														onClick={this.loginWithSms}
														loading={loading}
													>
														立即登录
													</Button>

												</div>
											</div>
										</TabPane>
									</Tabs>
								</div>
							)}
							<div className="custom_instructions">Copyright@北京果心科技有限公司</div>
						</div>
					</div>
				)}
			</>
		);
	}

	// 设置Cookie
	setCookie(key: string, value: string) {
		let d = new Date();
		d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
		const expires = 'expires=' + d.toUTCString();
		document.cookie = key + '=' + value + ';' + expires;
	}

	// 获取Cookie
	getCookie(key: string) {
		const name = key + '=';
		const ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			const c = ca[i].trim();
			if (c.indexOf(name) === 0) {
				return c.substr(name.length, c.length);
			}
		}
		return '';
	}

	//获得账户
	userNumber(ev: any) {
		userId = ev.target.value.toString();
	}

	passwordNumber(ev: any) {
		password = ev.target.value.toString();
	}

	captcheNumber(ev: any) {
		captche = ev.target.value.toString();
	}

	phoneCode(ev: any) {
		phoneCode = ev.target.value.toString();
	}

	forget() {
		this.setState({
			forget: false,
			showLogin: true,
			showRegister: true,
			validateInput: '',
			errMsg: {},
		});
		userId = '';
		password = '';
	}

	CodeBlur() {
		if (phoneCode !== null && phoneCode !== '' && phoneCode !== undefined) {
			if (phoneCode !== (this.state as any).code) {
				// this.openNotificationWithIcon('')
				return;
			}
		}
	}

	/**
	 * 获取手机验证码 -> 找回密码
	 * autor: mufeng
	 * date: 2020.8.15
	 * @constructor
	 */
	SendPhoneCode = async (verify: boolean) => {
		if (userId === undefined || userId === '') {
			//msg.suspensionMsg('账号不能为空');
			let errMsg = {
				username: '账号不能为空',
				password: '',
				captcha: '',
				agreement: '',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			return;
		}
		if (verify && ((this.state as any).validateInput === undefined || (this.state as any).validateInput === '')) {
			msg.suspensionMsg('请输入验证码');
			return;
		}

		this.timer = setInterval(() => {
			if (maxTime > 0) {
				--maxTime;
				this.setState({
					btnText: '重新获取' + maxTime,
					btnBool: true,
				});
			} else {
				this.setState({
					btnText: '发送验证码',
					btnBool: false,
				});
			}
		}, 1000);
		let time = new Date().getTime();
		let sign = md5.hex_md5('36348844314779561566' + time + userId + '36348844314779561566');
		let data = {
			username: userId,
			sign: sign,
			timestamp: time,
			user_id: userId,
			wuye_uuid: 'cb743f05-e07e-4159-8a34-2d55ba713cea',
			is_verify: true,
		};
		const res = await getLoginCode({ ...data });
		if (res.err === 0) {
			msg.suspensionMsg({ content: '验证码发送成功，请注意接收' });
		} else {
			msg.suspensionMsg({ content: '验证码发送失败，请检查您的手机号是否正确' });
			return;
		}
	};

	SendOut = async () => {
		if (userId === undefined || userId === '') {
			msg.suspensionMsg({ content: '请输入账号', type: 'danger' });
			return;
		}
		if ((this.state as any).validateInput === undefined || (this.state as any).validateInput === '') {
			msg.suspensionMsg({ content: '请输入验证码', type: 'danger' });
			return;
		}

		this.startCountDown();
		this.setState({
			codeTag: true,
		});
		const res = await saasRegister({
			userId: userId,
			wuye_uuid: appConfig.wuYeUuid
		});
		if (res.err === 0) {
			this.setState({
				code: res.code,
			});
		} else {
			msg.suspensionMsg({ content: '验证码发送失败', type: 'danger' });
			return;
		}
	};

	GoRegister = async () => {
		if (userId !== null && userId !== undefined && userId !== '') {
			const res = await saasUserRegister({
				userId: userId,
				password: password,
			});
			if (res.err === 0) {
				msg.suspensionMsg({ content: '注册成功，请去登录', type: 'success' });
				return;
			} else {
				msg.suspensionMsg('注册失败');
				return;
			}
		}
	};

	Retrieve = async () => {
		if (userId === undefined || userId === '') {
			let errMsg = {
				username: '手机号或邮箱不能为空',
				password: '',
				captcha: '',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			return;
		}

		if ((this.state as any).validateInput === undefined || (this.state as any).validateInput === '') {
			let errMsg = {
				username: '',
				password: '',
				captcha: '验证码不能为空',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			return;
		}

		let thisInput = (this.state as any).validateInput;
		let validateCode = (this.state as any).expression;
		if (thisInput !== validateCode) {
			let errMsg = {
				username: '',
				password: '',
				captcha: '验证码不正确',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			return;
		}

		if (phoneCode === undefined || phoneCode === '') {
			let errMsg = {
				username: '',
				password: '',
				// captcha: '验证码不能为空',
				phoneCode: '验证码不能为空',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			return;
		}

		if (phoneCode === undefined || phoneCode === '') {
			let errMsg = {
				username: '',
				password: '',
				captcha: '验证码不能为空',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			return;
		}

		if (password === undefined || password === '') {
			let errMsg = {
				username: '',
				password: '密码不能为空',
				captcha: '',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			return;
		}
		let passReg =/^(?=.*?[a-z])(?=.*?\d)(?=.*?[!#@*&.])[a-zA-Z\d!#@*&.]{8,16}$/;
		console.log('password', password, passReg.test(password))
		if (!passReg.test(password)) {
			let errMsg = {
				username: '',
				password: '密码必须为8-18位字母、数字、特殊符号的',
				captcha: '',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			return;
		}

		if (userId !== undefined && userId !== null) {
			const res = await saasRetrieve({
				userId: userId,
				password: password,
				code: phoneCode
			});
			this.setState({
				errMsg: {},
			});
			if (res.err === 0) {
				msg.suspensionMsg({ content: '找回密码成功', type: 'success' });
				return;
			} else {
				msg.suspensionMsg({ content: '找回密码失败，请检查您的用户名和验证码是否正确', type: 'danger' });
				return;
			}
		}
	};

	startCountDown() {
		let that = this;
		let currentTime = (this.state as any).currentTime;
		let interval = setInterval(function () {
			currentTime--;
			that.setState({
				obtainCaptche: currentTime + "秒",
			});
			if (currentTime <= 0) {
				clearInterval(interval);
				that.setState({
					obtainCaptche: "重新发送",
					currentTime: 61,
					codeTag: false,
				});
			}
		}, 1000);
	}
	userBlur = async () => {
		if (userId !== undefined || userId !== '') {
			const res = await judgeAccount({
				userId: userId,
			});
			console.log('', res);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '此账号已经注册', type: 'danger' });
			} else {
				return;
			}
		}
	};

	loginWithSms = async () => {
		if (userId === undefined || userId === '') {
			let errMsg = {
				username: '手机号不能为空',
				password: '',
				captcha: '',
				agreement: '',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			return;
		}
		if (phoneCode === undefined || phoneCode === '') {
			let errMsg = {
				username: '',
				password: '',
				captcha: '验证码不能为空',
				agreement: '',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			return;
		}
		console.log('是否同意：：：', (this.state as any).agreement)
		if (!(this.state as any).agreement ) {
			let errMsg = {
				username: '',
				password: '',
				captcha: '',
				agreement: '请勾选隐私协议',
			};
			this.setState({
				errMsg: { ...errMsg },
			});
			return;
		}
		let data = {
			userId: userId,
			phoneCode: phoneCode,
		};
		const dispatch = (this.props as any).dispatch;
		console.log('this.props', this.props as any);
		dispatch({
			type: 'login/phoneLogin',
			payload: {
				userId: userId,
				phoneCode: phoneCode,
			},
		});
		this.setState({
			loading: false,
		});
		console.log('this.props:::::', this.props);
	};

	renderCode() {
		var expression = '',
			result;
		//判断验证码类型
		result = '';
		let size = 4;
		var codeNormal = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; //字母库
		for (var j = 0; j < size; j++) {
			result = result + codeNormal[Math.round(Math.random() * (codeNormal.length - 1))];
		} //随机获取字母四个
		expression = result.toLowerCase(); //忽略大小写
		this.setState({
			//设置更新状态
			expression: expression,
			validate: result,
		});
	}

	handleChange(ev: any) {
		this.setState({
			validateInput: ev.target.value,
		});
	}

	Blue() {
		var thisInput = (this.state as any).validateInput;
		var validateCode = (this.state as any).expression;
		if (thisInput !== null || thisInput !== '') {
			if (thisInput.toLowerCase() === validateCode.toString().toLowerCase()) {
				return 'success';
			} else if (thisInput !== '') {
				// msg.suspensionMsg('验证码输入错误');
				return 'error';
			} else {
				return;
			}
		}
		return;
	}
}

export default connect(({ login, common }: ConnectState) => ({
	login: login,
	common: common,
}))(Login);

// helpers
