import React, {useRef, useState} from 'react';
import GSTable from "../../../components/GSTable";
import {ActionType} from "@ant-design/pro-table/lib/typing";
import {Button, Col, Form, Input, message, Modal, Row, Space, Tag} from "antd";
import {auditorWorkTask, getList} from "../../../services/WorkTaskService";
import UploadImgFormItem from "../../../components/UploadImgFormItem";
import UploadFilFormItem from "../../../components/UploadFileFormItem";

const {CheckableTag} = Tag;
const Index: React.FC = () => {
  const actionRef = useRef<ActionType>()
  const [addForm] = Form.useForm();
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('待审核');
  const [audioVisible, setAudioVisible] = useState(false);
  const note = useRef('')
  const columns = [

    {
      title: '名称',
      dataIndex: 'name',
      width: 100,
      key: 'name',
    }, {
      title: '薪资',
      dataIndex: 'salary',
      width: 100,
      key: 'salary',
    },
    {
      title: '公司名称',
      dataIndex: 'corporateName',
      width: 150,
      key: 'corporateName',
    },
    {
      title: '职位',
      width: 150,
      dataIndex: 'jobTitle',
      key: 'jobTitle',
    }, {
      title: '邮箱',
      width: 100,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '创建时间',
      width: 200,
      dataIndex: 'createdTime',
      valueType: 'dateTime',
      key: 'createdTime',
    },
    {
      title: '操作',
      width: 100,
      fixed: 'right',
      key: 'action',
      render: (text: string, record: any) => (
        <Space>
          {
            record.status === '待审核' && <a onClick={() => {
              setAudioVisible(true)
              addForm.setFieldsValue(record)
            }}>审核</a>
          }
        </Space>
      ),
    },
  ];
  const tableSearchBarRender = () => [
    <CheckableTag
      key={'1'}
      className="faceTag"
      checked={status === '通过'}
      onChange={() => {
        setStatus('通过')
        if (actionRef.current) {
          actionRef.current.reloadAndRest();
        }
      }}
    >
      通过
    </CheckableTag>,
    <CheckableTag
      key={'3'}
      className="faceTag"
      checked={status === '拒绝'}
      onChange={() => {
        setStatus('拒绝')
        if (actionRef.current) {
          actionRef.current.reloadAndRest();
        }
      }}
    >
      拒绝
    </CheckableTag>,
    <CheckableTag
      key={'3'}
      className="faceTag"
      checked={status === '待审核'}
      onChange={() => {
        setStatus('待审核')
        if (actionRef.current) {
          actionRef.current.reloadAndRest();
        }
      }}
    >
      待审核
    </CheckableTag>,
    <Input placeholder={'项目名称'} onChange={(event: any) => {
      setSearch(event.target.value)
    }}/>,
    <Button type="primary" onClick={() => {
      if (actionRef.current) {
        actionRef.current.reload()
      }
    }}>查询</Button>
  ];
  const refuse = () => {
    Modal.confirm({
      title: '您确定要拒绝吗？',
      content: <Row gutter={8}>
        <Col>
          理由：
        </Col>
        <Col>
          <Input.TextArea onChange={e => {
            note.current = e.target.value;
          }}/>
        </Col>
      </Row>,
      onOk: async () => {
        const uuid = addForm.getFieldValue("uuid");
        if (!note.current) {
          message.warn('填写理由');
          return
        }
        let rsp = await auditorWorkTask({uuid: uuid, "status": "拒绝", "description": note.current});
        if (rsp.err === 0) {
          message.success('操作成功')
          setAudioVisible(false)
          actionRef.current.reload()
        } else {
          message.error(rsp.msg);
        }
      }
    })
  }
  const adopt = () => {
    Modal.confirm({
      title: '您确定要通过吗？',
      onOk: async () => {
        const uuid = addForm.getFieldValue("uuid");
        let rsp = await auditorWorkTask({uuid: uuid, "status": "通过"});
        if (rsp.err === 0) {
          message.success('操作成功')
          actionRef.current.reload()
          setAudioVisible(false)
        } else {
          message.error(rsp.msg);
        }
      }
    })
  }
  return (<>
    <GSTable
      columns={columns}
      actionRef={actionRef}
      rowKey={"uuid"}
      searchBarRender={tableSearchBarRender()}
      operationsBarRender={[
        // <Button type={"primary"} onClick={() => {
        //   addForm.resetFields()
        //   setAddVisible(true)
        // }}>添加</Button>
      ]}
      request={async (params: any = {}, sort: any, filter: any) => {
        const res = await getList({
          page: params.current,
          size: params.pageSize,
          status: status,
          search: search,
        });
        if (res.err !== 0) {
          return {
            data: [],
            total: 0,
          };
        }
        return {
          data: res.data.data,
          total: res.data.total,
          success: true,
        };
      }}
    />
    <Modal destroyOnClose={true} title={'审核'} visible={audioVisible} onCancel={() => {
      setAudioVisible(false)
    }} onOk={() => {
      setAudioVisible(false)
    }} footer={[
      <Button type={"dashed"} danger={true} onClick={refuse}>拒绝</Button>,
      <Button type={"primary"} onClick={adopt}>通过</Button>
    ]}>
      <Form form={addForm} labelCol={{span: 6}}>
        <Form.Item name={'uuid'} label={'uuid'} hidden={true}>
          <Input/>
        </Form.Item>
        <Form.Item name={'name'} label={'名称'}>
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item name={'salary'} label={'薪资'}>
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item name={'content'} label={'工作内容'}>
          <Input.TextArea disabled={true}/>
        </Form.Item>
        <Form.Item name={'applicationRequirements'} label={'申请条件'}>
          <Input.TextArea disabled={true}/>
        </Form.Item>
        <Form.Item name={'corporateName'} label={'公司名称'}>
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item name={'companyProfile'} label={'公司简介'}>
          <Input.TextArea disabled={true} />
        </Form.Item>
        <Form.Item name={'jobTitle'} label={'职位名称'}>
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item name={'email'} label={'邮箱'}>
          <Input disabled={true}/>
        </Form.Item>
      </Form>
    </Modal>
  </>);
}
export default Index;
