import React, { useEffect, useRef, useState } from "react";
import {
    addItemInfo,
    deleteItemInfo,
    getItemInfoList,
     updateItemInfo
} from '../../../services/ZhuhushenheService';
import GSTable from "../../../components/GSTable";
import { ActionType } from "@ant-design/pro-table/lib/typing";
import { Button, Form, Input, InputNumber, message, Modal, Select, Space } from "antd";
import { FormInstance } from "antd/es/form";
import ProxyForm from "../../../components/ProxyForm";
import { addBlackList, deleteBlackList, getBlackList, updateBlackUser } from "../../../services/BlackUser";
const BlackListManager: React.FC = () => {
    const actionRef = useRef<ActionType>();
    const [search, setSearch] = useState('');
    const [visible, setVisible] = useState(false);
    const [type, settype] = useState("")
    const [loading, setLoading] = useState(false);
    const [add, setadd] = useState(false)
    const [form] = Form.useForm<FormInstance>();
    const columns = [

        {
            title: '参数信息',
            dataIndex: 'paramInfo',
            key: 'paramInfo',
            width: 150,
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            width: 100,
        },
        {
            title: '描述',
            dataIndex: 'note',
            key: 'note',
            width: 200,
        },
        {
            title: '创建时间',
            dataIndex: 'createdTime',
            valueType: 'dateTime',
            key: 'createdTime',
            width: 200,
        },
        {
            title: '操作',
            key: 'action',
            width: 100,
            fixed: 'right',
            render: (record: any) => (
                <Space>
                    <a onClick={() => {
                        form.setFieldsValue(record)
                        setadd(false)
                        setVisible(true)
                    }}>
                        编辑
                    </a>
                    <a onClick={() =>{
                        Modal.confirm({
                            title: '提示',
                            content: "您确定要将"+record.paramInfo+'移出黑名单吗？',
                            onOk: async() => {
                                let data = {
                                    id: record.id
                                }
                                let rsp = await deleteBlackList(data)
                                if (rsp.err === 0) {
                                    message.success(rsp.msg)
                                    if (actionRef.current) {
                                        actionRef.current.reload()
                                    }
                                    return
                                }
                                message.error(rsp.msg)
                            }
                        })
                    }}>删除</a>
                </Space>
            ),
        },
    ];
    const normalize = (value: string) => {
		console.log('value::::', value);
		let data: any = {
			paramInfo: value.toString().replace(/\s*/g,"").toUpperCase(),
		};
		form.setFieldsValue(data);
	};
    const tableSearchBarRender = () => [
        <Input placeholder={'参数信息'} onChange={(event: any) => {
            setSearch(event.target.value)
        }} />,
        <Select value={type} onChange={ (value)=>settype(value)}>
            <Select.Option value={""}>全部</Select.Option>
            <Select.Option value={"人员"}>人员</Select.Option>
            <Select.Option value={"车辆"}>车辆</Select.Option>
        </Select>,
        <Button type="primary" onClick={() => {
            if (actionRef.current) {
                actionRef.current.reload()
            }
        }}>查询</Button>
    ];
    const tableOperationsBarRender = () => [
        <Button type="primary" onClick={() => {
            setadd(true)
            setVisible(true)
        }}>
            新增
        </Button>,
    ];
    const updateItem =async () => {
        let data = await form.validateFields();
        console.log('data:::', data);
        setLoading(true)
        let rsp = await updateBlackUser(data)
        setLoading(false)
        if (rsp.err === 0) {
            message.success(rsp.msg);
            setVisible(false)
            if (actionRef.current) {
                actionRef.current.reload()
            }
        } else {
            message.error(rsp.msg)
        }

    }
    const addItem = async() => {
        let data = await form.validateFields();
        console.log('data:::', data);
        setLoading(true)
        let rsp = await addBlackList(data)
        setLoading(false)
        if (rsp.err === 0) {
            message.success(rsp.msg);
            setVisible(false)
            if (actionRef.current) {
                actionRef.current.reload()
            }
        } else {
            message.error(rsp.msg)
        }
    }
    const saveItem = async() => {
        if (add) {
            addItem()
        } else {
            updateItem()
        }
    }
    return <>
        <GSTable
            columns={columns}
            actionRef={actionRef}
            searchBarRender={tableSearchBarRender()}
            operationsBarRender={tableOperationsBarRender()}
            request={async (params: any = {}, sort: any, filter: any) => {
                const res = await getBlackList({
                    page: params.current,
                    size: params.pageSize,
                    search: search,
                });
                if (res.err !== 0) {
                    return {
                        data: [],
                        total: 0,
                    };
                }
                return {
                    data: res.data.data,
                    total: res.data.total,
                    success: true,
                };
            }}
        />
        <Modal title={add?"添加" :"修改"} visible={visible} onCancel={() => {setVisible(false)}} maskClosable={false} confirmLoading={loading} onOk={saveItem}>
            <ProxyForm form={form}>
                <Form.Item hidden={true}
                    name={"id"} ><Input></Input></Form.Item>
            <Form.Item name={'type'} label={'类型'} rules={[{required: true, message: '请填写类型'}]}>
                    <Select disabled={!add}>
                        <Select.Option value={"人员"}>人员</Select.Option>
                        <Select.Option value={"车辆"}>车辆</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name={'paramInfo'} tooltip="支持手机号、身份证号、车牌号" label={'参数信息'} rules={[{required: true, message: '请填写参数信息'}]}>
                    <Input disabled={!add} onChange={(e) => { normalize(e.target.value)}} />
                </Form.Item>
                <Form.Item name={"note"} label="描述">
                    <Input.TextArea></Input.TextArea>
                </Form.Item>
            </ProxyForm>
        </Modal>


    </>
}
export default BlackListManager;
