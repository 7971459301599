import React, { useEffect, useState } from 'react';
import { meetingFindByUuid, updateMeeting } from '../../../services/meetingService';
import {Button, Col, Form, Input, message, Row, Select, Upload} from 'antd';
import ProxyForm from '../../../components/ProxyForm';
import { FormInstance } from 'antd/es/form';
import Api from '../../../data/API';
import { PlusOutlined } from '@ant-design/icons/lib';
import {connect} from "dva";
import ConnectState from "../../../models/connect";
let msg = require('../../../util/SuspensionMsg');
const MeetingInfo: React.FC = (props:any) => {
	let uuid;
	useEffect(() => {
		uuid = getUuid();
		getData();
	}, []);
	const {common} = props
	const { deviceList } = common;
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [photo, setPhoto] = useState('');
	const getUuid = () => {
		const page = window.location.search;
		const c = page.split('=');
		const uuid = c[1];
		return uuid;
	};

	const getData = async () => {
		const res = await meetingFindByUuid({
			uuid: getUuid(),
		});
		console.log('meeting', res);
		if (res.err === 0) {
			setPhoto(res.picture);
			form.setFieldsValue(res);
		} else {
			message.error(res.msg);
		}
	};
	const formLayout = {
		labelCol: { span: 6 },
		wrapperCol: { span: 14 },
	};

	const saveItem = () => {
		form.validateFields().then(async (data: any) => {
			console.log('data:::', data);
			const res = await updateMeeting({
				name: data.name,
				area: data.area,
				price1: data.price1,
				people_num: data.people_num,
				auth_time: data.auth_time,
				start_time: data.start_time,
				end_time: data.end_time,
				linkedman: data.linkedman,
				creater: data.creater,
				menjin_device_id: data.menjin_device_id,
				address: data.address,
				equipment: data.equipment,
				picture: photo,
				uuid: getUuid(),
			});
			if (res.err === 0) {
				msg.suspensionMsg({ content: res.msg, type: 'success' });
			} else {
				msg.suspensionMsg({ content: res.msg });
			}
		});
	};
	const getMeetFileName = (fileList: any) => {
		console.log('fileList', fileList);
		if (fileList.file.status === 'done') {
			setPhoto(fileList.file.response.url);
			console.log(`${fileList.file.name}`);
			console.log('会议', fileList.file.response.url);
		} else if (fileList.file.status === 'error') {
			message.error('上传图片失败');
		}
	};

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>上传</div>
		</div>
	);
	return (
		<div className="content" style={{ marginTop: '15px' }}>
			<div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
				<Button type="primary" onClick={saveItem}>
					修改信息
				</Button>
			</div>
			<Form {...formLayout} form={form}>
				<FormItem label={'uuid'} name={'uuid'} hidden={true}>
					<Input />
				</FormItem>
				<Row>
					<Col span={12}>
						<FormItem label={'图片'} tooltip={'该图片在会议室列表页面展示'} name={'picture'}>
							<Upload
								name="image"
								listType="picture-card"
								className="avatar-uploader"
								showUploadList={false}
								data={{ wuye_uuid: localStorage.getItem('wyUuid') }}
								action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}
								onChange={getMeetFileName}
								accept={'.jpg,.png'}
							>
								{photo !== null && photo !== undefined && photo !== '' ? (
									<img alt="avatar" style={{ width: '100%' }} src={Api.cdnRootPath + photo} />
								) : (
									uploadButton
								)}
							</Upload>
						</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<FormItem label={'名称'} name={'name'} rules={[{ required: true }]}>
							<Input />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem label={'价格'} name={'price1'} tooltip={'单位收费时间段的价格'} rules={[{ required: true }]}>
							<Input suffix="元" />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem label={'收费单位'} name={'charge_unit'} rules={[{ required: true }]}>
							<Input suffix="分钟" />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem
							label={'使用开始时间'}
							name={'start_time'}
							tooltip={'只有在使用时间段内的时间可以预定'}
							rules={[{ required: true }]}
						>
							<Input prefix="每天" suffix="点" />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem
							label={'使用结束时间'}
							name={'end_time'}
							tooltip={'只有在使用时间段内的时间可以预定'}
							rules={[{ required: true }]}
						>
							<Input prefix="每天" suffix="点" />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem label={'容纳人数'} name={'people_num'} rules={[{ required: true }]}>
							<Input suffix="人" />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem label={'面积'} name={'area'} rules={[{ required: true }]}>
							<Input suffix="平方米" />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem label={'关联门禁'} name={'menjin_device_id'}>
							<Select
								optionFilterProp="children"
								showSearch
								style={{width:'100%'}}>
								{deviceList.length>0?
									deviceList.map((item:any)=>{
										return <Select.Option key={item.deviceid} value={item.deviceid}>{item.description}</Select.Option>
									})
									:''}
							</Select>
						</FormItem>

					</Col>
					<Col span={12}>
						<FormItem label={'服务电话'} name={'linkedman'} rules={[{ required: true }]}>
							<Input />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem label={'地址'} name={'address'} rules={[{ required: true }]}>
							<Input />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem
							label={'钥匙授权提前时间'}
							tooltip={'当会议室关联智能门禁时，预定会议室后发送电子钥匙有效期的提前时间'}
							name={'auth_time'}
							rules={[{ required: true }]}
						>
							<Input suffix="分钟" />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem label={'设备'} name={'equipment'} rules={[{ required: true }]}>
							<Input.TextArea />
						</FormItem>
					</Col>
				</Row>
			</Form>
		</div>
	);
};
export default connect(({ common }: ConnectState) => ({
	common: common,
}))(MeetingInfo);
