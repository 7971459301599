import React, { useRef, useState } from 'react';
import { FormInstance } from 'antd/es/form';
import { Button, Form, Image, Input, Modal, Upload } from 'antd';
import { ActionType } from '@ant-design/pro-table';
import appConfig from '../../../appConfig';
import { Link } from 'react-router-dom';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { addPlan, deletePlan, findPlan } from '../../../services/patrolService';
import API from '../../../data/API';
import Api from '../../../data/API';
import { PlusOutlined } from '@ant-design/icons/lib';

const PlaneManagement: React.FC = () => {
	let msg = require('../../../util/SuspensionMsg');
	const confirm = Modal.confirm;
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [visible, setVisible] = useState(false);
	const [add, setAdd] = useState(false);
	const [photo, setPhoto] = useState('');
	const columns = [
		{
			title: '名称',
			dataIndex: 'planName',
			key: 'planName',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						openUpdateModal(record);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: '图片',
			dataIndex: 'imgName',
			key: 'imgName',
			render: (text: string, record: any) => (
				<Image width={80} height={50} src={appConfig.cdnRootPath + record.imgName} />
			),
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<div>
					<span>
						<ul className="ant-list-item-action">
							<li>
								<a>
									<Link to={`/patrolinspection/patrol_plane?uuid=${record.uuid}&readOnly=false`}>
										查看点位
									</Link>
								</a>
								{/*<em className="ant-list-item-action-split" />*/}
							</li>
							<li>
								<Link to={`/patrolinspection/patrol_plane?uuid=${record.uuid}&readOnly=true`}>
									<a>设置点位</a>
								</Link>
								{/*<em className="ant-list-item-action-split" />*/}
							</li>
							<li>
								<a
									onClick={() => {
										deleteItem(record);
									}}
								>
									删除
								</a>
							</li>
						</ul>
					</span>
				</div>
			),
		},
	];

	const deleteItem = (value: any) => {
		confirm({
			title: '您确认要删除此平面图吗？',
			okType: 'danger',
			onOk: async () => {
				const res = await deletePlan({
					uuid: value.uuid,
				});
				console.log('删除后获得平面图', res);
				if (res.err === 0) {
					msg.suspensionMsg({ content: '删除成功', type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					msg.suspensionMsg({ content: res.msg, type: 'danger' });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const openUpdateModal = (value: any) => {
		setPhoto(value.imgName);
		setAdd(false);
		const formData: any = {
			name: value.planName,
			uuid: value.uuid,
			photo: value.imgName,
		};
		form.setFieldsValue(formData);
		setVisible(true);
	};

	const onclickOk = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData = {
				planName: data.name,
				imgName: photo,
				imgState: 1,
			};
			const res = await addPlan(formData);
			if (res.err === 0) {
				setVisible(false);
				msg.suspensionMsg({ content: '添加成功', type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: '添加失败', type: 'error' });
			}
			console.log('data:::', formData);
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData = {
				planName: data.name,
				imgName: photo,
				uuid: data.uuid,
				imgState: 2,
			};
			const res = await addPlan(formData);
			if (res.err === 0) {
				setVisible(false);
				msg.suspensionMsg({ content: '修改成功', type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: '修改失败', type: 'error' });
			}
			console.log('data:::', formData);
		});
	};
	const openAddModal = () => {
		form.resetFields();
		setPhoto('');
		setAdd(true);
		setVisible(true);
	};
	const tableOperationsBarRender = () => [
		<Button type="primary" onClick={openAddModal}>
			新增
		</Button>,
	];
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>上传</div>
		</div>
	);
	const getFileName = (fileList: any) => {
		console.log('fileList', fileList);
		if (fileList.file.status === 'done') {
			setPhoto(fileList.file.response.url);

			console.log(`${fileList.file.name}`);
			console.log('平面图', fileList.file.response.url);
		} else if (fileList.file.status === 'error') {
			msg.suspensionMsg({ content: `${fileList.file.name} 上传图片失败`, type: 'error' });
		}
	};
	return (
		<div>
			<GSTable
				rowKey={"uuid"}
				columns={columns}
				actionRef={actionRef}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findPlan({
						page: params.current,
						size: params.pageSize,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				visible={visible}
				title={add ? '添加' : '修改'}
				onOk={onclickOk}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem hidden={true} label={'uuid'} name={'uuid'}>
						<Input />
					</FormItem>
					<FormItem label={'名称'} name={'name'} rules={[{ required: true }]}>
						<Input />
					</FormItem>
					<FormItem label={'平面图'} name={'photo'} rules={[{ required: true }]}>
						<Upload
							name="image"
							listType="picture-card"
							className="avatar-uploader"
							showUploadList={false}
							data={{ wuye_uuid: localStorage.getItem('wyUuid') }}
							action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}
							onChange={getFileName}
						>
							{photo !== null && photo !== undefined && photo !== '' ? (
								<img
									alt="avatar"
									style={{ width: 102, height: 102 }}
									src={appConfig.cdnRootPath + photo}
								/>
							) : (
								uploadButton
							)}
						</Upload>
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default PlaneManagement;
