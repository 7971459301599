import React, { useRef, useState } from 'react';
import {
	Button,
	Col,
	DatePicker,
	Divider,
	Form,
	Image,
	Input,
	message,
	Modal,
	Radio,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Tag,
	Tooltip,
	Upload,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import { ActionType } from '@ant-design/pro-table';
import Api from '../../../data/API';
import GSTable from '../../../components/GSTable';
import {
	addHousehold,
	findBuilds,
	findLiveRoom,
	findRoom,
	getHouseholdList,
	householdCheckOut,
	updataHousehold,
} from '../../../services/ZhuhushenheService';
import { Link, NavLink, Redirect } from 'react-router-dom';
import KaimenList from '../../assets/components/KaimenData';
import API from '../../../data/API';
import appConfig from '../../../appConfig';
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons/lib';
import RoomAndHousehold from '../../assets/components/RoomAndHousehold';
import { getHealthCheckList } from '../../../services/TemperatureRecordsService';
import moment from '../../epidemicPrevention/pages/HealthCheckRecord';
import * as XLSX from 'xlsx';
import CustomUploadImage from '../../../components/CustomUploadImage';
import PayNotice from '../../notification/pages/PayNotice';
import AuthorizedView from "../../../util/AuthorizedView";
import DAL from "../../../data/DAL";
const RangePicker = DatePicker.RangePicker;
var myXlsx = require('../../../util/myXlsx');
interface RoomAndHouse {
	roomUuid: any;
	roomAndHouse: boolean;
}
const HouseholdList: React.FC<RoomAndHouse> = (props: any) => {
	const { roomUuid, roomAndHouse } = props;
	let moment = require('moment');
	const msg = require('../../../util/SuspensionMsg');
	const confirm = Modal.confirm;
	const Search = Input.Search;
	const uploadUrl = Api.apiRootPath + '/api/picture/upload_qiniu';
	const { CheckableTag } = Tag;
	const RadioGroup = Radio.Group;
	const Option = Select.Option;
	const InputGroup = Input.Group;
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const [isRuzhu, setIsRuzhu] = useState('1');
	const [state, setState] = useState(1);
	const [visible, setVisible] = useState(false);
	const [add, setAdd] = useState(false);
	const [dateKey, setdateKey] = useState(new Date().getTime().toString());
	const [tongzhiPhone, settongzhiPhone] = useState([]);
	const [tongzhiVisible, settongzhiVisible] = useState(false);
	const [kaimenVisiable, setKaimenVisiable] = useState(false);
	const [deviceId, setDeviceId] = useState('');
	const [deviceName, setDeviceName] = useState('');
	const [userId, setUserId] = useState('');
	const [zhengjianType, setZhengjianType] = useState('1');
	const [zhengjianNumber, setZhengjianNumber] = useState('');
	const [contractEndStartTime, setcontractEndStartTime] = useState('');
	const [contractEndEndTime, setcontractEndEndTime] = useState('');
	const [roomNumber0, setRoomNumber0] = useState('请选择房间');
	const [loading, setloading] = useState(false);
	const [batchSelectRoomsVisible, setBatchSelectRoomsVisible] = useState(false);
	const [rooms, setRooms] = useState([]);
	const [builds, setBuilds] = useState([]);
	const [fangjianUuid, setFangjianUuid] = useState('');
	const [isShow, setIsShow] = useState(false);
	const [photo, setPhoto] = useState('');
	const [checkStartTime, setCheckStartTime] = useState('');
	const [checkOutTime, setCheckOutTime] = useState('');
	const [roomNumber, setRoomNumber] = useState('');
	const [roomUuid1, setRoomUuid1] = useState('');
	const [roomAndHouse1, setRoomAndHouse1] = useState(false);
	const [fangVisiable, setFangVisiable] = useState(false);
	const [quanXuan, setQuanXuan] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [total, setTotal] = useState(0);
	const [status, setStatus] = useState('');
	let [fileList, setFileList] = useState<any[]>([]);
	const [previewVisable, setPreviewVisable] = useState(false);
	const [previewFileUrl, setPreviewFileUrl] = useState('');
	const [startTime, setStartTime] = useState('');
	const zaiZhuColumns = [
		{
			title: '姓名',
			dataIndex: 'householdName',
			key: 'householdName',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						setUpdateItem(record);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: '类型',
			key: 'householder',
			dataIndex: 'householder',
		},
		{
			title: '房间号',
			key: 'roomNumber',
			dataIndex: 'roomNumber',
			render: (text: string, record: any) => (
				<span>
					{roomAndHouse || !DAL.checkPermission("assets_management:room_management") ? (
						text
					) : (
						<a
							onClick={() => {
								getFangjian(record);
							}}
						>
							{' '}
							{text}{' '}
						</a>
					)}
				</span>
			),
		},
		{
			title: '位置',
			key: 'position',
			dataIndex: 'position',
		},
		{
			title: '登录帐号',
			dataIndex: 'phone',
			key: 'phone',
			render: (phone: any, record: any) => {
				return (
					<>
						<span>{phone}</span>
						{record.userId === '' ||
						record.userId == null ||
						record.userId === undefined ||
						record.userId1 === '' ||
						record.userId1 === null ||
						record.userId1 === undefined ? (
							<span style={{ color: '#FF4D4F' }}>（未激活）</span>
						) : (
							''
						)}
					</>
				);
			},
		},
		{
			title: '状态',
			key: 'isSendKey',
			dataIndex: 'isSendKey',
			render: (text: any, record: any) => {
				if (record.householder === '同住人' && !record.isSendKey) {
					return <Tag color={'red'}>未生效</Tag>;
				}
				return <Tag color={'green'}>已生效</Tag>;
			},
		},
		{
			title: '照片',
			key: 'userImg',
			dataIndex: 'userImg',
			render: (faceImg: string) => {
				return faceImg !== '' && faceImg !== '-' ? (
					<Image key={faceImg} src={faceImg} width={100} height={70} />
				) : (
					'无'
				); //先写死root
			},
		},
		{
			title: '证件类型',
			key: 'zhengjianType',
			dataIndex: 'zhengjianType',
			hideInTable: true,
		},
		{
			title: '户籍所在地',
			key: 'address',
			dataIndex: 'address',
			hideInTable: true,
		},
		{
			title: '合同开始时间',
			key: 'hetongStart',
			valueType: 'date',
			dataIndex: 'hetongStart',
			hideInTable: true,
		},
		{
			title: '合同结束时间',
			key: 'hetongEnd',
			valueType: 'date',
			dataIndex: 'hetongEnd',
			hideInTable: true,
		},
		{
			title: '证件号',
			key: 'zhengjianNumber',
			dataIndex: 'zhengjianNumber',
			render: (value: string, record: any) => {
				return record.zhengjianNumber === null || record.zhengjianNumber === ''
					? '无'
					: record.zhengjianType + ': ' + record.zhengjianNumber;
			},
		},
		{
			title: '迁入日期',
			key: 'stayDate',
			valueType: 'date',
			dataIndex: 'stayDate',
		},
		{
			title: '迁出日期',
			key: 'checkOutTime',
			valueType: 'date',
			dataIndex: 'checkOutTime',
		},
		{
			title: '操作',
			key: 'action',
			dataIndex: 'action',
			render: (text: string, record: any) => (
				<Space>
						{isRuzhu === '1' ? (
								<AuthorizedView needAuthority={'household:household_list:delete'}>
									<a
									onClick={() => {
										qianchuZhuHu(record.uuid, record);
									}}
								>
									迁出
								</a>
								</AuthorizedView>
						) : (
							''
						)}

							{record.deviceId === null || record.deviceId === '' ? null : (
								<a
									onClick={() => {
										kaimen(record);
									}}
								>
									开门记录
								</a>
							)}
				</Space>
			),
		},
	];

	const setUpdateItem = (record: any) => {
		console.log('record', record);
		setZhengjianType(record.zhengjianType);
		setZhengjianNumber(record.zhengjianNumber);
		setCheckStartTime(record.hetongStart === null ? '' : record.hetongStart);
		setCheckOutTime(record.hetongEnd === null ? '' : record.hetongEnd);
		setFangjianUuid(record.roomUuid);
		setStartTime(record.stayDate === null ? '' : record.stayDate);
		setRoomNumber0(
			record.roomNumber === null || record.roomNumber === ''
				? '请选择房间'
				: record.position + ' ' + record.roomNumber
		);
		setPhoto(record.userImg === null ? '' : record.userImg);
		if (!!record.userImg) {
			let list = [
				{
					uid: record.userImg + '',
					url: record.userImg,
					name: 'logo.png',
					status: 'done',
				},
			];
			setFileList(list);
		} else {
			setFileList([]);
		}
		const formData: any = {
			householdName: record.householdName,
			uuid: record.uuid,
			phone: record.phone,
			sex: record.sex,
			address: record.address,
			householder: record.householder,
			roomNumber0: record.roomNumber === null || record.roomNumber === '' ? '请选择房间' : record.roomNumber,
			startTime: record.stayDate === null ? '' : record.stayDate,
		};
		form.setFieldsValue(formData);
		setVisible(true);
		setAdd(false);
	};

	const getFangjian = (res: any) => {
		setRoomNumber(res.roomNumber);
		setRoomUuid1(res.roomUuid);
		setRoomAndHouse1(true);
		setFangVisiable(true);
	};
	const kaimen = (res: any) => {
		setDeviceId(res.deviceId);
		setDeviceName(res.householdName);
		setUserId(res.userId);
		setKaimenVisiable(true);
	};
	const qianchuZhuHu = (uuid: string, record: any) => {
		confirm({
			title: '退房',
			content: '您确认要为" ' + record.householdName + ' "退房吗？',
			okText: '确定',
			okType: 'danger',
			maskClosable: true,
			cancelText: '取消',
			onOk: async () => {
				if (uuid === null) {
					msg.suspensionMsg({ content: '退房异常', type: 'danger' });
					return;
				}
				const res = await householdCheckOut({
					uuid: uuid,
					state: 'false',
				});
				try {
					if (res.err === 0) {
						msg.suspensionMsg({ content: '迁出成功', type: 'success' });
						if (actionRef.current) {
							actionRef.current.reload();
						}
					} else if (res.err === -2) {
						msg.suspensionMsg(res.msg);
					} else {
						msg.suspensionMsg({ content: '迁出失败', type: 'error' });
					}
				} catch (e) {}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const tableSearchBarRender = () => [
		<CheckableTag
			className="faceTag"
			checked={state === 1}
			onChange={() => {
				setState(1);
				setIsRuzhu('1');
				actionRef.current.reloadAndRest();
			}}
		>
			在住
		</CheckableTag>,
		<CheckableTag
			className="faceTag"
			checked={state === 0}
			onChange={() => {
				setState(0);
				setIsRuzhu('0');
				actionRef.current.reloadAndRest();
			}}
		>
			已迁出
		</CheckableTag>,
		<Select
			style={{ width: 100 }}
			placeholder="是否生效"
			defaultValue={status}
			onChange={(e: string) => {
				setStatus(e);
			}}
		>
			<Select.Option value={''}>全部</Select.Option>
			<Select.Option value={'true'}>已生效</Select.Option>
			<Select.Option value={'false'}>未生效</Select.Option>
		</Select>,
		<RangePicker
			showTime={false}
			allowClear={true}
			onChange={(dates: any) => {
				console.log('dates:::::', dates);
				setcontractEndStartTime(dates ? dates[0] : undefined);
				setcontractEndEndTime(dates ? dates[1] : undefined);
			}}
			format="YYYY-MM-DD"
			placeholder={['合同结束时间范围', '合同结束时间范围']}
		/>,
		<Input
			placeholder="姓名、房号、手机号"
			onChange={(e) => {
				setSearch(e.target.value);
			}}
		/>,
		<Button
			type={'primary'}
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			查询
		</Button>,
	];
	const openAddModal = () => {
		setCheckOutTime('');
		setCheckStartTime('');
		setZhengjianType('1');
		setZhengjianNumber('');
		setFangjianUuid('');
		setRoomNumber0('请选择房间');
		setPhoto('');
		setStartTime('');
		form.resetFields();
		setVisible(true);
		setAdd(true);
	};
	const tableOperationsBarRender = () => [
		<AuthorizedView needAuthority={'household:household_list:add'}>
			<Button type="primary" onClick={openAddModal}>
				迁入
			</Button>
		</AuthorizedView>
		,
		<AuthorizedView needAuthority={'household:household_list:add'}>
			<Link to={{ pathname: '/zhuhu/batch-household' }}>
				<Button type="primary">批量导入</Button>
			</Link>
		</AuthorizedView>
		,
		// <a href="http://localhost:3000/zhuhu/batch-household">跳转页面</a>,
	];

	const onClickOk = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const reastForm = () => {
		form.resetFields();
		setRoomNumber0('请选择房间');
		setZhengjianType('1');
		setZhengjianNumber('');
		setFangjianUuid('');
		setCheckOutTime('');
		setCheckStartTime('');
		setStartTime('');
		setPhoto('');
	};
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData: any = {
				householdName: data.householdName,
				phone: data.phone,
				sex: data.sex,
				roomUuid: fangjianUuid,
				householder: data.householder,
				checkStartTime:
					checkStartTime === '' || checkStartTime === undefined
						? ''
						: moment(checkStartTime).format('YYYY-MM-DD'),
				checkOutTime:
					checkOutTime === '' || checkOutTime === undefined ? '' : moment(checkOutTime).format('YYYY-MM-DD'),
				startTime: startTime,
				img: photo,
				zhengjianType: zhengjianType,
				zhengjianNumber: zhengjianNumber,
				address: data.address,
			};
			console.log('addData', formData);
			const res = await addHousehold(formData);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '添加成功', type: 'success' });
				reastForm();
				if (actionRef.current) {
					actionRef.current.reload();
				}
				setVisible(false);
			} else if (res.err === -3) {
				msg.suspensionMsg({ content: res.msg, type: 'warning' });
			} else {
				msg.suspensionMsg({ content: res.msg, type: 'error' });
			}
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData: any = {
				uuid: data.uuid,
				householdName: data.householdName,
				phone: data.phone,
				sex: data.sex,
				roomUuid: fangjianUuid,
				householder: data.householder,
				checkStartTime:
					checkStartTime === '' || checkStartTime === undefined
						? ''
						: moment(checkStartTime).format('YYYY-MM-DD'),
				checkOutTime:
					checkOutTime === '' || checkOutTime === undefined ? '' : moment(checkOutTime).format('YYYY-MM-DD'),
				startTime: startTime,
				img: photo,
				zhengjianType: zhengjianType,
				zhengjianNumber: zhengjianNumber,
				address: data.address,
			};
			console.log('updateForm::', formData);
			const res = await updataHousehold(formData);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '修改成功', type: 'success' });
				setVisible(false);
				reastForm();
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: res.msg });
			}
		});
	};
	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	};

	/*获得房间列表*/
	const findRooms = async () => {
		const res = await findLiveRoom({});
		if (res.err === 0) {
			console.log('获得楼栋下的房间列表', res);
			setRooms([...roomsToArray(res.data)]);
			setIsShow(false);
		}
	};
	/*获得楼栋列表*/
	const findBuild = async () => {
		setBatchSelectRoomsVisible(true);
		findRooms();
		const res = await findBuilds({});
		if (res.err === 0) {
			console.log('获得楼栋列表', res);
			setBuilds(buildsToArray(res.data));
			setIsShow(false);
		}
	};
	// 将rooms返回一个对象数组
	const roomsToArray = (rooms: any[]) => {
		let result: { key: any; roomNumber: any; position: any; buildUuid: any }[] = [];
		rooms.map((item: any, index) => {
			result.push({
				key: item.uuid,
				roomNumber: item.roomNumber,
				position: item.position,
				buildUuid: item.buildUuid,
			});
		});
		return result;
	};
	const buildsToArray = (builds: any[]) => {
		let result: { buildName: any; roomNumber: any; unitNumber: any; key: string }[] = [];
		builds.map((item: any, index: number) => {
			result.push({
				buildName: item.buildName,
				roomNumber: item.roomNumber,
				unitNumber: item.unitNumber,
				key: item.uuid,
			});
		});
		return result;
	};
	const columns1 = [
		{
			title: '楼栋列表',
			dataIndex: 'buildName',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						findRoom1(record);
					}}
				>
					{text}
				</a>
			),
		},
	];
	const columns2 = [
		{
			title: '位置',
			dataIndex: 'position',
			width: '60%',
		},
		{
			title: '房间号',
			dataIndex: 'roomNumber',
			width: '40%',
			render: (text: string, record: any) => (
				<div>
					<span>{text}</span>
					<Divider type="vertical" />
					<a
						onClick={() => {
							rooms0(record);
						}}
					>
						选中
					</a>
				</div>
			),
		},
	];
	/*获得楼栋下的房间列表*/
	const findRoom1 = async (ev: any) => {
		console.log('选中得：：', ev);
		const res = await findRoom({
			uuid: ev.key,
		});
		if (res.err === 0) {
			console.log('获得楼栋下的房间列表', res);
			setRooms([...roomsToArray(res.data)]);
			setIsShow(false);
		}
	};
	const rooms0 = (ev: any) => {
		console.log('选中得：：', ev);
		setRoomNumber0(ev.position + ' ' + ev.roomNumber);
		setFangjianUuid(ev.key);
		setBatchSelectRoomsVisible(false);
		form.setFieldsValue({ roomNumber0: ev.roomNumber });
	};

	const getFileName = (fileList: any) => {
		console.log('fileList', fileList);
		setFileList(fileList.fileList);
		if (fileList.file.status === 'done') {
			if (fileList.file.response.err === 0) {
				setFileList(fileList.fileList);
				setPhoto(
					API.apiRootPath +
						'/api/file/get_file_qiniu/' +
						fileList.file.response.url +
						'/' +
						appConfig.wuYeUuid
				);
				console.log(`${fileList.file.name}`);
			} else {
				setFileList([]);
				setPhoto('');
				message.error(fileList.file.response.msg);
			}
		} else if (fileList.file.status === 'error') {
			msg.suspensionMsg({ content: `${fileList.file.name} 上传文件失败`, type: 'error' });
		} else if (fileList.file.status === 'removed') {
			// fileList = fileList.filter((item: any) => item.status !== "removed");
			setFileList([]);
			setPhoto('');
		}
	};

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>上传</div>
		</div>
	);
	const render = () => (fileList.length >= 1 ? null : uploadButton);

	const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		if (quanXuan) {
			let data = {
				search: search,
				state: isRuzhu,
				roomUuid: roomUuid,
				keyStatus: status,
				state: isRuzhu,
				startTime: contractEndStartTime,
				endTime: contractEndEndTime,
			};
			setloading(true);
			let rsp = await getHouseholdList(data);
			setloading(false);
			if (rsp.err === 0) {
				setExprotData(rsp.data);
			} else {
				message.error(rsp.msg);
			}
		} else {
			setExprotData(selectedRows);
		}
	};
	const tongzhi = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		if (quanXuan) {
			let data = {
				search: search,
				state: isRuzhu,
				roomUuid: roomUuid,
				keyStatus: status,
				startTime: contractEndStartTime,
				endTime: contractEndEndTime,
			};
			setloading(true);
			let rsp = await getHouseholdList(data);
			setloading(false);
			if (rsp.err === 0) {
				const data = rsp.data || [];
				let phones = [];
				data.forEach((element) => {
					phones.push(element.phone);
				});

				settongzhiPhone(phones);
				settongzhiVisible(true);
			} else {
				message.error(rsp.msg);
			}
		} else {
			let phones = [];
			selectedRows.forEach((element) => {
				phones.push(element.phone);
			});
			settongzhiPhone(phones);
			settongzhiVisible(true);
		}
	};

	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		zaiZhuColumns.map((item: any) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data: [string?] = [];
			zaiZhuColumns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					if (selectedRows[i][item.dataIndex]) {
						data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
					} else {
						data.push('');
					}
				} else if (item.valueType === 'date') {
					if (selectedRows[i][item.dataIndex]) {
						data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD'));
					} else {
						data.push('');
					}
				} else if (item.dataIndex === 'isSendKey') {
					if (selectedRows[i].householder === '同住人' && !selectedRows[i].isSendKey) {
						data.push('未生效')
					}
					return data.push('已生效')
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '住户列表');

		/* save to file */
		XLSX.writeFile(wb, '住户列表.xlsx');
	};

	const onPreview = (file: any) => {
		console.log('file:::', file);
		setPreviewVisable(true);
		setPreviewFileUrl(file.url || file.thumbUrl);
		return;
	};

	return (
		<Spin spinning={loading}>
			<GSTable
				columns={zaiZhuColumns}
				actionRef={actionRef}
				rowKey={'uuid'}
				rowSelection={{
					preserveSelectedRowKeys: true,
					selectedRowKeys: selectedRowKeys,
					onChange: (selectedRowKeys: any, selectedRows: any) => {
						setSelectedRowKeys(selectedRowKeys);
						setQuanXuan(false);
					},
					selections: [
						{
							key: 'SELECT_CURRENT_ALL',
							text: '全选当前页面',
							onSelect: (changableRowKeys: any) => {
								setQuanXuan(false);
								setSelectedRowKeys(selectedRowKeys.concat(changableRowKeys));
							},
						},
						{
							key: 'SELECT_ALL',
							text: '全选全部页面',
							onSelect: (changableRowKeys: any) => {
								setQuanXuan(true);
								setSelectedRowKeys(changableRowKeys);
							},
						},
					],
				}}
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
							<span>
								已选 {quanXuan ? total : selectedRowKeys.length} 项
								<a
									style={{ marginLeft: 8 }}
									onClick={() => {
										onCleanSelected();
										setQuanXuan(false);
									}}
								>
									取消选择
								</a>
							</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					console.log('tableAlertOptionRender::::', selectedRows);
					return (
						<Space size={16}>
							<a onClick={() => exprotData(selectedRows)}>导出数据</a>

							<a onClick={() => tongzhi(selectedRows)}>通知</a>
						</Space>
					);
				}}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let res = await getHouseholdList({
						page: params.current,
						size: params.pageSize,
						search: search,
						state: isRuzhu,
						keyStatus: status,
						roomUuid: roomUuid,
						startTime: contractEndStartTime,
						endTime: contractEndEndTime,
					});
					if (quanXuan) {
						let uuids: any = [];
						res.data.map((item: any) => {
							uuids.push(item.uuid);
						});
						setSelectedRowKeys(uuids);
					}
					setTotal(res.count);
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				title="开门记录"
				visible={kaimenVisiable}
				width={1200}
				footer={null}
				onCancel={() => {
					setKaimenVisiable(false);
				}}
			>
				<KaimenList key={userId} deviceId={deviceId} deviceName={deviceName} userId={userId} />
			</Modal>

			<Modal
				width={1100}
				title={add ? '新增用户' : '修改用户'}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
				footer={
					// if (!DAL.checkPermission("household:household_list:update")) {
					// 	return null;
					// }
					//  <Button>编辑</Button>
					<Space>
						<Button onClick={() => {
							setVisible(false)
						}}>取消</Button>
						<AuthorizedView needAuthority={'household:household_list:update'}>
							<Button onClick={onClickOk} type={"primary"}>保存</Button>
						</AuthorizedView>
					</Space>
				}
				onOk={onClickOk}
			>
				<Form form={form} {...formItemLayout}>
					<FormItem hidden={true} label={'uuid'} name={'uuid'}>
						<Input />
					</FormItem>
					<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
						<Col span={12}>
							<FormItem label={'住户姓名'} name={'householdName'} rules={[{ required: true }]}>
								<Input placeholder={'请输入住户名称'} />
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label={'登录账号'} name={'phone'} rules={[{ required: true }]}>
								<Input disabled={!add} placeholder={'请输入手机号'} />
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
						<Col span={12}>
							<FormItem label={'证件号码'} name={'zhengjianNumber'}>
								<InputGroup compact={true}>
									<Select
										onChange={(value: any) => {
											setZhengjianType(value);
										}}
										defaultValue={zhengjianType}
									>
										<Option value="1">身份证</Option>
										<Option value="2">军官证</Option>
										<Option value="3">港澳证</Option>
										<Option value="4">其他</Option>
									</Select>
									<Input
										style={{ width: 266 }}
										onChange={(ev) => {
											setZhengjianNumber(ev.target.value);
										}}
										value={zhengjianNumber}
									/>
								</InputGroup>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label={'入住时间'} name={'startTime'} rules={[{ required: true }]}>
								{console.log('startTime', startTime)}
								<DatePicker
									format="YYYY-MM-DD"
									style={{ width: '100%' }}
									onChange={(date: any, dateString: any) => {
										setStartTime(dateString);
										form.setFieldsValue({ startTime: dateString });
									}}
									value={
										startTime === null || startTime === '' ? null : moment(startTime, 'YYYY-MM-DD')
									}
									placeholder="选择或者输入一个日期"
								/>
							</FormItem>
						</Col>
					</Row>

					<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
						<Col span={12}>
							<FormItem label={'性别'} name={'sex'} rules={[{ required: true }]}>
								<RadioGroup>
									<Radio value={0}>男</Radio>
									<Radio value={1}>女</Radio>
								</RadioGroup>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label={'房间号'} name={'roomNumber0'} rules={[{ required: true }]}>
								<Button onClick={findBuild} value={roomNumber0}>
									{roomNumber0}
								</Button>
							</FormItem>
						</Col>
					</Row>

					<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
						<Col span={12}>
							<FormItem label={'业户类型'} name={'householder'} rules={[{ required: true }]}>
								<RadioGroup>
									<Radio value={'承租人'}>
										承租人
										<Tooltip placement="top" title={'每个房间只允许存在一个承租人或户主'}>
											<QuestionCircleOutlined />
										</Tooltip>
									</Radio>
									<Radio value={'户主'}>
										户主
										<Tooltip placement="top" title={'每个房间只允许存在一个承租人或户主'}>
											<QuestionCircleOutlined />
										</Tooltip>
									</Radio>
									<Radio value={'租户'}>租户</Radio>
									<Radio value={'业主'}>业主</Radio>
									<Radio value={'家庭成员'}>家庭成员</Radio>
									<Radio value={'同住人'}>同住人</Radio>
								</RadioGroup>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label={'照片'} name={'photo'}>
								<>
									<CustomUploadImage
										maxCount={1}
										cdn={'private'}
										onPreview={onPreview}
										data={{ isFace: true }}
										multiple={false}
										listType="picture-card"
										fileList={fileList}
										render={render()}
										onChange={getFileName}
									/>
									<span>请上传大小25K~1M、像素不低于480x640的清晰免冠照片</span>
								</>
								{/*<Upload*/}
								{/*	name="image"*/}
								{/*	listType="picture-card"*/}
								{/*	className="avatar-uploader"*/}
								{/*	showUploadList={false}*/}
								{/*	action={uploadUrl}*/}
								{/*	onChange={getFileName}*/}
								{/*>*/}
								{/*	/!* <img*/}
								{/*			alt="avatar"*/}
								{/*			src={*/}
								{/*				API.apiRootPath +*/}
								{/*				'/api/file/get_file_qiniu/' +*/}
								{/*				photo +*/}
								{/*				'/' +*/}
								{/*				appConfig.wuYeUuid*/}
								{/*			}*/}
								{/*		/> *!/*/}
								{/*	{photo !== null && photo !== undefined && photo !== '' ? (*/}
								{/*		<img*/}
								{/*			alt="avatar"*/}
								{/*			src={*/}
								{/*				photo*/}
								{/*			}*/}
								{/*		/>*/}
								{/*	) : (*/}
								{/*		uploadButton*/}
								{/*	)}*/}
								{/*</Upload>*/}
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
						<Col span={12}>
							<FormItem label={'合同开始日期'} name={'checkStartTime'}>
								{console.log('checkStartTime', checkStartTime)}
								<DatePicker
									format="YYYY-MM-DD"
									style={{ width: '100%' }}
									onChange={(date: any) => {
										setCheckStartTime(date.toDate().getTime());
									}}
									value={
										checkStartTime === null || checkStartTime === '' ? null : moment(checkStartTime)
									}
									placeholder="选择或者输入一个日期"
								/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label={'合同终止日期'} name={'checkOutTime'}>
								{console.log('checkOutTime', checkOutTime)}
								<DatePicker
									format="YYYY-MM-DD"
									style={{ width: '100%' }}
									onChange={(date: any) => {
										setCheckOutTime(date.toDate().getTime());
									}}
									value={checkOutTime === null || checkOutTime === '' ? null : moment(checkOutTime)}
									placeholder="选择或者输入一个日期"
								/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
						<Col span={12}>
							<FormItem label={'户籍地址'} name={'address'}>
								<Input />
							</FormItem>
						</Col>
					</Row>
				</Form>
			</Modal>
			<Modal
				title="房间选择"
				width={800}
				visible={batchSelectRoomsVisible}
				onCancel={() => {
					setBatchSelectRoomsVisible(false);
				}}
				onOk={() => {
					setBatchSelectRoomsVisible(false);
				}}
			>
				<div style={{ display: 'flex', height: '300px' }}>
					<div style={{ width: '40%' }}>
						<Table
							columns={columns1}
							bordered={true}
							scroll={{ y: 250 }}
							dataSource={builds}
							pagination={false}
							showHeader={true}
							sticky={true}
						/>
					</div>
					<div style={{ width: '60%', height: '300px' }}>
						<Table
							key={rooms.toString()}
							columns={columns2}
							bordered={true}
							scroll={{ y: 250 }}
							dataSource={rooms}
							pagination={false}
							sticky={true}
						/>
					</div>
				</div>
			</Modal>
			<Modal
				title={'房间信息     ' + '(' + roomNumber + ')'}
				visible={fangVisiable}
				width={1300}
				footer={null}
				onCancel={() => {
					setFangVisiable(false);
				}}
			>
				{console.log('roomUuid1', roomUuid1)}
				<RoomAndHousehold key={new Date().getTime()} roomUUid={roomUuid1} roomAndHouse={roomAndHouse1} />
			</Modal>
			<Modal
				title={'预览'}
				zIndex={1001}
				footer={null}
				visible={previewVisable}
				onCancel={() => {
					setPreviewVisable(false);
				}}
				onOk={() => {
					setPreviewVisable(false);
				}}
			>
				<Image src={previewFileUrl} />
			</Modal>
			<Modal
				title={'通知'}
				width={1100}
				footer={null}
				visible={tongzhiVisible}
				onCancel={() => {
					settongzhiVisible(false);
				}}
				onOk={() => {
					settongzhiVisible(false);
				}}
			>
				<PayNotice key={dateKey} phones={tongzhiPhone}></PayNotice>
			</Modal>
		</Spin>
	);
};
export default HouseholdList;
