import React, { useRef, useState, useEffect } from 'react';
import { Tag, Input, Table, Space, Modal, Row, Col, TreeSelect, Tooltip, Select, message, Image, Button } from 'antd';
import {
	findAllPersonManage,
	batchQuit,
	batchHandleRequeryKey,
	batchRejectRequeryKey,
} from '../../../services/WuyeService';
import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getDevices, sendKeyByEmp } from '../../../services/ZhiNengMenJinService';
import moment from 'moment';
import API from '../../../data/API';
import appConfig from '../../../appConfig';
import { findLiveRoom } from '../../../services/ZhuhushenheService';
import * as XLSX from 'xlsx';
import AuthorizedView from '../../../util/AuthorizedView';
const { CheckableTag } = Tag;

const RequestList: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const Search = Input.Search;
	const [search, setSearch] = useState('');
	const [sendKey, setSendKey] = useState(false);
	const [devices, setDevices] = useState([]);
	const [deviceIds, setDeviceIds] = useState('');
	const [keyType, setKeyType] = useState('anytime');
	const [phones, setPhones] = useState('');
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [requestKey, setRequestKey] = useState(1);
	const proColumns = [
		{
			title: '姓名',
			width: 100,
			dataIndex: 'employeeName',
			key: 'employeeName',
		},
		{
			title: '登录账号',
			width: 150,
			dataIndex: 'account',
			key: 'account',
		},
		{
			title: '人脸照片',
			width: 100,
			dataIndex: 'facePhoto',
			key: 'facePhoto',
			render: (text: string) => {
				if (text === '-') {
					return <span>未上传</span>;
				}
				return (
					<Image
						width={100}
						height={100}
						src={API.apiRootPath + '/api/file/get_file_qiniu/' + text + '/' + appConfig.wuYeUuid}
					/>
				);
			},
		},
		{
			title: '身份证号',
			width: 200,
			dataIndex: 'cardIdNum',
			key: 'cardIdNum',
		},
		{
			title: '部门',
			width: 150,
			dataIndex: 'departmentName',
			key: 'departmentName',
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			width: 50,
			render: (text: string, record: any) => <Tag color={record.status === '正常' ? 'green' : 'red'}>{text}</Tag>,
		},
		{
			title: '人员类型',
			width: 100,
			dataIndex: 'employeeType',
			key: 'employeeType',
		},
		{
			title: '截止日期',
			valueType: 'date',
			width: 100,
			dataIndex: 'expireTime',
			key: 'expireTime',
		},
		{
			title: '所属项目',
			width: 100,
			dataIndex: 'wuyeProj',
			key: 'wuyeProj',
		},
		{
			title: '录入人员',
			width: 100,
			dataIndex: 'createor',
			key: 'createor',
		}
	];
	useEffect(() => {
		getDeviceList().then();
	}, []);
	const getDeviceList = async () => {
		const res = await getDevices({});
		if (res.err !== 0) {
			message.error(res.msg);
			return;
		}
		setDevices(res.data);
	};
	const tableSearchBarRender = () => [
		<CheckableTag
			key={'1'}
			className="faceTag"
			checked={requestKey === 1}
			onChange={() => {
				setRequestKey(1);
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			待处理
		</CheckableTag>,
		<CheckableTag
			key={'2'}
			className="faceTag"
			checked={requestKey === -1}
			onChange={() => {
				setRequestKey(-1);
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			已拒绝
		</CheckableTag>,
		<Input
			placeholder="姓名，登录账号，身份证号"
			onChange={(value: any) => {
				setSearch(value.target.value);
			}}
		/>,
		<Button
			type={'primary'}
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			查询
		</Button>,
	];

	const deviceIdChange = (ev: any) => {
		console.log('ev', ev);
		setDeviceIds(ev.toString());
	};
	const tooltipText = (
		<div>
			<div>普通用户：只能开锁；</div>
			<div>管理和查看：可以发送，删除钥匙，可以查看使用记录；</div>
			<div>管理：可以发送，删除钥匙；</div>
			<div>使用和查看：可以使用、查看使用记录；</div>
			<div>超级管理员：可以开锁，发送删除钥匙，查看使用记录</div>
		</div>
	);
	const batchSendKey = async () => {
		setConfirmLoading(true);
		if (!deviceIds) {
			message.error('发送的设备不能为空');
			setConfirmLoading(false);

			return;
		}
		if (!phones) {
			message.error('接收人不能为空');
			setConfirmLoading(false);

			return;
		}
		let params = {
			device_id: deviceIds,
			key_type: keyType,
			phones: phones,
			companyUuid: localStorage.getItem('companyUuid'),
		};
		let res = await sendKeyByEmp(params);
		setConfirmLoading(false);
		setSendKey(false);
		if (res.err === 0) {
			if (res.errCount > 0) {
				message.warning(res.errCount + '条信息因未上传人脸片发送失败');
			} else {
				message.success(res.msg);
			}
		} else {
			message.error(res.msg);
		}
	};
	const batchQuitRequest = async (selectedRows: any) => {
		let uuids: any = [];
		selectedRows.map((item: any) => {
			uuids.push(item.uuid);
		});
		Modal.confirm({
			title: '提示',
			content: '您确定要删除该员工？',
			onOk: async () => {
				let data = {
					empUuids: uuids.toString(),
					quitTime: moment().format('YYYY-MM-DD'),
				};
				let rsp = await batchQuit(data);
				if (rsp.err === 0) {
					message.success('操作成功');
					if (actionRef.current) {
						actionRef.current.reloadAndRest();
					}
				} else {
					message.error(rsp.msg);
				}
			},
		});
	};
	const batchHandle = async (selectedRows: any) => {
		let uuids: any = [];
		selectedRows.map((item: any) => {
			uuids.push(item.uuid);
		});
		Modal.confirm({
			title: '提示',
			content: '您确定标识为已处理吗，标识为已处理后将不在列表显示!',
			onOk: async () => {
				let data = {
					empUuids: uuids.toString(),
				};
				let rsp = await batchHandleRequeryKey(data);
				if (rsp.err === 0) {
					message.success('操作成功');
					if (actionRef.current) {
						actionRef.current.reloadAndRest();
					}
				} else {
					message.error(rsp.msg);
				}
			},
		});
	};
	const batchReject = async (selectedRows: any) => {
		let uuids: any = [];
		selectedRows.map((item: any) => {
			uuids.push(item.uuid);
		});
		Modal.confirm({
			title: '提示',
			content: '您确定拒绝该申请吗？',
			onOk: async () => {
				let data = {
					empUuids: uuids.toString(),
				};
				let rsp = await batchRejectRequeryKey(data);
				if (rsp.err === 0) {
					message.success('操作成功');
					if (actionRef.current) {
						actionRef.current.reloadAndRest();
					}
				} else {
					message.error(rsp.msg);
				}
			},
		});
	};
	const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		setExprotData(selectedRows);
	};
	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];

		proColumns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let excelData: any = [];
			proColumns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							excelData.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							excelData.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						excelData.push('');
					}
				} else if (item.valueType === 'dateTime') {
					excelData.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				} else if (item.key === 'isSell') {
					excelData.push(
						selectedRows[i][item.dataIndex] == 2
							? '出售'
							: selectedRows[i][item.dataIndex] == 0
							? '不出售'
							: item.isSell == 1
							? '锁定'
							: ''
					);
				} else if (item.key === 'state') {
					excelData.push(selectedRows[i][item.dataIndex] == 1 ? '已入住' : '空置');
				} else {
					excelData.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = excelData;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '待授权人员');
		/* save to file */
		XLSX.writeFile(wb, '待授权人员.xlsx');
	};

	return (
		<>
			<GSTable
				columns={proColumns}
				rowKey="account"
				rowSelection={{
					preserveSelectedRowKeys: true,
					selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
				}}
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
							<span>
								已选 {selectedRowKeys.length} 项
								<a
									style={{ marginLeft: 8 }}
									onClick={() => {
										onCleanSelected();
									}}
								>
									取消选择
								</a>
							</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					if (requestKey === -1) {
						return (
							<a
								onClick={() => {
									exprotData(selectedRows);
								}}
							>
								导出
							</a>
						);
					}
					return (
						<Space size={16}>
							<AuthorizedView needAuthority={'entrance_guard:mapping_list:sendKey'}>
								<a
									onClick={() => {
										setSendKey(true);
										setPhones(selectedRowKeys.toString());
									}}
								>
									发送钥匙
								</a>
							</AuthorizedView>
							<AuthorizedView needAuthority={'entrance_guard:mapping_list:sendKey'}>
								<a
									onClick={() => {
										batchReject(selectedRows);
									}}
								>
									拒绝
								</a>
							</AuthorizedView>
							<AuthorizedView needAuthority={'entrance_guard:mapping_list:sendKey'}>
								<a
									onClick={() => {
										batchHandle(selectedRows);
									}}
								>
									已处理
								</a>
							</AuthorizedView>
							<a
								onClick={() => {
									exprotData(selectedRows);
								}}
							>
								导出
							</a>
						</Space>
					);
				}}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findAllPersonManage({
						state: true,
						requestKey: requestKey,
						page: params.current,
						Search: search,
						size: params.pageSize,
						departmentUuid: '',
						companyUuid: localStorage.getItem('companyUuid'),
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>

			<Modal
				title={'发送钥匙'}
				width={650}
				confirmLoading={confirmLoading}
				visible={sendKey}
				onCancel={() => setSendKey(false)}
				onOk={batchSendKey}
			>
				<Row justify="center" align={'middle'} className={'antdRow'}>
					<Col span={4}>设备名称：</Col>
					<Col span={14}>
						<TreeSelect
							style={{ width: '100%' }}
							dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
							treeData={devices}
							allowClear={true}
							multiple={true}
							treeNodeFilterProp={'title'}
							treeCheckable={true}
							showSearch={true}
							onChange={deviceIdChange}
						/>
					</Col>
				</Row>
				<Row className={'antdRow'} align={'middle'} justify="center">
					<Col span={4}>
						权限选择{''}
						<Tooltip placement="topLeft" title={tooltipText}>
							<QuestionCircleOutlined />
						</Tooltip>{' '}
						:
					</Col>
					<Col span={14}>
						<Select
							showSearch={true}
							style={{ width: '100%' }}
							placeholder="请选择"
							optionFilterProp="children"
							onChange={(e: any) => {
								setKeyType(e);
							}}
							defaultValue={'anytime'}
						>
							<Select.Option value="anytime">普通用户</Select.Option>
							<Select.Option value="manage">管理</Select.Option>
							<Select.Option value="look">查看</Select.Option>
							<Select.Option value="use_look">使用和查看</Select.Option>
							<Select.Option value="manage_look">管理和查看</Select.Option>
							<Select.Option value="use_manage">使用和管理</Select.Option>
							<Select.Option value="admin">超级管理员</Select.Option>
						</Select>
					</Col>
				</Row>
			</Modal>
		</>
	);
};
export default RequestList;
