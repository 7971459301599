import React, { useEffect, useRef, useState } from "react";
import { ActionType } from '@ant-design/pro-table';
import { Button, Form, Image, Input, message, Modal, Select, Space, TreeSelect } from 'antd';
import { FormInstance } from 'antd/es/form';
import GSTable from '../../../components/GSTable';
import { getCallerInfo, updataInfo } from '../../../services/CallerService';
import { findDepartmentDirectory } from "../../../services/WuyeService";
import GsTreeSelect from "../../../components/GsTreeSelect";
import moment from  'moment';
import CustomRangePicker from '../../../components/CustomRangePicker';
import { getHeatlhRecordList } from '../../../services/TemperatureRecordsService';
import * as XLSX from 'xlsx';
var QRCode = require('qrcode.react');
var msg = require('../../../util/SuspensionMsg');
var id: number;
const confirm = Modal.confirm;
const Search = Input.Search;
const CallerList: React.FC = () => {
	let users2: any = [];
	const actionRef = useRef<ActionType>();
	const [inputStr, setInputStr] = useState('');
	const [qrCode, setQrCode] = useState('');
	const [department, setDepartment] = useState([]);
	const [departmentUuid, setDepartmentUuid] = useState([]);
	const [involvingSecrets, setInvolvingSecrets] = useState('');
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [quanXuan, setQuanXuan] = useState(false);
	const [total, setTotal] = useState(0);

	const [Stime, setStime] = useState(
		moment().subtract(7, 'd').hours(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:00')
	);
	const [Etime, setEtime] = useState(moment().hours(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:59'));

	const columns = [
		{
			title: '访客姓名',
			width: 100,
			fixed: 'left',
			key: 'applyForName',
			dataIndex: 'applyForName',
		},
		{
			title: '访客手机号',
			key: 'applyForPhone',
			width: 100,
			dataIndex: 'applyForPhone',
		},
		{
			title: '访客身份证号',
			width: 150,
			key: 'applyForCardIdNum',
			dataIndex: 'applyForCardIdNum',
		},
		{
			title: '提交时间',
			key: 'create_time',
			width: 200,
			valueType: 'dateTime',
			dataIndex: 'create_time',
		},

		{
			title: '接访人手机号',
			key: 'ownerPhone',
			width: 100,
			dataIndex: 'ownerPhone',
		},
		{
			title: '接访人姓名',
			key: 'ownerName',
			width: 100,
			dataIndex: 'ownerName',
		},
		{
			title: '访问部门',
			key: 'department',
			width: 100,
			dataIndex: 'department',
		},
		{
			title: '来访事由',
			key: 'info',
			width: 100,
			dataIndex: 'info',
		},
		{
			title: '来访区域',
			key: 'accessArea',
			width: 150,
			dataIndex: 'accessArea',
		},
		{
			title: '是否涉密',
			width: 100,
			key: 'involvingSecrets',
			dataIndex: 'involvingSecrets',
		},
		{
			title: '审批文件',
			key: 'proveFile',
			width: 100,
			dataIndex: 'proveFile',
			render: (text: string, record: any) => {
				if (record.proveFile) {
					return <Image src={text} width={100} height={100}/>;
				}
				return <span>-</span>;
			}
		},
		{
			title: '开始时间',
			key: 'startTime',
			width: 200,
			valueType: 'dateTime',
			dataIndex: 'startTime',
		},
		{
			title: '结束时间',
			key: 'endTime',
			width: 200,
			valueType: 'dateTime',
			dataIndex: 'endTime',
		},
		// {
		// 	title: '状态',
		// 	key: 'state',
		// 	dataIndex: 'state',
		// 	render: (text: string, record: any) => (
		// 		<span>
		// 			{' '}
		// 			{record.state === 0
		// 				? '等审核'
		// 				: record.state === -1
		// 				? '未通过'
		// 				: record.state === 1
		// 				? '通过'
		// 				: '重新提交'}{' '}
		// 		</span>
		// 	),
		// },
		// {
		// 	title: '操作',
		// 	key: 'active',
		// 	dataIndex: 'active',
		// 	render: (text: string, record: any) => {
		// 		return (
		// 			<span>
		// 				{record.state === 0 ? (
		// 					<div>
		// 						<ul className="ant-list-item-action">
		// 							<li>
		// 								<a
		// 									onClick={() => {
		// 										getUserInfo(record.id, 1);
		// 									}}
		// 								>
		// 									通过
		// 								</a>
		// 							</li>
		// 							<li>
		// 								<a
		// 									onClick={() => {
		// 										getUserInfo(record.id, -1);
		// 									}}
		// 								>
		// 									拒绝
		// 								</a>
		// 							</li>
		// 						</ul>
		// 					</div>
		// 				) : record.state === -1 ? (
		// 					'未通过'
		// 				) : record.state === 1 ? (
		// 					'通过'
		// 				) : (
		// 					'重新提交'
		// 				)}
		// 			</span>
		// 		);
		// 	},
		// },
	];

	useEffect(() => {
		getDepartment().then()
	}, [])

	const getDepartment = async () => {
		const res = await findDepartmentDirectory({
			companyUuid: localStorage.getItem("companyUuid"),
			showAll: true
		});
		if (res.err == 0) {
			console.log('res:::', res.data);
			let dataList: any = [];
			res.data.map((item: any) => {
				let data: any = {};
				data["id"] = item.uuid;
				data["pId"] = item.parentUuid;
				data["value"] = item.uuid;
				data["title"] = item.departmentName;
				dataList.push(data);
			});
			setDepartment(dataList)
		}
	};

	const getUserInfo = (ev: any, state: number) => {
		id = ev;
		confirm({
			title: state === 1 ? '您确认要允许该用户吗？' : '您确认要拒绝该用户吗？',
			onOk() {
				updateItem(state);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const updateItem = async (state: any) => {
		const res = await updataInfo({ id: id, state: state });
		console.log('res::::::::', res.err);
		if (res.err === 0) {
			let users = users2;
			users.map((index: number, item: any) => {
				if (item.id === id) {
					item.state = state;
				}
			});
			message.success(res.msg);
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			message.error(res.msg);
		}
	};
	const dateTime = (startTime: any, endTime: any) => {
		setStime(startTime.format('YYYY-MM-DD HH:mm:00'));
		setEtime(endTime.format('YYYY-MM-DD HH:mm:59'));
	};
	const tableSearchBarRender = () => [
		<Select style={{width: 100}} defaultValue={involvingSecrets} onChange={(value: string) => {
			setInvolvingSecrets(value)
		}}>
			<Select.Option value={""}>全部</Select.Option>
			<Select.Option value={"是"}>涉密</Select.Option>
			<Select.Option value={"否"}>不涉密</Select.Option>
		</Select>,
		<CustomRangePicker onChange={dateTime} />,
		<GsTreeSelect
			treeDefaultExpandAll
			treeData={department}
			multiple={true}
			treeCheckable={true}
			showCheckedStrategy={TreeSelect.SHOW_ALL}
			placeholder={'请选择部门'}
			treeDataSimpleMode={true}
			treeCheckStrictly={true}
			onChange={(value: any) => {
				console.log("value:::", value);
				let values: any = [];
				value.map((item: any) => {
					values.push(item.value);
				});
				setDepartmentUuid(values);
			}}
		/>,
		<Input
			placeholder={'请输入访客姓名或者手机号'}
			onChange={(valueType) => {
				inputValue(valueType);
			}}
		/>,
		<Button type={"primary"} onClick={() => actionRef.current.reloadAndRest()}
		>查询</Button>
	];
	const inputValue = (ev: any) => {
		setInputStr(ev.target.value);
	};
	const downloadQR = () => {
		const canvasImg: any = document.getElementById('qrCode'); // 获取canvas类型的二维码
		let link = document.createElement('a');
		link.textContent = 'download image';
		link.setAttribute('href', canvasImg.toDataURL('image/png'));
		link.setAttribute('download', '二维码.png');
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	const tableToolBarRender = () => [
		<QRCode
			style={{ display: 'flex', alignItems: 'center' }}
			size={32}
			value={'http://api.guosim.com/api/wuye/add_caller_info_view/' + qrCode}
		/>,
		<Button type="primary" onClick={() => downloadQR()}>
			下载二维码
			<QRCode
				hidden={true}
				id="qrCode"
				style={{ display: 'flex', alignItems: 'center' }}
				size={320}
				value={'http://api.guosim.com/api/wuye/add_caller_info_view/' + qrCode}
			/>
		</Button>,
	];

	const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		if (quanXuan) {
			const rsp = await getCallerInfo({
				search: inputStr,
				involvingSecrets: involvingSecrets,
				startTime: Stime,
				endTime: Etime,
				departmentUuid: departmentUuid.toString()
			});

			if (rsp.err === 0) {
				setExprotData(rsp.data.data);
			} else {
				message.error(rsp.msg);
			}
		} else {
			setExprotData(selectedRows);
		}
	};

	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data: [string?] = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}

					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '访客人员');

		/* save to file */
		XLSX.writeFile(wb, '访客人员.xlsx');
	};

	return (
		<div>
			<GSTable
				columns={columns}
				rowSelection={{
					fixed: true,
					preserveSelectedRowKeys: true,
					selectedRowKeys: selectedRowKeys,
					onChange: (selectedRowKeys: any, selectedRows: any) => {
						setSelectedRowKeys(selectedRowKeys);
						setQuanXuan(false);
					},
					selections: [
						{
							key: 'SELECT_CURRENT_ALL',
							text: '全选当前页面',
							onSelect: (changableRowKeys: any) => {
								setQuanXuan(false);
								setSelectedRowKeys(selectedRowKeys.concat(changableRowKeys));
							},
						},
						{
							key: 'SELECT_ALL',
							text: '全选全部页面',
							onSelect: (changableRowKeys: any) => {
								setQuanXuan(true);
								setSelectedRowKeys(changableRowKeys);
							},
						},
					],
				}}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
								<span>
									已选 {quanXuan ? total : selectedRowKeys.length} 项
									<a
										style={{ marginLeft: 8 }}
										onClick={() => {
											onCleanSelected();
											setQuanXuan(false);
										}}
									>
										取消选择
									</a>
								</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					console.log('tableAlertOptionRender::::', selectedRows);
					return (
						<Space size={16}>
							<a onClick={() => exprotData(selectedRows)}>导出数据</a>
						</Space>
					);
				}}
				// operationsBarRender={tableToolBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getCallerInfo({
						search: inputStr,
						involvingSecrets: involvingSecrets,
						page: params.current,
						size: params.pageSize,
						startTime: Stime,
						endTime: Etime,
						departmentUuid: departmentUuid.toString()
					});
					if (res.err !== 0) {
						msg.suspensionMsg({ content: res.msg, type: 'danger' });
						return;
					}
					if (quanXuan) {
						let uuids: any = [];
						res.data.data.map((item: any) => {
							uuids.push(item.id);
						});
						setSelectedRowKeys(uuids);
					}
					setTotal(res.data.total);
					setQrCode(res.wuyeId);
					users2 = res.data;
					return {
						data: res.data.data,
						total: res.data.total,
						success: true,
					};
				}}
			/>
		</div>
	);
};
export default CallerList;
